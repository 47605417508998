import { animated, useSpring } from '@react-spring/web';
import React from 'react';
import { useSelector } from 'react-redux';
import { playerColor } from '../../../config/constants';
import { playerNameAcronym } from '../../../utils/GameUtils';
import { useTranslation } from 'react-i18next';

export default function LastRollValue({ side }) {
  const { t } = useTranslation();
  const { played: playDone, state } = useSelector((state) => state.play.current.table.players[side]);
  const { activeSide, players } = useSelector((state) => state.play.current.table);
  const player = players[side];
  const { x } = useSpring({
    from: { x: 0 },
    x: playDone ? 1 : 0,
    config: { duration: 1000 },
  });

  return (
    <animated.div
      className='m-2'
      style={{
        width: '100px',
        height: '100%',
        color: playDone ? playerColor.active[side] : 'black',
        opacity: x.to({ range: [0, 1], output: [0.4, 1] }),
      }}>
      <div style={{ textAlign: 'center', maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}>{playerNameAcronym(player?.name)}</div>
      <div className='w-full text-center text-md '>{`${playDone && activeSide === side ? t('currentRoll') : t('lastRoll')}:`} </div>
      <div className='w-full text-center text-md '>{`${state?.lastRoll ?? ''}`} </div>
    </animated.div>
  );
}
