import { useState } from 'react';
import { Alert, Box, Button } from '@mui/material';
import useJoinTableHook from '../../../hooks/play/useJoinTableHook';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { isValueEmpty } from '@grethics/commons';
import { useTranslation } from 'react-i18next';

export default function JoinTableForm() {
  const { t } = useTranslation();
  const [enteredCode, enterCode] = useState(null);
  const user = useSelector((state) => state.auth?.user);

  const { joinError, joinTable } = useJoinTableHook();

  const handleChangeInput = (e) => {
    enterCode(e.target.value);
  };

  async function submitJoinGame(e) {
    e.preventDefault();
    let code = enteredCode ?? '';
    if (code.startsWith('https') || code.startsWith('http')) {
      const parts = code.split('/').filter((part) => part !== '');
      code = parts[parts.length - 1];
    }
    if (!isValueEmpty(code.trim())) {
      if (user?.id) {
        joinTable(code);
      }
    } else {
      enqueueSnackbar(t('invalidGameCode'), { variant: 'error' });
    }
  }

  return (
    <Box width={'100%'} display='flex' justifyContent='center' alignItems='start' className='min-h-[500px]'>
      <Box maxWidth={'md'} sx={{ minWidth: { lg: 700, md: 500, sm: 400 } }}>
        <Box component={'form'} sx={{ display: 'flex', flexDirection: 'row', p: 2, m: 2, bgcolor: 'primary.light' }} data-tip='error: game not found' onSubmit={submitJoinGame}>
          <input onChange={handleChangeInput} type='text' placeholder={t('gameCode')} className='border rounded mr-3 w-full p-3' name='gameHashCode' id='gameHashCode' />
          <Button disabled={!enteredCode} color='primary' variant='contained' type='submit' sx={{ textTransform: 'none' }}>
            {t('join')}
          </Button>
        </Box>
        {joinError && false && (
          <Alert variant='outlined' severity='error' className='rounded my-3 py-2 mx-auto'>
            {`Game cannot be joined. Reason: ${joinError}`}
          </Alert>
        )}
      </Box>
    </Box>
  );
}
