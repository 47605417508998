import { PlayerSearchMode, Validators } from '@grethics/commons';
import { DeleteForever } from '@mui/icons-material';
import { Autocomplete, Button, Container, IconButton, Paper, Stack, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AgentDao } from '../../../../model/dao/AgentDao';
import { GuiService } from '../../../../services/GuiService';
import { ageRangeTogroupLabels, isAgeGroupInGameAgeRange } from '../../../../utils/GameUtils';
import PlayerInfo from '../common/PlayerInfo';
import NewPlayerModal from './NewPlayerModal';

export default function PlayerConfigurator({ side, ageRange, onComplete, selected }) {
  const { t } = useTranslation();
  const playerForm = useRef();
  const [selectedPlayer, setSelectedPlayer] = React.useState(selected[side]);
  const [players, setPlayers] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const findPlayers = (searchTerm) => {
    if (!searchTerm || searchTerm.length < 3) {
      return;
    }

    const filter = {};
    if (Validators.core.isEmail(searchTerm)) {
      filter.email = searchTerm;
    } else {
      filter.name = searchTerm;
      filter.mode = PlayerSearchMode.MIXED;
    }
    AgentDao.searchPlayers(filter)
      .then((results) => {
        setPlayers(
          results.filter((record) => {
            const otherSide = side === 'left' ? 'right' : 'left';
            const oponnent = selected[otherSide];
            if (!oponnent) {
              return true;
            }
            if (record.player && oponnent.player && oponnent.player.id === record.player.id) {
              return false;
            }
            if (record.user && oponnent.user && oponnent.user.id === record.user.id) {
              return false;
            }
            return true;
          })
        );
      })
      .catch((err) => {
        setPlayers([]);
      });
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      findPlayers(value);
    } else {
      setPlayers([]);
    }
  };

  return (
    <Container sx={{ mt: 1 }}>
      {!selectedPlayer && (
        <Container sx={{ py: { xs: 1, sm: 2 } }}>
          <Stack direction={'column'} spacing={1}>
            <div className='flex flex-1'>
              <Autocomplete
                fullWidth
                value={selectedPlayer}
                id='free-solo-2-demo'
                freeSolo
                options={players}
                filterOptions={(options) => {
                  return options;
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} className='border-b last:border-b-0 border-gray-100 my-1'>
                      <PlayerInfo authUser={user} record={option} withAvatar avatarW={32} avatarH={32} />
                    </li>
                  );
                }}
                getOptionLabel={(option) => option?.name ?? ''}
                onInputChange={onInputChange}
                onChange={(event, newValue) => {
                  if (newValue.player) {
                    if (!isAgeGroupInGameAgeRange(ageRange, newValue.player.ageGroup)) {
                      GuiService.showAlert({
                        title: t('playerMissmatch'),
                        message: (
                          <p className='text-center'>
                            <span className='text-red-600 font-bold'>{t('playerOutOfGroupRange')}</span> <br />
                            <br />
                            {t('targetAgeGroups', { ageGroups: ageRangeTogroupLabels(ageRange).join(', ') })}
                          </p>
                        ),
                        actions: [
                          {
                            title: t('ok'),
                            color: 'primary',
                          },
                        ],
                      });
                    } else {
                      setSelectedPlayer(newValue);
                    }
                    if (onComplete) {
                      onComplete(newValue);
                    }
                  } else {
                    //setSelectedPlayer(newValue);
                    if (playerForm.current) {
                      playerForm.current.show(newValue);
                    }
                  }
                }}
                renderInput={(params) => <TextField {...params} label={t('searchByNameOrEmail')} />}
              />
            </div>
            <div className='w-full text-center'>{t('or')}</div>
            <div className='w-full'>
              <Button
                variant='outlined'
                fullWidth
                sx={{ py: 2, textTransform: 'none' }}
                onClick={() => {
                  if (playerForm.current) {
                    playerForm.current.show({});
                  }
                }}>
                {t('createNewPlayer')}
              </Button>
              <NewPlayerModal
                ref={playerForm}
                onSave={(record) => {
                  if (!isAgeGroupInGameAgeRange(ageRange, record.player.ageGroup)) {
                    GuiService.showAlert({
                      title: t('playerMissmatch'),
                      message: (
                        <p className='text-center'>
                          <span className='text-red-600 font-bold'>{t('playerOutOfGroupRange')}</span> <br />
                          <br />
                          {t('targetAgeGroups', { ageGroups: ageRangeTogroupLabels(ageRange).join(', ') })}
                        </p>
                      ),
                      actions: [
                        {
                          title: t('ok'),
                          color: 'primary',
                        },
                      ],
                    });
                  } else {
                    setSelectedPlayer(record);
                    console.log(record);
                    if (onComplete) {
                      onComplete(record);
                    }
                  }
                }}
              />
            </div>
          </Stack>
        </Container>
      )}
      {selectedPlayer && (
        <Container sx={{ py: { xs: 1, sm: 2 } }}>
          <Paper sx={{ py: { xs: 1, sm: 2 }, px: 2 }}>
            <div className='w-full flex flex-row items-center'>
              <div className='flex flex-1'>
                <PlayerInfo authUser={user} record={selectedPlayer} withAvatar avatarW={64} avatarH={64} direction='column' />
              </div>
              <IconButton
                aria-label='delete'
                color='error'
                onClick={() => {
                  setSelectedPlayer(null);
                  if (onComplete) {
                    onComplete(null);
                  }
                }}>
                <DeleteForever />
              </IconButton>
            </div>
          </Paper>
        </Container>
      )}
    </Container>
  );
}
