import { USER_ROLE } from '@grethics/commons';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';

export default function HomePage() {
  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    /* setTimeout(() => {
      throw new Error('Ooops');
    }, 4000); */
  }, []);

  if (user) {
    return <Navigate to={'./play'} replace />;
  }
}
