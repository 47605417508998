import { CakeRounded } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from 'react';
import { PALLETE } from '../../../config/constants';
import { capitalizePhrase } from '../../../utils/Utils';
import { useTranslation } from 'react-i18next';

export default function AgeFilter({ allAgeGroups = [], onRangeChange }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState([]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    onRangeChange && onRangeChange(newChecked);
  };

  return (
    <Accordion sx={{ width: '100%' }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary sx={{ px: 0.1, py: 0, borderRadius: 1 }} expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='lang-header'>
        <CakeRounded sx={{ color: 'primary.light', mr: 1 }} />
        <Typography sx={{ maxWidth: { sm: 300, md: 250 }, overflow: 'hidden' }}>
          {checked.length === 0 ? t('anyAge') : capitalizePhrase(checked[0].replaceAll('_', ' '), true) + (checked.length > 1 ? ', ...' : '')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List dense sx={{ width: '100%', maxWidth: 360 }}>
          {allAgeGroups.map((value) => {
            const labelId = `checkbox-list-primary-label-${value}`;
            return (
              <ListItem
                key={value}
                secondaryAction={<Checkbox edge='end' onChange={handleToggle(value)} checked={checked.indexOf(value) !== -1} inputProps={{ 'aria-labelledby': labelId }} />}
                disablePadding>
                <ListItemButton sx={{ px: 1 }}>
                  <ListItemText id={labelId} primary={t(value)} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
