import { isValueEmpty } from '@grethics/commons';
import { AccountCircleOutlined, Add, BarChart, Cake, CalendarMonthOutlined, LanguageOutlined, Numbers } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Interweave } from 'interweave';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import useMediaHook from '../../../hooks/useMediaHook';
import { ageRangeTogroups } from '../../../utils/GameUtils';
import { GameItemErrorFallback } from '../common/ErrorFallbacks';
import LightTooltip from '../common/LightTooltip';
import { useTranslation } from 'react-i18next';

export default function PlayListItem({ game }) {
  const { t } = useTranslation();
  const { media } = useMediaHook(game.logoMediaId);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();

  const targetGroups = ageRangeTogroups(game.ageRange);
  let targetAudience = '';
  if (targetGroups.length > 1) {
    targetAudience = t(`${targetGroups[0]}`) + ' - ' + t(`${targetGroups[1]}`);
  } else {
    targetAudience = t(`${targetGroups[0]}`);
  }

  return (
    <Grid item md={12} sm={12}>
      <Card>
        <ErrorBoundary fallback={<GameItemErrorFallback />}>
          <CardContent sx={{ padding: 0 }}>
            <Box component={'div'} className='flex flex-row '>
              <div className='p-1 flex flex-1 flex-row items-center justify-between'>
                <Typography variant='h5'>{game.title}</Typography>({game.publishedAt ? t('published') : t('unpublished')})
              </div>
            </Box>
            <Box>
              <Stack direction={{ md: 'row', sm: 'column' }} spacing={{ xs: 0, md: 2 }} sx={{ p: 1, borderTop: 0.5, borderColor: 'lightgray', color: 'gray' }}>
                {!imageLoaded && <Skeleton variant='rectangular' sx={{ top: 0, minHeight: 100, minWidth: 100, m: 0 }} animation='pulse' />}
                {!isEmpty(media) && <img onLoad={() => setImageLoaded(true)} src={media.url} alt='Game Logo' className='w-[100px] h-[100px] border-gray-200 border p-1 rounded shadow-md' />}
                <Typography className='px-2'>
                  {!isValueEmpty(game.description) && <Interweave content={game.description} />}
                  {isValueEmpty(game.description) && t('noDescription')}
                </Typography>
              </Stack>
              <div className='flex flex-row p-2 flex-wrap'></div>
              <div className='flex flex-1 flex-row flex-wrap border-t py-2' sx={{ p: 1, borderTop: 0.5, borderColor: 'lightgray', color: 'gray' }}>
                <div className='flex flex-row items-center p-1 border-r-2'>
                  <LightTooltip title={t('createdBy', { name: game.owner?.name })}>
                    <span className='flex items-center'>
                      <AccountCircleOutlined sx={{ fontSize: 20, color: 'primary.light' }} />
                      <span>{game.owner?.name}</span>
                    </span>
                  </LightTooltip>
                </div>
                {game.publishedAt && (
                  <div className='flex flex-row items-center  p-1 border-r-2'>
                    <LightTooltip title={t('publishedAt', { date: format(new Date(game.publishedAt), 'yyyy/MM/dd') })}>
                      <span className='flex items-center'>
                        <CalendarMonthOutlined sx={{ fontSize: 20, color: 'primary.light' }} />
                        <span>{format(new Date(game.publishedAt), 'yyyy/MM/dd')}</span>
                      </span>
                    </LightTooltip>
                  </div>
                )}
                <div className='flex flex-row items-center  p-1 border-r-2'>
                  <LightTooltip title={`${t('gameLanguage')}: ${t('lang' + game.lang)}`}>
                    <span className='flex items-center'>
                      <LanguageOutlined sx={{ fontSize: 20, color: 'primary.light' }} />
                      {game.lang}
                    </span>
                  </LightTooltip>
                </div>
                <div className='flex flex-row items-center  p-1 border-r-2'>
                  <LightTooltip title={t('playedBy', { times: game._count?.tables })}>
                    <span className='flex items-center'>
                      <Numbers sx={{ fontSize: 20, color: 'primary.light' }} />
                      <span>{game._count?.tables || ''}</span>
                    </span>
                  </LightTooltip>
                </div>
                <div className='flex flex-row items-center  p-1'>
                  <Cake sx={{ fontSize: 20, color: 'primary.light' }} />[{targetAudience}]
                </div>
              </div>
            </Box>
          </CardContent>
          <Stack direction={'row-reverse'} spacing={2} className='border-t border-gray-200 p-2 justify-between'>
            <IconButton
              color='success'
              sx={{ border: 1 }}
              title={t('createNewTable')}
              onClick={() => {
                navigate('/play/new/' + game.slug, { state: { gameId: game.id, gameTitle: game.title } });
              }}>
              <Add />
            </IconButton>
            {game.leaderboards && (
              <Button
                variant='text'
                sx={{ textTransform: 'none' }}
                startIcon={<BarChart />}
                onClick={() => {
                  navigate(`/rankings/${game.slug}`);
                }}>
                {t('leaderboards')}
              </Button>
            )}
          </Stack>
        </ErrorBoundary>
      </Card>
    </Grid>
  );
}
