import React from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { getI18n, useTranslation } from 'react-i18next';
import { LANGS } from '../../../config/config';
import { setLangCookie } from '../../../model/dao/utils/LangHelper';

const LanguageItem = ({ lang, onClick }) => {
  const { t } = useTranslation();
  return (
    <Box onClick={onClick} sx={{ '& > img': { mr: 2, flexShrink: 0 }, cursor: 'pointer' }}>
      {lang.unicodeFlag} {t(`lang${lang.code.toUpperCase()}`)}
    </Box>
  );
};

export default function LanguageMenu() {
  const { language, changeLanguage } = getI18n();
  const [langAnchor, setLangAnchor] = React.useState(null);
  const lang = LANGS[language];

  const handleOpenLangMenu = (event) => {
    setLangAnchor(event.currentTarget);
  };
  const handleCloseLangMenu = () => {
    setLangAnchor(null);
  };

  const onLangSelected = (langCode) => {
    console.log('onLangSelected: ', langCode);
    handleCloseLangMenu();
    changeLanguage(langCode);
    setLangCookie(langCode);
  };

  return (
    <>
      <Box sx={{ display: 'flex', pt: 1, cursor: 'pointer', fontSize: 30 }} onClick={(e) => handleOpenLangMenu(e)}>
        {lang.unicodeFlag}
      </Box>
      <Menu
        sx={{ mt: '45px' }}
        id='langMenu'
        anchorEl={langAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted={false}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseLangMenu}
        open={Boolean(langAnchor)}>
        {Object.keys(LANGS).map((code) => {
          return (
            <MenuItem key={'m' + code} sx={{ width: 200 }} selected={code === language}>
              <LanguageItem lang={LANGS[code]} onClick={() => onLangSelected(code)} sx={{ p: 0 }} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
