import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GameDao } from '../../model/dao/GameDao';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';
import { setGameRankings } from '../../model/store/slices/admin/AdminSlice';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function useGameRankings(slug, leaderboardId) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const rankings = useSelector((state) => state.admin.rankings[slug]);

  useEffect(() => {
    const fetchGameLeaderboards = async () => {
      try {
        dispatch(setLoading(true));
        const rankings = await GameDao.getGameRankings(slug, leaderboardId);
        dispatch(setGameRankings({ slug, rankings }));
      } catch (error) {
        enqueueSnackbar(error?.message ?? t('somethingWentWrong'), {
          variant: 'error',
        });
      } finally {
        setFetched(true);
        dispatch(resetLoading());
      }
    };
    if (!fetched) {
      fetchGameLeaderboards();
    }
  }, [fetched, dispatch, slug, leaderboardId]);

  return { rankings };
}
