import { SOUND_FX_TYPE, isValueEmpty } from '@grethics/commons';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { Button, FormControl, FormHelperText, IconButton, InputLabel, Paper, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { MEDIA_URL } from '../../../../config/config';
import { PALLETE } from '../../../../config/constants';
import '../../../../editor.css';
import useGameHook from '../../../../hooks/make/useGameHook';
import { getSoundFxUrl } from '../../../../utils/Utils';
import PageHeader from '../../../components/common/PageHeader';
import { NavigateAwayPrompt } from '../../../components/make/NavigateAwayPrompt';
import MediaLibraryModal from '../../../components/make/MediaLibraryModal';
import MediaViewer from '../../../components/play/MediaViewer';

export default function SoundsConfigPage() {
  const bgFxLibRef = useRef();
  const walkFxLibRef = useRef();
  const successFxLibRef = useRef();
  const failureFxLibRef = useRef();
  const tieFxLibRef = useRef();
  const winFxLibRef = useRef();
  const { t } = useTranslation();
  const { updateGameSounds } = useGameHook();

  const { game, loading } = useSelector((state) => state.make.current ?? {});

  const { bgFxId, walkFxId, successFxId, failureFxId, tieFxId, winFxId, diceFxId } = game;
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      bgFxId: null,
      diceFxId: null,
      walkFxId: null,
      successFxId: null,
      failureFxId: null,
      tieFxId: null,
      winFxId: null,
    },
    values: { bgFxId, diceFxId, walkFxId, successFxId, failureFxId, tieFxId, winFxId },
  });

  const onSubmit = async (data) => {
    await updateGameSounds(game.slug, { ...data });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col flex-1' autoComplete='off'>
      <PageHeader title={'Game Sound Effects'} />
      <NavigateAwayPrompt prevent={isDirty} />
      <Paper className='flex flex-col min-h-[500px] w-full items-start p-2'>
        <div className='flex flex-row gap-10 my-4 flex-wrap justify-center w-full'>
          <div className='flex flex-col flex-none'>
            <Controller
              name='bgFxId'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[100px] min-w-[200px] items-center'>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('backgroundSnd')}</InputLabel>
                    <div className='mt-3 top-0 z-0'>
                      {field.value && <MediaViewer mediaId={field.value} width={100} height={100} mediaStyle={{ backgroundColor: PALLETE.PURPLE.light, borderRadius: 5 }} />}
                      {!field.value && (
                        <div className='flex flex-col w-[120px] items-center justify-center pb-2'>
                          <ReactPlayer controls light url={`${MEDIA_URL}/sounds/bg.mp3`} width={180} height={100} style={{ backgroundColor: PALLETE.SUCCESS.light }} />
                          {/* <audio src={`${MEDIA_URL}/sounds/bg.mp3`} controls className='w-[100px] h-[100px] rounded' style={{ backgroundColor: PALLETE.SUCCESS.light }} /> */}
                          <Typography variant='caption' className='absolute bottom-5'>
                            {t('defaultSnd')}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className='flex flex-row justify-between pt-2 w-[100px] absolute top-1'>
                      <IconButton
                        onClick={() => {
                          bgFxLibRef.current?.show(game.id);
                        }}
                        sx={{ borderRadius: 1, py: 1, px: 0 }}
                        color='primary'
                        className='z-10'>
                        <Edit />
                      </IconButton>
                      {field.value && (
                        <IconButton
                          onClick={() => {
                            setValue(field.name, null, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                          sx={{ borderRadius: 1, py: 1, px: 0 }}
                          color='error'
                          className='z-10'>
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <FormHelperText>{error?.message}</FormHelperText>
                  <div>
                    <MediaLibraryModal
                      ref={bgFxLibRef}
                      mediaId={field.value}
                      mediaTypes={['audio']}
                      onSelect={(mediaId) => {
                        setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                        // trigger(field.name);
                      }}
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
          <div className='flex flex-col flex-none'>
            <Controller
              name='diceFxId'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[100px] min-w-[200px] items-center'>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('diceRollingSnd')}</InputLabel>
                    <div className='mt-3 top-0 z-0'>
                      {field.value && <MediaViewer width={200} height={100} mediaId={field.value} mediaStyle={{ backgroundColor: PALLETE.PURPLE.light, borderRadius: 5 }} />}
                      {!field.value && (
                        <div className='flex flex-col w-[120px] items-center justify-center pb-2'>
                          <ReactPlayer
                            controls
                            light
                            url={getSoundFxUrl(null, SOUND_FX_TYPE.DICE)}
                            width={180}
                            height={100}
                            style={{ height: 100, width: 200, backgroundColor: PALLETE.SUCCESS.light }}
                          />
                          <Typography variant='caption' className='absolute bottom-5'>
                            {t('defaultSnd')}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className='flex flex-row justify-between pt-2 w-[100px] absolute top-1'>
                      <IconButton
                        onClick={() => {
                          bgFxLibRef.current?.show(game.id);
                        }}
                        sx={{ borderRadius: 1, py: 1, px: 0 }}
                        color='primary'
                        className='z-10'>
                        <Edit />
                      </IconButton>
                      {field.value && (
                        <IconButton
                          onClick={() => {
                            setValue(field.name, null, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                          sx={{ borderRadius: 1, py: 1, px: 0 }}
                          color='error'
                          className='z-10'>
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <FormHelperText>{error?.message}</FormHelperText>
                  <div>
                    <MediaLibraryModal
                      ref={bgFxLibRef}
                      mediaId={field.value}
                      mediaTypes={['audio']}
                      onSelect={(mediaId) => {
                        setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                      }}
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
          <div className='flex flex-col flex-none'>
            <Controller
              name='walkFxId'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[100px] min-w-[200px] items-center'>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('gooseWalkSnd')}</InputLabel>
                    <div className='mt-3 top-0 z-0'>
                      {field.value && <MediaViewer mediaId={field.value} width={100} height={100} mediaStyle={{ backgroundColor: PALLETE.PURPLE.light, borderRadius: 5 }} />}
                      {!field.value && (
                        <div className='flex flex-col w-[120px] items-center justify-center pb-2'>
                          <ReactPlayer controls light url={getSoundFxUrl(null, SOUND_FX_TYPE.WALK)} width={180} height={100} style={{ backgroundColor: PALLETE.SUCCESS.light }} />
                          <Typography variant='caption' className='absolute bottom-5'>
                            {t('defaultSnd')}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className='flex flex-row justify-between pt-2 w-[100px] absolute top-1'>
                      <IconButton
                        onClick={() => {
                          walkFxLibRef.current?.show(game.id);
                        }}
                        sx={{ borderRadius: 1, py: 1, px: 0 }}
                        color='primary'
                        className='z-10'>
                        <Edit />
                      </IconButton>
                      {field.value && (
                        <IconButton
                          onClick={() => {
                            setValue(field.name, null, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                          sx={{ borderRadius: 1, py: 1, px: 0 }}
                          color='error'
                          className='z-10'>
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <FormHelperText>{error?.message}</FormHelperText>
                  <div>
                    <MediaLibraryModal
                      ref={walkFxLibRef}
                      mediaId={field.value}
                      mediaTypes={['audio']}
                      onSelect={(mediaId) => {
                        setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                        // trigger(field.name);
                      }}
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
          <div className='flex flex-col flex-none'>
            <Controller
              name='successFxId'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[100px] min-w-[200px] items-center'>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('successSnd')}</InputLabel>
                    <div className='mt-3 top-0 z-0'>
                      {field.value && <MediaViewer mediaId={field.value} width={100} height={100} light={true} mediaStyle={{ backgroundColor: PALLETE.PURPLE.light, borderRadius: 5 }} />}
                      {!field.value && (
                        <div className='flex flex-col w-[120px] items-center justify-center pb-2'>
                          <ReactPlayer controls light url={`${MEDIA_URL}/sounds/success.mp3`} width={180} height={100} style={{ backgroundColor: PALLETE.SUCCESS.light }} />
                          {/* <audio src={`${MEDIA_URL}/sounds/success.mp3`} controls className='w-[100px] h-[100px] rounded' style={{ backgroundColor: PALLETE.SUCCESS.light }} /> */}
                          <Typography variant='caption' className='absolute bottom-5'>
                            {t('defaultSnd')}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className='flex flex-row justify-between pt-2 w-[100px] absolute top-1'>
                      <IconButton
                        onClick={() => {
                          successFxLibRef.current?.show(game.id);
                        }}
                        sx={{ borderRadius: 1, py: 1, px: 0 }}
                        color='primary'
                        className='z-10'>
                        <Edit />
                      </IconButton>
                      {field.value && (
                        <IconButton
                          onClick={() => {
                            setValue(field.name, null, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                          sx={{ borderRadius: 1, py: 1, px: 0 }}
                          color='error'
                          className='z-10'>
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <FormHelperText>{error?.message}</FormHelperText>
                  <div>
                    <MediaLibraryModal
                      ref={successFxLibRef}
                      mediaId={field.value}
                      mediaTypes={['audio']}
                      onSelect={(mediaId) => {
                        setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                      }}
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
          <div className='flex flex-col flex-none'>
            <Controller
              name='failureFxId'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[100px] min-w-[200px] items-center'>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('failureSnd')}</InputLabel>
                    <div className='mt-3 top-0 z-0'>
                      {field.value && <MediaViewer mediaId={field.value} width={100} height={100} mediaStyle={{ backgroundColor: PALLETE.PURPLE.light, borderRadius: 5 }} />}
                      {!field.value && (
                        <div className='flex flex-col w-[120px] items-center justify-center pb-2'>
                          <ReactPlayer controls light url={`${MEDIA_URL}/sounds/failure.mp3`} width={180} height={100} style={{ backgroundColor: PALLETE.SUCCESS.light }} />
                          {/* <audio src={`${MEDIA_URL}/sounds/failure.mp3`} controls className='w-[100px] h-[100px] rounded' style={{ backgroundColor: PALLETE.SUCCESS.light }} /> */}
                          <Typography variant='caption' className='absolute bottom-5'>
                            {t('defaultSnd')}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className='flex flex-row justify-between pt-2 w-[100px] absolute top-1'>
                      <IconButton
                        onClick={() => {
                          failureFxLibRef.current?.show(game.id);
                        }}
                        sx={{ borderRadius: 1, py: 1, px: 0 }}
                        color='primary'
                        className='z-10'>
                        <Edit />
                      </IconButton>
                      {field.value && (
                        <IconButton
                          onClick={() => {
                            setValue(field.name, null, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                          sx={{ borderRadius: 1, py: 1, px: 0 }}
                          color='error'
                          className='z-10'>
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <FormHelperText>{error?.message}</FormHelperText>
                  <div>
                    <MediaLibraryModal
                      ref={failureFxLibRef}
                      mediaId={field.value}
                      mediaTypes={['audio']}
                      onSelect={(mediaId) => {
                        setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                      }}
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
          <div className='flex flex-col flex-none'>
            <Controller
              name='tieFxId'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[100px] min-w-[200px] items-center'>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('tieSnd')}</InputLabel>
                    <div className='mt-3 top-0 z-0'>
                      {field.value && (
                        <div className='flex flex-col w-[120px] items-center justify-center pb-2'>
                          <MediaViewer mediaId={field.value} mediaStyle={{ height: 100, width: 100, backgroundColor: PALLETE.PURPLE.light, borderRadius: 5 }} />
                        </div>
                      )}
                      {!field.value && (
                        <div className='flex flex-col w-[120px] items-center justify-center pb-2'>
                          <ReactPlayer controls light url={`${MEDIA_URL}/sounds/tie.mp3`} width={180} height={100} style={{ backgroundColor: PALLETE.SUCCESS.light }} />
                          {/* <audio src={`${MEDIA_URL}/sounds/tie.mp3`} controls className='w-[100px] h-[100px] rounded' style={{ backgroundColor: PALLETE.SUCCESS.light }} /> */}
                          <Typography variant='caption' className='absolute bottom-5'>
                            {t('defaultSnd')}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className='flex flex-row justify-between pt-2 w-[100px] absolute top-1'>
                      <IconButton
                        onClick={() => {
                          tieFxLibRef.current?.show(game.id);
                        }}
                        sx={{ borderRadius: 1, py: 1, px: 0 }}
                        color='primary'
                        className='z-10'>
                        <Edit />
                      </IconButton>
                      {field.value && (
                        <IconButton
                          onClick={() => {
                            setValue(field.name, null, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                          sx={{ borderRadius: 1, py: 1, px: 0 }}
                          color='error'
                          className='z-10'>
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <FormHelperText>{error?.message}</FormHelperText>
                  <div>
                    <MediaLibraryModal
                      ref={tieFxLibRef}
                      mediaId={field.value}
                      mediaTypes={['audio']}
                      onSelect={(mediaId) => {
                        setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                      }}
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
          <div className='flex flex-col flex-none'>
            <Controller
              name='winFxId'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[100px] min-w-[200px] items-center'>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('winSnd')}</InputLabel>
                    <div className='mt-3 top-0 z-0'>
                      {field.value && <MediaViewer mediaId={field.value} mediaStyle={{ height: 100, width: 100, backgroundColor: PALLETE.PURPLE.light, borderRadius: 5 }} />}
                      {!field.value && (
                        <div className='flex flex-col w-[120px] items-center justify-center pb-2'>
                          <ReactPlayer
                            controls
                            light
                            url={`${MEDIA_URL}/sounds/win.mp3`}
                            config={{
                              file: {
                                attributes: {
                                  crossOrigin: 'true',
                                },
                              },
                            }}
                            width={180}
                            height={100}
                            style={{ backgroundColor: PALLETE.SUCCESS.light }}
                          />
                          {/* <audio src={`${MEDIA_URL}/sounds/win.mp3`} controls className='w-[100px] h-[100px] rounded' style={{ backgroundColor: PALLETE.SUCCESS.light }} /> */}
                          <Typography variant='caption' className='absolute bottom-5'>
                            {t('defaultSnd')}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className='flex flex-row justify-between pt-2 w-[100px] absolute top-1'>
                      <IconButton
                        onClick={() => {
                          winFxLibRef.current?.show(game.id);
                        }}
                        sx={{ borderRadius: 1, py: 1, px: 0 }}
                        color='primary'
                        className='z-10'>
                        <Edit />
                      </IconButton>
                      {field.value && (
                        <IconButton
                          onClick={() => {
                            setValue(field.name, null, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                          sx={{ borderRadius: 1, py: 1, px: 0 }}
                          color='error'
                          className='z-10'>
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <FormHelperText>{error?.message}</FormHelperText>
                  <div>
                    <MediaLibraryModal
                      ref={winFxLibRef}
                      mediaId={field.value}
                      mediaTypes={['audio']}
                      onSelect={(mediaId) => {
                        setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                      }}
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
        </div>
      </Paper>
      <Paper className='flex flex-row flex-1 mt-1 p-2 items-center justify-between'>
        <div>
          {!isValueEmpty(dirtyFields) && (
            <Button
              startIcon={<Close />}
              variant='outlined'
              color='error'
              disabled={loading}
              onClick={() => {
                reset();
              }}>
              {t('cancel')}
            </Button>
          )}
        </div>
        <Button startIcon={<Check />} variant='outlined' color='primary' type='submit' disabled={isValueEmpty(dirtyFields) || loading}>
          {t('save')}
        </Button>
      </Paper>
    </form>
  );
}
