import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AlertDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [actions, setActions] = useState([]);
  const { t } = useTranslation();
  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show({ title, message, actions }) {
      setTitle(title);
      setMessage(message);
      setActions(actions ?? []);
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{title ?? t('headsUp')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map((action, idx) => (
          <Button
            key={`action${idx}`}
            onClick={() => {
              if (action.callback) {
                action.callback();
              }
              handleClose();
            }}
            color={action.color ?? 'primary'}>
            {action.title ?? 'Action ' + idx}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
});

export default AlertDialog;
