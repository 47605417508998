import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//const vMinMargin = 60 + 122 + 100; //header + footer + playerPads on top
const vMinMargin = 100; //header + footer + playerPads on top
const aspect = ['horizontal', 'vertical', 'square'];

export default function useGameMetrics(outer, canvas, gridSize = { width: 16, height: 10 }) {
  const [canvasWidth, setCanvasWidth] = useState();
  const [canvasHeight, setCanvasHeight] = useState();
  const { drawerOpen } = useSelector((state) => state.ui);
  useEffect(() => {
    function handleResize() {
      if (outer && canvas) {
        const outerH = outer.offsetHeight-90;
        const outerW = outer.offsetWidth;
        const canvasWidth = Math.min(outerW, outerH * (gridSize.width / gridSize.height));
        const canvasHeight = Math.min(outerH, outerW * (gridSize.height / gridSize.width));
        canvas.style.width = `${canvasWidth}px`;
        canvas.style.height = `${canvasHeight}px`;
        setCanvasWidth(canvasWidth);
        setCanvasHeight(canvasHeight);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [outer, canvas, drawerOpen, gridSize.width, gridSize.height]);

  const cellSize = Math.floor((canvasWidth - canvasWidth * 0.001) / gridSize.width);
  const cellPadding = Math.floor(cellSize * 0.015);
  const squareSize = cellSize - cellPadding * 2;
  const avatarSize = cellSize - 15;

  return { canvasWidth, canvasHeight, cellSize, cellPadding, squareSize, avatarSize };
}
