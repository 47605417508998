import React, { useEffect } from 'react';
import useMediaHook from '../../../hooks/useMediaHook';
import { isEmpty } from 'lodash';
import MediaError from './MediaError';
import ReactPlayer from 'react-player';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function MediaViewer({ mediaId, showTitle, width, height, mediaStyle, onMediaLoad, onMediaError, autoPlay, light }) {
  const { t } = useTranslation();
  const { media, loading, error } = useMediaHook(mediaId);
  useEffect(() => {
    if (error && onMediaError) {
      onMediaError();
    }
    if (!isEmpty(media) && onMediaLoad) {
      onMediaLoad(true);
    }
  }, [media, error, onMediaLoad, onMediaError]);

  if (loading) {
    return <div className='w-full h-[400px] bg-slate-400 animate-pulse mb-2'></div>;
  }
  if (error) {
    return (
      <div className='w-full h-[200px] bg-slate-400 text-center flex-col flex flex-1 items-center mb-2'>
        <MediaError stroke='gray' {...mediaStyle} />
        <p>{t('couldNotLoadMedia')}</p>
      </div>
    );
  }
  if (isEmpty(media)) {
    const mediaUrl = '/img/avatars/default-image.svg';
    return (
      <div className='flex flex-col items-center'>
        <img src={mediaUrl} alt='' width={width} height={height} style={{ objectFit: 'cover' }} />
      </div>
    );
  }
  const { mimeType } = media;

  const [type, extension] = mimeType.split('/');

  if (type === 'image')
    return (
      <div className='flex flex-col items-center'>
        {showTitle && <Typography>{media.title ?? ''}</Typography>}
        <img src={media.url} alt='' width={width} height={height} style={{ ...(mediaStyle ?? {}), width, height }} />
      </div>
    );
  return (
    <div style={{ marginBottom: 8 }}>
      {false && <Typography>{media.title ?? ''}</Typography>}
      <ReactPlayer controls light={light} url={media.url} width={width} height={height} style={{ ...mediaStyle }} />
    </div>
  );
}
