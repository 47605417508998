import { GAME_STATUS } from '@grethics/commons';
import { PlayCircle } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { playerColor } from '../../../config/constants';
import { playerNameAcronym } from '../../../utils/GameUtils';
import PlayerAvatar from './PlayerAvatar';
import { useTranslation } from 'react-i18next';

export default function PlayerPad({ position: side, acronyms, extraStyle = {} }) {
  const { t } = useTranslation();
  const { gameStatus, players, activeSide } = useSelector((state) => state.play.current.table);
  const player = players[side];
  const score = player?.state?.score ?? 0;
  if (!player) {
    return <></>;
  }

  function getStyle() {
    const style = { ...extraStyle, backgroundColor: playerColor.inactive[side], minWidth: '150px' };
    if (!player.connected) {
      style.backgroundColor = playerColor.offline;
      return style;
    }
    if (activeSide === side) {
      style.backgroundColor = playerColor.active[side];
    }
    return style;
  }

  const renderContent = () => {
    if ([GAME_STATUS.STARTED, GAME_STATUS.COMPLETED].includes(gameStatus)) {
      return (
        <div className='flex flex-row justify-center items-center text-4xl'>
          <div>{player?.state?.score ?? '-'}</div>
          {activeSide === side && <PlayCircle sx={{ fontSize: 30, color: 'white' }} />}
        </div>
      );
    }
    if (gameStatus === GAME_STATUS.STARTING_UP) {
      if (player.side === side) {
        return (
          <div className='w-full text-center text-4xl'>
            {player?.state?.lastRoll ?? '-'}
            {activeSide === side && <PlayCircle sx={{ fontSize: 30, color: 'white' }} />}
          </div>
        );
      } else {
        return (
          <div className='w-full text-center text-2xl'>
            {score || '-'}
            {activeSide === side && <PlayCircle sx={{ fontSize: 30, color: 'white' }} />}
          </div>
        );
      }
    } else {
      return null;
    }
  };
  return (
    <div className={`flex flex-col w-full h-full items-center justify-start shadow-md ${side === 'left' ? 'rounded-tl-xl border-r-2' : ' rounded-tr-xl border-l-2'}`} style={getStyle()}>
      <div className={`flex border-b-1 py-1 flex-row flex-nowrap justify-center items-center rounded-tl-xl'}`}>
        {player.name && <div className={`lg:text-xl text-${side === 'left' ? 'right' : 'left'} text-nowrap text-ellipsis `}>{acronyms ? playerNameAcronym(player.name) : player.name}</div>}
        {!player.name && <Typography sx={{ textTransform: 'capitalize' }}>{player.name}</Typography>}
      </div>
      <div className='flex flex-1 w-full items-start justify-between relative min-h-[16px]'>
        {!player.connected && <div className='w-full text-center lg:text-4xl md:text-2xl'>{t('notConnected')}</div>}
        {player.connected && <div className='w-full text-center lg:text-4xl md:text-2xl'>{renderContent()}</div>}
        <div className={side === 'left' ? `absolute bottom-1 right-0` : 'absolute bottom-1 left-0'}>
          <PlayerAvatar imageId={player?.imageId ?? player?.user?.imageId} />
        </div>
      </div>
    </div>
  );
}
