import { CheckCircleOutline, Close, CloudUpload, DeleteOutline, LibraryAdd, LinkOutlined, Preview } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import React, { useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import useMediaLibraryHook from '../../../hooks/make/useMediaLibraryHook';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import MediaViewer from '../play/MediaViewer';
import { MediaImportModal } from './MediaImportModal';
import { useTranslation } from 'react-i18next';
//TODO: Translate

const MediaLibraryModal = React.forwardRef(({ onSelect, mediaId, mediaTypes = [] }, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [gameId, setGameId] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(mediaId);
  const loading = useSelector((state) => state.ui.loading);
  const { media, uploadMedia, importMedia, deleteMedia } = useMediaLibraryHook(gameId);
  const [previewOpen, setPreviewOpen] = useState(false);
  const mediaImporter = React.createRef();

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setGameId(null);
    setFiles([]);
    setSelectedMedia(null);
    setPreviewOpen(false);
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show(gameId, types) {
      setGameId(gameId);
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  const handleDelete = async (id) => {
    deleteMedia(id);
    setSelectedMedia(null);
  };

  const onFilesSelected = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = async () => {
    if (files.length > 0) {
      const newMedia = await uploadMedia(files, gameId);
      console.log({ newMedia });
    }
    setFiles([]);
  };

  const handleImport = async (title, url, mimeType) => {
    try {
      await importMedia(url, title, mimeType, gameId);
    } catch (err) {
      console.log('ML. Import failed.', err?.message);
    }
  };

  const handleSelect = (id) => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(id);
    }
    handleClose();
  };

  return (
    <>
      {false && (
        <button
          className='w-full bg-green-800 text-white'
          onClick={() => {
            setGameId(1);
            setOpen(true);
          }}>
          {t('openMediaLibrary')}
        </button>
      )}
      <div>
        {previewOpen && (
          <Dialog disableEscapeKeyDown maxWidth={'md'} aria-labelledby='customized-dialog-title' open={previewOpen && selectedMedia} sx={{ mx: 'auto' }}>
            <DialogContent dividers sx={{ minHeight: 100 }}>
              <MediaViewer mediaId={selectedMedia} autoPlay={true} />
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'end', bgcolor: 'lightsteelblue' }}>
              <Button variant='contained' color='primary' autoFocus onClick={() => setPreviewOpen(false)}>
                {t('ok')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Dialog fullScreen disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
          <DisposableDialogTitle id='customized-dialog-title'>{t('mediaLibrary')}</DisposableDialogTitle>
          <DialogContent dividers sx={{ minHeight: 500 }}>
            {!loading && (
              <Typography variant='body1' sx={{ minHeight: 30 }}>
                {media?.length > 0 && !selectedMedia && <>{t('selectMediaToManage')}</>}
                {media?.length === 0 && <>{t('noMediaFilesInLib')}</>}
              </Typography>
            )}
            {loading && (
              <div className='min-h-[30px]'>
                <LinearProgress />
              </div>
            )}
            <div className='flex flex-row flex-wrap'>
              {(media ?? []).map((m) => {
                const mediaMimeType = (m?.mimeType ?? '').split('/')[0];
                if (mediaTypes.length > 0 && !mediaTypes.includes(mediaMimeType)) {
                  return null;
                }
                return (
                  <div className='flex flex-col' key={m.id}>
                    <div className={m.id === selectedMedia ? `p-2 border-2 rounded border-emerald-600 items-center` : 'p-2'}>
                      <div className='border-gray-200 border p-2 rounded cursor-pointer' onClick={() => setSelectedMedia(selectedMedia === m.id ? null : m.id)}>
                        {mediaMimeType === 'audio' && <Typography>{m.title}</Typography>}
                        <MediaViewer
                          light={true}
                          showTitle={selectedMedia === m.id}
                          width={150}
                          height={mediaMimeType === 'audio' ? 125 : 150}
                          mediaStyle={{ objectFit: 'cover', borderRadius: 5, backgroundColor: 'lightgray' }}
                          mediaId={m.id}
                          onMediaLoad={() => {}}
                          onMediaError={() => {}}
                          autoPlay={false}
                        />
                      </div>
                      {selectedMedia === m.id && (
                        <div className='flex flex-row flex-1 justify-between'>
                          <IconButton variant='contained' title={t('delete')} color='error' autoFocus onClick={() => handleDelete(m.id)}>
                            <DeleteOutline />
                          </IconButton>
                          <IconButton
                            variant='contained'
                            title={t('preview')}
                            color='secondary'
                            autoFocus
                            onClick={() => {
                              setPreviewOpen(true);
                            }}>
                            <Preview />
                          </IconButton>
                          <IconButton variant='contained' title={t('selectToUse')} color='success' autoFocus onClick={() => handleSelect(m.id)}>
                            <CheckCircleOutline />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', bgcolor: 'primary.light' }}>
            <MediaImportModal ref={mediaImporter} onComplete={handleImport} />
            {files.length === 0 && (
              <Button sx={{ mx: 2 }} component='label' variant='contained' color='primary' startIcon={<LibraryAdd />}>
                {t('selectFilesToUpload')}
                <input hidden name='files' type='file' multiple onChange={onFilesSelected} />
              </Button>
            )}
            {files.length > 0 && (
              <LoadingButton sx={{ mx: 2 }} onClick={handleUpload} variant='contained' color='secondary' startIcon={<CloudUpload />}>
                {t('uploadFiles', { num: files.length })}
              </LoadingButton>
            )}
            <Button
              component='label'
              variant='contained'
              color='primary'
              startIcon={<LinkOutlined />}
              onClick={() => {
                if (mediaImporter.current) {
                  mediaImporter.current.show();
                }
              }}>
              {t('importMediaFromUrl')}
            </Button>
            <Stack direction={'row'} spacing={2}>
              <Button variant='contained' color='error' startIcon={<Close />} autoFocus onClick={handleClose}>
                {t('close')}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
});

export default MediaLibraryModal;
