import { QuestionType } from '@grethics/commons';
import { Add, ArrowDownward, ArrowDropDown, ArrowDropUp, ArrowUpward, Delete } from '@mui/icons-material';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Radio, Select, TextField, TextareaAutosize, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { OptionCodes } from '../../../utils/Utils';
import { useTranslation } from 'react-i18next';

const QuestionOptionsForm = ({ qType }) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const { fields, append, remove, move, update, replace } = useFieldArray({ name: 'body.options', control });

  return (
    <div className='flex flex-col flex-1 gap-4'>
      <InputLabel className='ml-2'>{t('options')}:</InputLabel>
      <div className='p-2'>
        <div className='flex flex-col w-full gap-4 border rounded-md p-2'>
          <div className='grid grid-cols-12 w-full gap-4'>
            <div className='col-span-9'>
              <Typography variant='body1'>{t('option')}</Typography>
            </div>
            <div className='col-span-1'>
              <Typography variant='body1'>{t('correctOption')}</Typography>
            </div>
            <div className='col-span-2 text-center'>
              <Typography variant='body1'>{t('manage')}</Typography>
            </div>
          </div>
          {fields.map((option, index) => {
            return (
              <div key={option.id} className='grid grid-cols-12 w-full gap-4'>
                <div className='col-span-9'>
                  <Controller
                    rules={{ required: t('optionRequired') }}
                    name={`body.options.${index}.description`}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl fullWidth error={error}>
                          <TextField key={option.id} label={`${t('option')} ${OptionCodes[index]}`} fullWidth {...field} />
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                </div>
                <div className='col-span-1'>
                  {qType === QuestionType.MCSS && (
                    <Controller
                      name={`body.correctAnswer`}
                      render={({ field }) => (
                        <Radio
                          sx={{ height: 55 }}
                          checked={[].concat(field.value).includes(option.code)}
                          {...field}
                          onChange={(e) => {
                            console.log(field.value);
                            setValue(field.name, [option.code], { shouldDirty: true });
                          }}
                        />
                      )}
                      control={control}
                    />
                  )}
                  {qType === QuestionType.MCMS && (
                    <Controller
                      name={`body.correctAnswer`}
                      render={({ field }) => (
                        <Checkbox
                          sx={{ height: 55 }}
                          checked={[].concat(field.value).includes(option.code)}
                          {...field}
                          onChange={(e) => {
                            console.log(field.value);
                            if (qType === QuestionType.MCMS) {
                              const value = [].concat(field.value).includes(option.code) ? field.value.filter((code) => code !== option.code) : [...field.value, option.code];
                              setValue(field.name, value, { shouldDirty: true });
                            } else {
                              setValue(field.name, [option.code]);
                            }
                          }}
                        />
                      )}
                      control={control}
                    />
                  )}
                </div>
                <div className='col-span-2 flex items-center justify-center'>
                  <IconButton
                    sx={{ padding: 0, margin: 0 }}
                    disabled={fields.length <= 2}
                    color='error'
                    onClick={() => {
                      remove(index);
                    }}>
                    <Delete />
                  </IconButton>
                  <IconButton
                    color='primary'
                    disabled={index === 0}
                    onClick={() => {
                      if (index > 0) {
                        move(index, index - 1);
                      }
                    }}>
                    <ArrowDropUp />
                  </IconButton>
                  <IconButton
                    color='primary'
                    disabled={index === fields.length - 1}
                    onClick={() => {
                      move(index, index + 1);
                    }}>
                    <ArrowDropDown />
                  </IconButton>
                </div>
              </div>
            );
          })}
          <div className='flex flex-row-reverse w-full'>
            <IconButton
              onClick={() => {
                append({ code: OptionCodes[fields.length], description: '' }, { shouldFocus: true });
              }}>
              <Add />
            </IconButton>
          </div>
        </div>
      </div>
      <div className='flex flex-col lg:flex-row flex-1 lg:items-start md:items-center gap-4'></div>
    </div>
  );
};

export default QuestionOptionsForm;
