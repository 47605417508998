import { isValueEmpty } from '@grethics/commons';
import { Check, Close, EditOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useProfile from '../../../hooks/auth/useProfile';
import AvatarEditor from '../common/AvatarEditor';
import PlayerAvatar from '../play/PlayerAvatar';
import { useTranslation } from 'react-i18next';
import { capitalizePhrase } from '../../../utils/Utils';

export default function UserProfileForm() {
  const { t } = useTranslation();
  const { loading } = useSelector((state) => state.ui);
  const [onAvatarEdit, setAvatarEdit] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [changePwd, setChangePwd] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { user, updateUserProfile } = useProfile();
  const {
    control,
    formState: { dirtyFields },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: { email: '', password: '', password2: '', name: '', country: '', city: '', picture: null },
    values: { ...user, picture: user.imageId },
  });
  const country = watch('country');
  const password = watch('password');
  useEffect(() => {
    fetch('https://countriesnow.space/api/v0.1/countries/flag/unicode')
      .then((response) => {
        response.json().then((rsp) => {
          setCountries(rsp?.data ?? []);
        });
      })
      .catch((error) => console.log('could not get Countries'));
  }, []);

  useEffect(() => {
    setCities([]);
    if (country) {
      axios
        .post('https://countriesnow.space/api/v0.1/countries/cities', { country })
        .then((response) => {
          setCities(response?.data?.data ?? []);
        })
        .catch((error) => console.log('could not get cities'));
    }
  }, [country]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (data) => {
    const { name, imageId, picture, city, country, password, newPassword } = data;
    const payload = { email: user.email, name, imageId, city, country };
    if (changePwd) {
      payload.password = password;
      payload.newPassword = newPassword;
    }
    if (!isValueEmpty(picture) && picture !== imageId) {
      payload.picture = picture;
    }
    await updateUserProfile(payload);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full  max-w-[800px]'>
        <Controller
          name='email'
          control={control}
          disabled
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl sx={{ my: 2 }} fullWidth error={!!error}>
                <TextField label={t('email')} {...field} type='email' />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        {user.provider !== 'grethics' && <div className='text-center w-full mb-8 text-xl text-gray-400'>{t('oAuthenticated', { provider: capitalizePhrase(user.provider) })}</div>}
        {user.provider === 'grethics' && (
          <div className='flex flex-row items-center w-full mb-3'>
            <InputLabel>{t('changePassword')}</InputLabel>
            <Checkbox value={changePwd} onClick={() => setChangePwd(!changePwd)} />
          </div>
        )}
        {changePwd && (
          <>
            <Controller
              name='password'
              control={control}
              rules={{
                required: t('passwordRequired'),
                minLength: { value: 6, message: t('passwordTooShort') },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl sx={{ my: 2 }} fullWidth variant='outlined'>
                    <InputLabel htmlFor='password'>{t('currentPassword')}</InputLabel>
                    <OutlinedInput
                      fullWidth
                      id='password'
                      type={showPassword ? 'text' : 'password'}
                      {...field}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Current Password'
                    />
                    <FormHelperText error={error}>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              name='newPassword'
              control={control}
              rules={{
                required: t('passwordRequired'),
                minLength: { value: 6, message: t('passwordTooShort') },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl sx={{ my: 2 }} fullWidth variant='outlined'>
                    <InputLabel htmlFor='newPassword'>{t('newPassword')}</InputLabel>
                    <OutlinedInput
                      fullWidth
                      id='newPassword'
                      type={showPassword ? 'text' : 'password'}
                      {...field}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='New Password'
                    />
                    <FormHelperText error={error}>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
          </>
        )}
        <Controller
          name='name'
          control={control}
          rules={{
            required: t('nameRequired'),
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl fullWidth error={!!error}>
                <TextField label={t('name')} {...field} type='text' />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <Controller
          name='country'
          defaultValue={''}
          control={control}
          rules={{ required: t('countryRequired') }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl error={!!error} fullWidth sx={{ mb: 1 }}>
                <Autocomplete
                  id='country-selector'
                  fullWidth
                  value={field.value ? { name: field.value } : { name: '' }}
                  sx={{ mt: 2 }}
                  options={countries}
                  autoHighlight
                  disableClearable
                  getOptionLabel={(country) => country?.name}
                  isOptionEqualToValue={(option, value) => {
                    return option.name === value.name;
                  }}
                  renderOption={(props, country) => (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {country.unicodeFlag} {country.name} ({country.iso2})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('country')}
                      value={field.value}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(event, newCountry) => {
                    setValue(field.name, newCountry?.name, { shouldDirty: true, shouldValidate: true });
                    setValue('city', '', { shouldDirty: true });
                  }}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <Controller
          name='city'
          control={control}
          rules={{ required: t('cityRequired') }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl error={!!error} fullWidth sx={{ mb: 1 }}>
                <Autocomplete
                  id='city-selector'
                  fullWidth
                  sx={{ mt: 2 }}
                  options={cities}
                  value={field.value ?? ''}
                  autoHighlight
                  disableClearable
                  getOptionLabel={(city) => city}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderOption={(props, city) => (
                    <Box component='li' {...props}>
                      {city}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('city')}
                      value={field.value}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(event, city) => {
                    setValue(field.name, city, { shouldDirty: true });
                  }}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <Controller
          name='picture'
          defaultValue={''}
          control={control}
          //rules={{ required: 'Photo is required' }}
          render={({ field, fieldState: { error } }) => {
            const fieldValue = field.value ?? '/img/avatars/default-profile.svg';
            return (
              <FormControl error={!!error} fullWidth sx={{ mb: 1 }}>
                <div className='text-gray-500 mt-2 w-full text-center content-center border rounded-sm border-gray-300'>
                  <InputLabel sx={{ top: -15, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('photo')} *</InputLabel>
                  {onAvatarEdit && (
                    <div className='w-full flex flex-col flex-1 items-center p-3'>
                      <AvatarEditor
                        showPreview={false}
                        onChange={(value) => {
                          if (value) {
                            setValue(field.name, value, { shouldDirty: true });
                          }
                          setAvatarEdit(false);
                        }}
                        onCancel={() => {
                          setAvatarEdit(false);
                        }}
                      />
                    </div>
                  )}
                  {!onAvatarEdit && (
                    <div className='w-full flex flex-col flex-1 items-center p-3'>
                      {!(fieldValue.startsWith('data') || fieldValue.startsWith('/')) && <PlayerAvatar imageId={fieldValue} width={100} height={100} />}
                      {(fieldValue.startsWith('data') || fieldValue.startsWith('/img/avatars')) && <img src={fieldValue} alt='preview' width={100} height={100} className='mx-auto' />}
                      <IconButton
                        sx={{ top: -30, left: 45 }}
                        onClick={() => {
                          setAvatarEdit(true);
                        }}>
                        <EditOutlined />
                      </IconButton>
                    </div>
                  )}
                </div>
              </FormControl>
            );
          }}
        />
        <Paper className='flex flex-row flex-1 mt-1 p-2 items-center justify-between'>
          <div>
            {!isValueEmpty(dirtyFields) && (
              <Button
                startIcon={<Close />}
                variant='outlined'
                color='error'
                disabled={loading}
                onClick={() => {
                  reset();
                }}>
                {t('cancel')}
              </Button>
            )}
          </div>
          <Button startIcon={<Check />} variant='outlined' color='primary' type='submit' disabled={isValueEmpty(dirtyFields) || loading}>
            {t('save')}
          </Button>
        </Paper>
      </form>
    </Box>
  );
}
