import { GAME_STATUS, PLAY_STATUS } from '@grethics/commons';
import { Backdrop, Box, Button, createTheme, LinearProgress, ThemeProvider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useBlocker, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { ActiveGameController } from '../../../control/play/ActiveGameController';
import { contentModalRef, feedbackModalRef, GuiService } from '../../../services/GuiService';
import Board from '../../components/play/Board';
import ContentModal from '../../components/play/ContentModal';
import FeedbackModal from '../../components/play/FeedbackModal';
import { useTranslation } from 'react-i18next';
import { NavigateAwayPrompt } from '../../components/make/NavigateAwayPrompt';

export default function GamePage() {
  const { slug } = useParams();
  const { t } = useTranslation();
  const [loadingGame, setLoadingGame] = useState(false);
  const [loadingGameError, setLoadingGameError] = useState(null);
  const online = useSelector((state) => state.play.current.table.online);
  const { playStatus, gameStatus } = useSelector((state) => state.play.current.table);
  const gameFontFamily = '"Open Sans", Verdana, sans-serif';
  const navigate = useNavigate();

  const gameTheme = useMemo(() => {
    return createTheme({
      typography: {
        fontFamily: gameFontFamily,
      },
    });
  }, [gameFontFamily]);

  /* Fetch Game Data*/
  useEffect(() => {
    const doFetch = async () => {
      try {
        setLoadingGameError(null);
        setLoadingGame(true);
        console.log('Requesting Full Game Instance for ' + slug);
        await ActiveGameController.enterGame({ slug });
        console.log('Full Game Instance for ' + slug + ' Received');
      } catch (err) {
        setLoadingGameError(err.message);
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setLoadingGame(false);
      }
    };
    try {
      if (slug) {
        doFetch();
      }
    } catch (err) {}
    return () => {
      ActiveGameController.exitGame();
    };
  }, [slug]);

  const board = useSelector((state) => state.play.current?.spec?.board);

  return (
    <ThemeProvider theme={gameTheme}>
      <div id='gamePage' style={{ display: 'flex', flexDirection: 'column', height: '100%', fontFamily: 'Open Sans', alignItems: 'center' }}>
        <ContentModal ref={contentModalRef} />
        <FeedbackModal ref={feedbackModalRef} />
        {loadingGame && (
          <div className='mb-3 flex flex-col align-center justify-top pt-2 max-w-[800px] mx-auto'>
            <Box sx={{ width: '100%' }}>
              <div className='text-xl'>Loading Game</div>
              <LinearProgress />
            </Box>
          </div>
        )}
        {loadingGameError && (
          <div className='mb-3 flex flex-col align-center justify-top pt-10 max-w-[200px] mx-auto'>
            <Link to={'/play'} className='p-3 bg-purple-400 text-white rounded-sm shadow-sm text-center font-bold'>
              Join another game
            </Link>
          </div>
        )}
        {board && <Board />}
        <Backdrop
          style={{ position: 'absolute' }}
          className='rounded-t-md'
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={gameStatus === GAME_STATUS.STARTED && playStatus === PLAY_STATUS.WAITING}>
          <div className='flex flex-col items-center'>
            <div className='text-6xl mb-3'>Opponent disconnected...</div>
            <Button variant='contained' color='warning' onClick={() => navigate('../')} title='Back to Tables'>
              Back to the Tables
            </Button>
          </div>
        </Backdrop>
        <Backdrop style={{ position: 'absolute' }} className='rounded-t-md' sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!online}>
          <div className='text-6xl'>{t('youAreOffline')}</div>
        </Backdrop>
      </div>
    </ThemeProvider>
  );
}
