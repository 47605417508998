import { useSpring } from '@react-spring/web';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActiveGameController } from '../../control/play/ActiveGameController';
import { getCellBoundsOfSquareNew } from '../../utils/GameUtils';
import DefaultWalkFx from '../../assets/sounds/walk.mp3';
import useSound from 'use-sound';
import { isValueEmpty, MOVED_BWD, MOVED_FWD, TransitionType } from '@grethics/commons';
import { setCurrentSquareAction } from '../../model/store/slices/PlaySlice';
import { getMediaUrl } from '../../utils/Utils';
const rotationsMap = {
  right2down: {},
  right2up: {},
  right2left: { rotateY: 180 },
  left2right: { rotateY: 0 },
  left2down: {},
  down2right: {},
  down2left: {},
  up2right: {},
  up2left: {},
};

export default function usePlayerAvatarHook(side, path, CellSize, walkFx) {
  const [play, { stop }] = useSound(walkFx ?? DefaultWalkFx);
  const [coords, setCoords] = useState({
    x: 0,
    y: 0,
    rotateY: 0,
    rotateX: 0,
    rotateZ: 0,
  });

  const { soundFxOn, visitedSquares } = useSelector((state) => state.play.current.table);
  const player = useSelector((state) => state.play.current.table.players[side]);
  const avatarMedia = useSelector((state) => state.play.current.spec?.game[`${side}Avatar`]);
  const mediaUrl = avatarMedia ? getMediaUrl(avatarMedia) : `/img/${side}-goose.svg`;
  const curSquare = player?.state?.curSquare ?? 0;
  const [styles, api] = useSpring(() => {
    return {
      ...coords,
      onChange: {},
    };
  }, [coords]);

  //CleanUp
  useEffect(() => {
    return () => {
      setCoords({
        x: 0,
        y: 0,
        rotateY: 0,
        rotateX: 0,
        rotateZ: 0,
      });
    };
  }, []);

  const { direction: currentAvatarDirection } = useMemo(() => {
    const cellBoundsInfo = getCellBoundsOfSquareNew(path?.asArray, curSquare, CellSize);
    return cellBoundsInfo;
  }, [path, curSquare, CellSize]);

  /* SetAvatar Cordinages when currentSquare is set */
  useEffect(() => {
    if (path) {
      const { x, y, direction } = getCellBoundsOfSquareNew(path.asArray, curSquare, CellSize);
      setCoords((prevCoords) => ({ ...prevCoords, x, y, rotateY: direction === 'left' ? 180 : 0 }));
    }
  }, [path, CellSize, curSquare]);

  const move = ({ steps, deviation }) => {
    //console.log(`${side} moves with arguments: ${JSON.stringify({ steps, deviation })}`);
    try {
      const walk = [];
      const remainingSteps = path.asArray.length - (curSquare + 1);
      const stepsToMove = steps > remainingSteps ? remainingSteps : steps;
      let lastDirection = currentAvatarDirection;
      let lastCoords = { ...coords };
      for (let i = 1; i <= stepsToMove; i++) {
        let frame = { ...lastCoords, config: { duration: 200 }, at: curSquare + i };
        const cellBoundsInfo = getCellBoundsOfSquareNew(path.asArray, curSquare + i, CellSize);
        const { x, y, direction: stepDirection } = cellBoundsInfo;
        if (lastDirection !== stepDirection) {
          const turn = rotationsMap[`${lastDirection}2${stepDirection}`];
          walk.push({ ...frame, ...turn, config: { duration: 10 } });
          for (const [key, value] of Object.entries(turn)) {
            frame[key] = value;
          }
          lastDirection = stepDirection;
        }
        frame.x = x;
        frame.y = y;
        walk.push(frame);
        lastCoords = frame;
      }
      api.start({
        to: walk,
        config: {
          duration: 100 * stepsToMove,
        },
        onStart: () => {
          if (soundFxOn) {
            play();
          }
        },
        onResolve: (result) => {
          setCoords(result.value);
          const newSquareIdx = curSquare + stepsToMove;
          if (soundFxOn) {
            stop();
          }
          ActiveGameController.setPlayerCurrentSquare(player.side, newSquareIdx);
          setTimeout(() => {
            const newSquare = path.asArray[newSquareIdx];
            const { transition } = newSquare;
            if (!isValueEmpty(transition) && !visitedSquares.find((sq) => sq.squareNum === transition.to)) {
              const newDeviation = transition.type === TransitionType.fwd ? MOVED_FWD : MOVED_BWD;
              ActiveGameController.setPlayerCurrentSquare(side, transition.to);
              setTimeout(() => {
                ActiveGameController.avatarMoved(player.side, [deviation, newDeviation]);
              }, 500);
            } else {
              ActiveGameController.avatarMoved(player.side, deviation);
            }
          }, 500);
        },
      });
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Error!!!', { variant: 'error' });
    } finally {
    }
  };

  return {
    connected: player?.connected,
    mediaUrl,
    player,
    styles,
    move,
  };
}
