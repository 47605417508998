// NavigateAwayPrompt.js
//TODO: Translate
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { GuiService } from '../../../services/GuiService';

export const NavigateAwayPrompt = ({ prevent, message }) => {
  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (prevent) {
        e.preventDefault();
        e.returnValue = message ?? 'Are you sure you want to leave?';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [prevent, message]);

  const blocker = useBlocker(() => {
    return prevent === true;
  });
  console.log({ prevent, block: blocker.state });
  useEffect(() => {
    if (blocker.state === 'blocked') {
      GuiService.showAlert({
        message: message ?? 'Are you sure you want to leave?',
        actions: [
          { title: 'Leave', color: 'error', callback: blocker.proceed },
          { title: 'Stay', color: 'success', callback: blocker.reset },
        ],
      });
    }
  }, [blocker, message]);
};
