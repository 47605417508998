import { Autocomplete, Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PlayerAvatar from '../play/PlayerAvatar';
import { EditOutlined } from '@mui/icons-material';
import AvatarEditor from './AvatarEditor';
import { AGE_GROUP } from '@grethics/commons';
import { useTranslation } from 'react-i18next';

export default function PlayerFormFields({ country, control, setValue, errors }) {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [onAvatarEdit, setAvatarEdit] = useState(false);
  useEffect(() => {
    fetch('https://countriesnow.space/api/v0.1/countries/flag/unicode')
      .then((response) => {
        response.json().then((rsp) => {
          console.log('Countries response: ', rsp);
          setCountries(rsp?.data ?? []);
        });
      })
      .catch((error) => console.log('could not get Countries'));
  }, []);

  useEffect(() => {
    setCities([]);
    if (country) {
      axios
        .post('https://countriesnow.space/api/v0.1/countries/cities', { country })
        .then((response) => {
          console.log('Cities response: ', response.data);
          setCities(response?.data?.data ?? []);
        })
        .catch((error) => console.log('could not get cities'));
    }
  }, [country]);

  return (
    <>
      <Controller
        name='name'
        defaultValue={''}
        control={control}
        rules={{ required: t('nameRequired') }}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
              <TextField label={t('playerName')} {...field} fullWidth type={'text'} />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        name='country'
        defaultValue={''}
        control={control}
        rules={{ required: t('countryRequired') }}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
              <Autocomplete
                id='country-selector'
                fullWidth
                value={{ name: field.value }}
                options={countries}
                autoHighlight
                disableClearable
                getOptionLabel={(country) => country?.name}
                renderOption={(props, country) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {country.unicodeFlag} {country.name} ({country.iso2})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('country')}
                    required
                    value={field.value}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, newCountry) => {
                  setValue(field.name, newCountry?.name, { shouldDirty: true });
                  setValue('city', null, { shouldDirty: true });
                }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        name='city'
        control={control}
        rules={{ required: t('cityRequired') }}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
              <Autocomplete
                id='city-selector'
                fullWidth
                options={cities}
                value={field.value}
                autoHighlight
                disableClearable
                getOptionLabel={(city) => city}
                renderOption={(props, city) => (
                  <Box component='li' {...props}>
                    {city}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('city')}
                    required
                    value={field.value}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, city) => {
                  setValue(field.name, city, { shouldDirty: true });
                }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        name='ageGroup'
        control={control}
        rules={{ required: t('ageGroupRequired') }}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
              <InputLabel required>{t('ageGroup')}</InputLabel>
              <Select
                label={t('ageGroup')}
                {...field}
                onChange={(e) => {
                  setValue(field.name, e.target.value, { shouldDirty: true });
                }}>
                {Object.keys(AGE_GROUP).map((st) => (
                  <MenuItem key={st} value={st}>
                    {t(`${st}`)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        name='imageId'
        defaultValue={''}
        control={control}
        rules={{ required: t('photoRequired') }}
        render={({ field, fieldState: { error } }) => {
          const fieldValue = field.value ?? '/img/avatars/default-profile.svg';
          return (
            <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
              <div className='text-gray-500 mt-2 w-full text-center content-center border rounded-sm border-gray-300'>
                <InputLabel sx={{ top: -15, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('photo')} *</InputLabel>
                {onAvatarEdit && (
                  <div className='w-full flex flex-col flex-1 items-center p-3'>
                    <AvatarEditor
                      showPreview={false}
                      onChange={(value) => {
                        if (value) {
                          setValue(field.name, value, { shouldDirty: true });
                        }
                        setAvatarEdit(false);
                      }}
                      onCancel={() => {
                        setAvatarEdit(false);
                      }}
                    />
                  </div>
                )}
                {!onAvatarEdit && (
                  <div className='w-full flex flex-col flex-1 items-center p-3'>
                    {!(fieldValue.startsWith('data') || fieldValue.startsWith('/')) && <PlayerAvatar imageId={fieldValue} width={100} height={100} />}
                    {(fieldValue.startsWith('data') || fieldValue.startsWith('/img/avatars')) && <img src={fieldValue} alt='preview' width={100} height={100} className='mx-auto' />}
                    <IconButton
                      sx={{ top: -30, left: 45 }}
                      onClick={() => {
                        setAvatarEdit(true);
                      }}>
                      <EditOutlined />
                    </IconButton>
                  </div>
                )}
              </div>
            </FormControl>
          );
        }}
      />
    </>
  );
}
