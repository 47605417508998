import { Check, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import slugify from 'slugify';
import useGameHook from '../../../hooks/make/useGameHook';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LANGS } from '../../../config/config';

const NewGameModal = React.forwardRef(({ onSave }, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const loading = useSelector((state) => state.ui.loading);
  const { checkGameSlug, createGame } = useGameHook();
  const { control, handleSubmit, reset } = useForm({ mode: 'onBlur', defaultValues: { title: '', lang: '', slug: '' } });

  const onFormSubmit = async (data) => {
    const pureTitle = data.title.replaceAll('&', 'kai').replace(/[.,\/#"'!$%\^&\*;:{}=\-_`~()]/g, '');
    data.slug = slugify(pureTitle, { trim: true, lower: true });
    const game = await createGame(data);
    if (game) {
      handleClose();
      onSave(game);
    }
  };

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    reset();
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show() {
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off' className='flex flex-col flex-1'>
        <Dialog disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} fullWidth sx={{ mx: 'auto' }}>
          <DisposableDialogTitle id='customized-dialog-title' onClose={handleClose}>
            {t('newGame')}
          </DisposableDialogTitle>
          <DialogContent dividers sx={{ minWidth: '100%' }}>
            <div className='flex flex-col flex-1'>
              <div className='flex flex-col min-h-[400px] flex-1 w-full mt-2 p-2'>
                <Stack spacing={2}>
                  <Controller
                    name={t('title')}
                    control={control}
                    rules={{
                      required: t('gameTitleRequired'),
                      validate: async (title) => {
                        const pureTitle = title.replaceAll('&', 'kai').replace(/[.,\/#"'!$%\^&\*;:{}=\-_`~()]/g, '');
                        const slug = slugify(pureTitle, { trim: true, lower: true });
                        const free = await checkGameSlug(slug);
                        return free || `${title} ${t('alreadyExists')}`;
                      },
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl error={!!error}>
                          <TextField label={t('gameTitle')} {...field} />
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    name='lang'
                    control={control}
                    rules={{ required: t('gameLanguageRequired') }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error}>
                        <InputLabel>{t('gameLanguage')}</InputLabel>
                        <Select label={t('gameLanguage')} {...field}>
                          {Object.keys(LANGS).map((code) => (
                            <MenuItem key={code} value={code}>
                              {t(`lang${code.toUpperCase()}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Stack>
              </div>
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', bgcolor: 'primary.light' }}>
            <Button variant='contained' color='error' autoFocus onClick={handleClose} startIcon={<Close />}>
              {t('cancel')}
            </Button>
            <LoadingButton loading={!!loading} loadingposition='start' variant='contained' color='primary' onClick={handleSubmit(onFormSubmit)} startIcon={<Check />}>
              {t('create')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
});

export default NewGameModal;
