import React, { useEffect } from 'react';
import SignInForm from '../../components/auth/SignInForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Link } from '@mui/material';
import { useSelector } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from '../../../config/config';
import { useTranslation } from 'react-i18next';

export default function SignInPage() {
  const location = useLocation();
  const { t } = useTranslation();
  const origin = location?.state?.from?.pathname ?? '/';
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.user);
  useEffect(() => {
    if (user) {
      //console.log('signinPage navigating to origin...');
      navigate(origin);
    }
  }, [user, navigate, origin]);
  //console.log({ GOOGLE_CLIENT_ID });
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className='h-full max-w-md mx-auto'>
        <SignInForm redirectTo={origin} />
        <Grid container>
          <Grid item xs>
            <Link href='./reset'>{t('forgotPassword')}</Link>
          </Grid>
          <Grid item>
            <Link href='./signup'>{t('dontHaveAnAccount')}</Link>
          </Grid>
        </Grid>
      </div>
    </GoogleOAuthProvider>
  );
}
