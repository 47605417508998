import { Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material';

import { ContentTypes } from '@grethics/commons';
import { Add, ArrowBack, ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PALLETE } from '../../../../config/constants';
import useGameHook from '../../../../hooks/make/useGameHook';
import { getActiveRoute } from '../../../../utils/Utils';
import { useTranslation } from 'react-i18next';

export default function GameDesignPage() {
  const { t } = useTranslation();
  const { slug, contentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { drawerOpen } = useSelector((state) => state.ui);
  const [route, setRoute] = useState('board');
  const { contents, fetchGamePath, fetchGameContents } = useGameHook(slug);

  useEffect(() => {
    if (slug) {
      fetchGamePath(slug);
    }
  }, [fetchGamePath, slug]);

  useEffect(() => {
    if (slug) {
      fetchGameContents(slug);
    }
  }, [fetchGameContents, slug]);

  useEffect(() => {
    const newRoute = getActiveRoute(location?.pathname, ['content', 'board']) ?? 'board';
    setRoute(newRoute);
  }, [location.pathname]);
  const [openContents, setOpenContents] = useState(null);

  return (
    <div className='flex flex-col flex-1 w-full'>
      <Paper className='flex flex-col w-full min-w-min items-start mt-2'>
        <div className={`flex flex-row w-full mt-2`}>
          <Collapse orientation='horizontal' in={drawerOpen} className='h-full'>
            <div className='flex flex-col min-w-[300px] mr-1 p-1'>
              <div className='flex flex-row items-center w-full rounded-sm p-2 text-lg' style={{ backgroundColor: PALLETE.EMERLAND.extraLight }}>
                {route !== 'board' && (
                  <IconButton sx={{ m: 0, p: 0, mr: 1 }} onClick={() => navigate('../')}>
                    <ArrowBack />
                  </IconButton>
                )}
                <Typography variant='h5'>{t('gameElements')}</Typography>
              </div>
              <Paper className='flex flex-1 flex-col h-full mt-2'>
                <Stack direction={'column'}>
                  {ContentTypes.map((type) => {
                    return (
                      <div key={type} id={type} className='flex flex-col'>
                        <ListItemButton
                          onClick={() => {
                            setOpenContents((openTree) => (type === openTree ? null : type));
                          }}>
                          <ListItemText primary={t(`many${type}`)} primaryTypographyProps={{ fontWeight: 'bold' }} />
                          {openContents === type ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openContents === type} timeout='auto' unmountOnExit>
                          <List
                            component='div'
                            sx={{
                              bgcolor: 'background.paper',
                              position: 'relative',
                              overflow: 'auto',
                              maxHeight: 500,
                              '& ul': { padding: 0 },
                            }}
                            disablePadding>
                            <ListItemButton
                              divider
                              sx={{ alignItems: 'center' }}
                              onClick={() => {
                                navigate(`./content/new?type=${type}`);
                              }}>
                              <ListItemIcon sx={{ minWidth: 24 }}>
                                <Add color='success' />
                              </ListItemIcon>
                              <ListItemText primary={t('create')} primaryTypographyProps={{ color: 'green' }} />
                            </ListItemButton>
                            {Object.values(contents)
                              .filter((cont) => cont.type === type)
                              .sort((a, b) => a.sysTitle.localeCompare(b.sysTitle))
                              .map((cont) => (
                                <ListItemButton key={cont.id} sx={{ pl: 4 }} component={Link} to={`./content/${cont.id}`}>
                                  <ListItemText primary={cont.sysTitle ?? `${cont.type} - ${cont.id}`} primaryTypographyProps={{ borderBottom: cont.id === contentId ? '1px solid purple' : null }} />
                                </ListItemButton>
                              ))}
                          </List>
                        </Collapse>
                      </div>
                    );
                  })}
                </Stack>
              </Paper>
            </div>
          </Collapse>
          <div className='flex flex-col flex-1 p-1 ml-1'>{<Outlet />}</div>
        </div>
      </Paper>
    </div>
  );
}
