import { Box, Button, FormControl, FormHelperText, Grid, Link, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isValueEmpty } from '@grethics/commons';
import { jwtVerify } from 'jose';
import useForgotPwdHook from '../../../hooks/auth/useForgotPwdHook';
import { JWT_SECRET } from '../../../config/config';
import { useTranslation } from 'react-i18next';

export default function ChangePage() {
  const { token } = useParams();
  const { t } = useTranslation();
  const [validToken, setValidToken] = useState(false);
  const [user, setUser] = useState(null);
  const { loading, changed, error, changePassword } = useForgotPwdHook();
  const navigate = useNavigate();

  useEffect(() => {
    const veryfToken = async (token) => {
      try {
        const secretKey = new TextEncoder().encode(JWT_SECRET);
        const { payload } = await jwtVerify(token, secretKey);
        setValidToken(true);
        const { id, email } = payload;
        setUser({ id, email });
      } catch (err) {
        console.log(err);
        setValidToken(false);
      }
    };
    if (token) {
      veryfToken(token);
    }
  }, [token]);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { email: '', password: '' },
    mode: 'onChange',
  });

  const onChangeClbck = (data) => {
    console.log({ data });
    if (!isValueEmpty(data?.password)) {
      changePassword({ email: user?.email, token, password: data.password });
    }
  };

  if (changed) {
    return (
      <div className='h-full max-w-md mx-auto'>
        <Typography variant='h4' sx={{ color: 'success.main', textAlign: 'center' }}>
          {t('cool')}
        </Typography>
        <p className='text-center text-4xl'>🙂</p>
        <p className='text-center'>
          {t('passwordChanged')} <br />
        </p>
        <br />
        <Button variant='outlined' onClick={() => navigate('../auth/signin')} fullWidth>
          {t('signInWithNewPassword')}
        </Button>
      </div>
    );
  }

  return (
    <div className='h-full max-w-md mx-auto'>
      {!validToken && (
        <>
          <Typography variant='h4' sx={{ color: 'error.main', textAlign: 'center' }}>
            {t('oops')}
          </Typography>
          <p className='text-center text-4xl'>😟</p>
          <p className='text-center'>{t('invalidResetLink')}</p>
          <Grid container className='mt-4 border-t pt-4'>
            <Grid item xs>
              <Link href='../signin'>{t('signIn')}</Link>
            </Grid>
            <Grid item>
              <Link href='../signup'>{t('signUp')}</Link>
            </Grid>
          </Grid>
        </>
      )}
      {validToken && (
        <Box component={'form'} onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Typography variant='h4' sx={{ color: 'primary.main' }}>
            {t('changePassword')}
          </Typography>
          <Controller
            name='email'
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl sx={{ my: 2 }} fullWidth error={!!error}>
                  <TextField label={t('email')} {...field} value={user?.email} type='email' disabled />
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />

          <Controller
            name='password'
            control={control}
            rules={{
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Password should be at least 8 characters',
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl sx={{ my: 2 }} fullWidth error={!!error}>
                  <TextField label='New Password' {...field} type='password' />
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Button
            onClick={handleSubmit(onChangeClbck)}
            fullWidth
            disabled={loading}
            variant='outlined'
            className={loading ? 'animate-pulse' : ''}
            sx={{
              mt: 2,
              mb: 2,
              py: 2,
              width: '100%',
              textTransform: 'capitalize',
              fontSize: 20,
              fontWeight: 'light',
            }}>
            Submit
          </Button>
        </Box>
      )}
    </div>
  );
}
