import { createSlice } from '@reduxjs/toolkit';
import { arrayGroupedBy } from '../../../utils/Utils';
import { isAfter } from 'date-fns';
import { GAME_STATUS, isValueEmpty } from '@grethics/commons';

const initialState = { tableMap: {}, paging: {} };

export const GameTablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setGameTablesAction: (state, action) => {
      const { tables } = action.payload;
      const tableMap = arrayGroupedBy(tables, 'gameId');
      const paging = Object.keys(tableMap).reduce(
        (acc, gameId) => {
          acc[gameId] = 1;
          return acc;
        },
        { size: 3 }
      );
      state.tableMap = tableMap;
      state.paging = paging;
    },
    addGameTableAction: (state, action) => {
      const { table, gameId } = action.payload;
      if (!state.tableMap[gameId]) {
        state.tableMap[gameId] = [];
        state.paging[gameId] = 1;
      }
      let gameTables = state.tableMap[gameId];
      gameTables.push(table);
      gameTables.sort((a, b) => {
        return isAfter(new Date(a.createdAt), new Date(b.createdAt)) ? -1 : 1;
      });
      state.tableMap[gameId] = gameTables;
    },
    updateGameTableAction: (state, action) => {
      const { table, gameId } = action.payload;
      if (state.tableMap && state.tableMap[gameId]) {
        const idx = state.tableMap[gameId].findIndex((tbl) => tbl.slug === table?.slug);
        if (idx >= 0) {
          state.tableMap[gameId][idx] = table;
        }
      }
    },
    deleteGameTableAction: (state, action) => {
      const { slug, gameId } = action.payload;
      if (!isValueEmpty(state.tableMap[gameId])) {
        state.tableMap[gameId] = state.tableMap[gameId].filter((tbl) => tbl.slug !== slug);
        if (isValueEmpty(state.tableMap[gameId])) {
          delete state.tableMap[gameId];
          delete state.paging[gameId];
        }
      }
    },
    resetGameTableAction: (state, action) => {
      const { slug, gameId } = action.payload;
      if (!isValueEmpty(state.tableMap[gameId])) {
        const tableIdx = state.tableMap[gameId].findIndex((tbl) => tbl.slug === slug);
        if (tableIdx < 0) {
          return;
        }
        const table = state.tableMap[gameId][tableIdx];
        table.state = { gameStatus: GAME_STATUS.NOT_STARTED };
        state.tableMap[gameId][tableIdx] = { ...table };
      }
    },
    clearGameTablesAction: () => {
      return initialState;
    },
    onNextTablePage: (state, action) => {
      const { gameId } = action.payload;
      if (state.paging[gameId]) {
        state.paging[gameId]++;
      }
    },
    onPrevTablePage: (state, action) => {
      const { gameId } = action.payload;
      if (state.paging[gameId]) {
        state.paging[gameId]--;
      }
    },
    onNavToTablePage: (state, action) => {
      const { gameId, page } = action.payload;
      if (state.paging[gameId]) {
        state.paging[gameId] = page;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('play/setGameStatusAction', (state, action) => {
        const { gameId, tableId, status } = action.payload;
        const gameTables = state.tableMap[`${gameId}`];
        if (gameTables) {
          const idx = gameTables.findIndex((tbl) => {
            return tbl.id === tableId;
          });
          if (idx >= 0) {
            const table = gameTables[idx];
            table.state.gameStatus = status;
          }
        }
      })
      .addCase('play/startGameUpAction', (state, action) => {
        const { gameId, tableId } = action.payload;
        const gameTables = state.tableMap[`${gameId}`];
        if (gameTables) {
          const idx = gameTables.findIndex((tbl) => {
            return tbl.id === tableId;
          });
          if (idx >= 0) {
            const table = gameTables[idx];
            table.state.gameStatus = GAME_STATUS.STARTING_UP;
          }
        }
      })
      .addCase('play/startGamePlayAction', (state, action) => {
        const { gameId, tableId } = action.payload;
        const gameTables = state.tableMap[`${gameId}`];
        if (gameTables) {
          const idx = gameTables.findIndex((tbl) => {
            return tbl.id === tableId;
          });
          if (idx >= 0) {
            const table = gameTables[idx];
            table.state.gameStatus = GAME_STATUS.STARTED;
          }
        }
      });
  },
});

export const {
  setGameTablesAction,
  addGameTableAction,
  updateGameTableAction,
  resetGameTableAction,
  deleteGameTableAction,
  clearGameTablesAction,
  onNavToTablePage,
  onNextTablePage,
  onPrevTablePage,
} = GameTablesSlice.actions;
export default GameTablesSlice.reducer;
