import { isValueEmpty } from '@grethics/commons';
import { MEDIA_URL } from '../config/config';
import { el, enGB, it, pt, pl, sr, nl, es, bg, hu, sv, lt } from 'date-fns/locale';

export const getCurrentLocale = (currentLanguage) => {
  //TODO: Add more locales
  switch (currentLanguage) {
    case 'el':
      return el;
    case 'it':
      return it;
    case 'es':
      return es;
    case 'pt':
      return pt;
    case 'pl':
      return pl;
    case 'sr':
      return sr;
    case 'hu':
      return hu;
    case 'sw':
      return sv;
    case 'nl':
      return nl;
    case 'bg':
      return bg;
    case 'lt':
      return lt;
    default:
      return enGB;
  }
};
export const sleep = async (seconds) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export const CustomError = (code, message) => {
  let cErr = new Error(message);
  cErr.code = code;
  return cErr;
};

export const CustomResponseError = (error) => {
  if (error?.response?.data?.message) {
    const { code, message } = error?.response?.data;
    throw CustomError(code, message);
  } else {
    throw new Error(error?.message ?? 'Operation Failed');
  }
};

/* Fucntion that compares two arguments (primitive types, arrays of primitive types)*/
export const areEqual = (arg1, arg2, locale) => {
  if (!arg1 || !arg2) {
    return false;
  }

  try {
    if (Array.isArray(arg1) && Array.isArray(arg2)) {
      const a = new Set(arg1);
      const b = new Set(arg2);
      function eqSet(as, bs) {
        if (as.size !== bs.size) return false;
        for (const a of as) if (!bs.has(a)) return false;
        return true;
      }

      return eqSet(a, b);
    } else if (typeof arg1 === 'object' || typeof arg1 === 'object') {
      return null;
    } else {
      let left = (arg1 + '')
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      let right = (arg2 + '')
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      return left.toUpperCase().localeCompare(right.toUpperCase(), locale) === 0;
    }
  } catch (err) {
    return false;
  }
};

export const getMediaUrl = (media) => {
  if (!media) {
    return null;
  }
  if (media.url) {
    return media.url;
  }
  if (media.gameId) {
    return `${MEDIA_URL}/${media.gameId}/${media.id}.${media.extension}`;
  } else {
    return `${MEDIA_URL}/users/${media.id}.${media.extension}`;
  }
};

export const getSoundFxUrl = (media, fxType) => {
  if (!media) {
    if (!fxType) {
      return null;
    }
    const soundFile = fxType.toLowerCase() + '.mp3';
    return `${MEDIA_URL}/sounds/${soundFile}`;
  }
  if (media.url) {
    return media.url;
  }
  if (media.gameId) {
    return `${MEDIA_URL}/${media.gameId}/${media.id}.${media.extension}`;
  } else {
    return `${MEDIA_URL}/${media.id}.${media.extension}`;
  }
};

export const isIntanceCfgValid = (config) => {
  if (!config) {
    return false;
  }
  const { opponents, moderator, availableAt } = config;
  if (Object.values(opponents).length < 2) {
    return false;
  }

  if (!moderator && Object.values(opponents).find((o) => !o.user)) {
    return false;
  }
  return true;
};

export const arrayGroupedBy = (array, objKey) => {
  const getObjKeyValue = (item, keys) => {
    if (keys.length === 1) {
      return item[keys[0]];
    }
    const curKey = keys[0];
    keys.shift();
    return getObjKeyValue(item[curKey], keys);
  };
  if (array) {
    return array.reduce((obj, item) => {
      const key = getObjKeyValue(item, objKey.split('.'));
      if (!obj[key]) {
        obj[key] = [];
      }
      obj[key].push(item);
      return obj;
    }, {});
  }
  return {};
};

export const getActiveRoute = (path, routes = []) => {
  //console.log({ routes });
  if (path.endsWith('/')) {
    path = path.substring(0, path.length - 1);
  }
  const parts = path
    .substring(1)
    .split('/')
    .filter((part) => part !== '');
  let route = null;
  //console.log({ parts: parts.join() });
  parts.forEach((part) => {
    if (routes.includes(part)) {
      route = part;
      return false;
    }
    //console.log(`${part} is not included in ${routes.join()}`);
    return true;
  });
  //console.log({ route });
  return route;
};

export const RTEditorToolbarOptions = [
  [{ header: [2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ align: [] }],
  //['blockquote', 'code-block'],
  //[{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['link'],
  [
    //[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    //[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    //[{ direction: 'rtl' }], // text direction
    { size: ['small', false, 'large', 'huge'] },
  ], // custom dropdown
  [{ color: [] }], // dropdown with defaults from theme
  //[{ font: [] }],
  ['clean'],
];

export const OptionCodes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];

function capitalizeWord(word) {
  if (!word) {
    return word;
  }
  let lWord = word.toLowerCase();
  let cWord = lWord[0].toUpperCase();
  if (lWord.length > 1) {
    cWord += lWord.substring(1);
  }
  return cWord;
}

export const capitalizePhrase = (phrase = '', allWorlds) => {
  if (isValueEmpty(phrase)) {
    return phrase;
  }
  const words = phrase.split(' ');
  if (allWorlds) {
    const cWords = words.map((word) => capitalizeWord(word));
    return cWords.join(' ');
  } else {
    words.splice(0, 1, capitalizeWord(words[0]));
    return words.join(' ');
  }
};

export const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
