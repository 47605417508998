/* eslint-disable no-undef */
import { PLAY_STATUS, SOUND_FX_TYPE } from '@grethics/commons';
import { MusicNote, MusicOff, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { animated } from '@react-spring/web';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSound from 'use-sound';
import { MEDIA_URL } from '../../../config/config';
import { playerColor } from '../../../config/constants';
import { ActiveGameController } from '../../../control/play/ActiveGameController';
import { PassiveGameController } from '../../../control/play/PassiveGameController';
import useGameMetrics from '../../../hooks/play/useGamePlayMetrics.js';
import usePlayerAvatarHook from '../../../hooks/play/useAvatarHook';
import { setSoundBgAction, setSoundFxAction } from '../../../model/store/slices/PlaySlice';
import { getSquareVisitors } from '../../../utils/GameUtils';
import { getMediaUrl, getSoundFxUrl } from '../../../utils/Utils.js';
import DiceRollerWidget from './DiceRollerWidget.jsx';
import PlayerPad from './PlayerPad.js';
import useScript from '../../../hooks/useScript';

export default function Board() {
  const { loaded: ldrLineLoaded } = useScript('/js/leader-line.min.js');
  const dispatch = useDispatch();
  const { bgFx, walkFx } = useSelector((state) => state.play.current.spec.game);
  const board = useSelector((state) => state.play.current.spec?.board);
  const gridSize = { width: board.width ?? 10, height: board.height ?? 10 };
  const path = board?.path;
  const outer = document.getElementById('outer');
  const canvas = document.getElementById('canvas');
  const { canvasWidth, cellSize, cellPadding, squareSize, avatarSize } = useGameMetrics(outer, canvas, gridSize, true);
  const { playStatus, gameStatus } = useSelector((state) => state.play.current.table);
  const leftAvatar = usePlayerAvatarHook('left', path, cellSize, getMediaUrl(walkFx));
  const rightAvatar = usePlayerAvatarHook('right', path, cellSize, getMediaUrl(walkFx));
  const avatars = { left: leftAvatar, right: rightAvatar };
  const { soundBgOn, soundFxOn, visitedSquares } = useSelector((state) => state.play?.current?.table);
  const [doRestart, setDoRestart] = useState(false);
  const [bgFxplay, { stop: bgFxStop }] = useSound(getSoundFxUrl(bgFx, SOUND_FX_TYPE.BG), {
    volume: 0.2,
    interrupt: true,
    onend: () => {
      setDoRestart(true);
    },
  });

  const [outerWidth, setOuterWidth] = useState();
  const [outerHeight, setOuterHeight] = useState();

  useEffect(() => {
    const handleResize = () => {
      setOuterWidth(window.innerWidth);
      setOuterHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (doRestart) {
      bgFxplay();
      setDoRestart(false);
    }
  }, [doRestart, bgFxplay]);

  useEffect(() => {
    ActiveGameController.setAvatars({ left: leftAvatar, right: rightAvatar });
    PassiveGameController.setAvatars({ left: leftAvatar, right: rightAvatar });
  }, [leftAvatar, rightAvatar]);

  useEffect(() => {
    if (playStatus === PLAY_STATUS.IN_PLAY && soundBgOn) {
      bgFxplay();
    }
    return () => {
      bgFxStop();
    };
  }, [bgFxplay, playStatus, soundBgOn, bgFxStop]);

  useEffect(() => {
    if (path && ldrLineLoaded) {
      const lines = [];
      path.asArray
        .filter((sq) => sq.transition)
        .forEach((sq) => {
          const start = document.getElementById(`sq${sq.order}`);
          const end = document.getElementById(`sq${sq.transition.to}`);
          const color = sq.transition.color ? sq.transition.color : sq.transition.type === 'fwd' ? 'lime' : 'red';
          if (start && end) {
            lines.push(
              new LeaderLine(start, end, {
                size: 8,
                color,
                startSocket: sq.transition.takeoff ?? 'auto',
                endSocket: sq.transition.landing ?? 'auto',
                startPlug: 'disc',
                dash: sq.transition.line === 'dash' ? { animation: !!sq.transition.animated } : false,
              })
            );
          }
        });
      return () => lines.forEach((line) => line.remove());
    }
    // playStatus and gameStatus where put in dependency list in purpose (board is moving a bit when status is changing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldrLineLoaded, playStatus, gameStatus]);

  if (isEmpty(board)) {
    return <></>;
  }

  const doTest = () => {};

  const toogleSoundBg = () => {
    dispatch(setSoundBgAction({ soundBgOn: !soundBgOn }));
  };

  const toogleSoundFx = () => {
    dispatch(setSoundFxAction({ soundFxOn: !soundFxOn }));
  };

  let boardBgUrl = undefined;
  if (board?.bgMedia) {
    boardBgUrl = `${MEDIA_URL}/${board.bgMedia.gameId}/${board.bgMedia.id}.${board.bgMedia.extension}`;
  }
  const renderSquare = (square) => {
    const squareStyle = {};
    if (!square) {
      return null;
    }
    let bgColor = board.sections[square?.sectionId]?.color;
    if (square.type === 'START') {
      bgColor = 'lime';
    }
    if (square.type === 'END') {
      bgColor = 'red';
    }
    squareStyle.background = bgColor;
    squareStyle.opacity = 0.75;
    //squareStyle.borderWidth = square.obligatory ? 5 : 0;

    squareStyle.borderColor = square.obligatory ? 'coral' : 'white';
    squareStyle.borderWidth = square.obligatory ? 5 : 0;
    const orderStyle = { position: 'absolute', top: 0, right: 2, color: 'black', width: 25, textAlign: 'center', background: 'white', borderRadius: 15 };
    const visitors = getSquareVisitors(visitedSquares, square.order);
    if (visitors.length === 1) {
      orderStyle.background = playerColor.active?.[visitors[0]];
    } else if (visitors.length === 2) {
      orderStyle.background = `linear-gradient(${playerColor.active?.[visitors[0]]}, ${playerColor.active?.[visitors[1]]})`;
    }
    orderStyle.borderRadius = 15;
    const { bgMedia } = square;
    let mediaUrl = undefined;
    if (bgMedia) {
      mediaUrl = `${MEDIA_URL}/${bgMedia.gameId}/${bgMedia.id}.${bgMedia.extension}`;
    }
    return (
      <div id={`sq${square.order}`} style={{ position: 'relative', width: squareSize, height: squareSize, ...squareStyle }} className={`flex justify-center items-center`}>
        {!['START', 'END'].includes(square.type) && <div style={orderStyle}>{square.order}</div>}
        {square.bgMedia && (
          <div className=''>
            <img src={mediaUrl} alt='' />
          </div>
        )}
      </div>
    );
  };

  const renderCell = (index) => {
    const row = Math.floor(index / board.width);
    const col = index - row * board.width;
    const square = path.asMap[`${row}_${col}`];
    return (
      <div key={index} style={{ borderWidth: square ? 1 : 0, width: cellSize, height: cellSize, padding: cellPadding / 4 }} className={'bg-transparent flex items-center justify-center'}>
        {renderSquare(square)}
      </div>
    );
  };
  //const outerDirection = hMargin > 250 ? 'row' : 'column';
  const outerDirection = 'column';
  return (
    <div
      id='outer'
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        width: outerWidth,
        height: outerHeight - 70,
      }}>
      <div className='flex flex-none flex-row rounded-t-lg' style={{ width: canvasWidth, minHeight: 90 }}>
        <PlayerPad position={'left'} />
        <PlayerPad position={'right'} />
      </div>
      <div id='inner' className={`flex flex-col flex-1 items-center justify-column bg-slate-400`}>
        <div id='canvas' style={{ backgroundImage: `url(${boardBgUrl})` }} className='flex w-full flex-wrap items-center justify-center shadow-md bg-cover relative'>
          {Array.from(Array(gridSize.width * gridSize.height)).map((_, index) => {
            return renderCell(index);
          })}
          {avatars.left.connected && (
            <animated.img
              src={avatars.left.mediaUrl}
              onClick={() => {}}
              alt='Cass A'
              title='Class A'
              style={{
                ...avatars.left.styles,
                cursor: 'pointer',
                position: 'absolute',
                top: 10,
                left: 10,
                width: avatarSize,
                height: avatarSize,
              }}
            />
          )}
          {avatars.right.connected && (
            <animated.img
              src={avatars.right.mediaUrl}
              onClick={() => {}}
              alt='Cass A'
              title='Class A'
              style={{
                ...avatars.right.styles,
                cursor: 'pointer',
                position: 'absolute',
                top: 9,
                left: 15,
                width: avatarSize,
                height: avatarSize,
              }}
            />
          )}
          <Fab size='small' aria-label='soundBg' sx={{ position: 'absolute', top: 16, left: 16 }} onClick={toogleSoundBg}>
            {!soundBgOn && <MusicOff />}
            {soundBgOn && <MusicNote />}
          </Fab>
          <Fab size='small' aria-label='soundFx' sx={{ position: 'absolute', top: 16, right: 16 }} onClick={toogleSoundFx}>
            {!soundFxOn && <VolumeOff />}
            {soundFxOn && <VolumeUp />}
          </Fab>
          {/* <Fab size='small' aria-label='test' sx={{ position: 'absolute', top: 16, left: 450 }} onClick={doTest}>
                <Check />
              </Fab> */}
        </div>
        <DiceRollerWidget boardSize={canvasWidth} />
      </div>
    </div>
  );
}
