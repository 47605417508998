import { BoardSquare, GAME_STATUS, PLAY_STATUS, PathSection, PlayerRecord, ScreenSizeMap, UserInfo, AGE_GROUP, isValueEmpty, SquareTypes, FinalSquareTypes } from '@grethics/commons';
import { isAfter } from 'date-fns';
import { t } from 'i18next';

export const BoardSize: ScreenSizeMap = {
  xl: 1000,
  lg: 800,
  md: 640,
  sm: 500,
};

export const CellSize: ScreenSizeMap = {
  xl: 100,
  lg: 80,
  md: 64,
  sm: 50,
};

const cellPadding = 4;

export const SquareSize: ScreenSizeMap = {
  xl: CellSize.xl - cellPadding,
  lg: CellSize.lg - cellPadding,
  md: CellSize.md - cellPadding,
  sm: CellSize.sm - cellPadding,
};

export const AvatarSize: ScreenSizeMap = {
  xl: 80,
  lg: 65,
  md: 50,
  sm: 34,
};

/* Rotations to rotate avatars around the Y axis only */
export const rotationsMap = {
  right2down: {},
  right2up: {},
  right2left: { rotateY: 180 },
  left2right: { rotateY: 180 },
  left2down: {},
  down2right: {},
  down2left: {},
  up2right: {},
  up2left: {},
};

export const getCellBoundsOfSquare = (
  path: BoardSquare[],
  idx: number,
  sizeScale: keyof ScreenSizeMap
): {
  x: number;
  y: number;
  side: number;
  direction?: string;
} => {
  if (!path || !path[idx]) {
    return { x: 0, y: 0, side: 0, direction: 'right' };
  }
  const square = path[idx];
  const { row, col } = square.coords;
  let direction = 'right';
  if (idx >= 0 && idx < path.length - 1) {
    const nextSquare = path[idx + 1];
    const { col: nextCol } = nextSquare.coords;
    if (nextCol !== col) {
      direction = nextCol > col ? 'right' : 'left';
    }
  } else if (idx === path.length - 1) {
    const prevSquare = path[idx - 1];
    direction = col < prevSquare.coords.col ? 'left' : 'right';
  }
  return {
    x: CellSize[sizeScale] * col,
    y: row * CellSize[sizeScale],
    side: CellSize[sizeScale],
    direction,
  };
};
export const getCellBoundsOfSquareNew = (
  path: BoardSquare[],
  idx: number,
  cellSize: number
): {
  x: number;
  y: number;
  side: number;
  direction?: string;
} => {
  if (!path || !path[idx]) {
    return { x: 0, y: 0, side: 0, direction: 'right' };
  }
  const square = path[idx];
  const { row, col } = square.coords;
  let direction = 'right';
  if (idx >= 0 && idx < path.length - 1) {
    const nextSquare = path[idx + 1];
    const { col: nextCol } = nextSquare.coords;
    if (nextCol !== col) {
      direction = nextCol > col ? 'right' : 'left';
    }
  } else if (idx === path.length - 1) {
    const prevSquare = path[idx - 1];
    direction = col < prevSquare.coords.col ? 'left' : 'right';
  }
  return {
    x: cellSize * col,
    y: row * cellSize,
    side: cellSize,
    direction,
  };
};

export const getCellBoundsByRowCol = (
  row: number,
  col: number,
  range: keyof ScreenSizeMap
): {
  x: number;
  y: number;
  side: number;
} => {
  return {
    x: CellSize[range] * col,
    y: CellSize[range] * row,
    side: CellSize[range],
  };
};

export const getSquare = (path: BoardSquare[], idx: number) => {
  return path.find((square) => square.order === idx);
};

export const getGameStatus = (state?: any): string => {
  if (!state) {
    return GAME_STATUS.NOT_STARTED;
  } else {
    return state.gameStatus;
  }
};

export const getPlayStatus = (state?: any): string => {
  if (!state || !state.playersOnBoard) {
    return PLAY_STATUS.WAITING;
  }
  if ([GAME_STATUS.STARTING_UP, GAME_STATUS.STARTED].includes(state.gameStatus)) {
    return PLAY_STATUS.IN_PLAY;
  }
  if (GAME_STATUS.COMPLETED === state.gameStatus) {
    return PLAY_STATUS.DISABLED;
  }
  return PLAY_STATUS.READY_TO_PLAY;
};

const getPathArray = (path: BoardSquare[]) => {
  const pathArray = path.map((square: BoardSquare) => {
    return { ...square };
  });
  return pathArray;
};

export const getOtherSide = (me: { side: string } | string): string => {
  if (typeof me === 'string') {
    return me === 'left' ? 'right' : 'left';
  }
  return me.side === 'left' ? 'right' : 'left';
};

const getPathMap = (path: BoardSquare[]) =>
  path.reduce((map: { [key: string]: BoardSquare }, square) => {
    const key: string | number = `${square.coords.row}_${square.coords.col}`;
    map[key] = square;
    return map;
  }, {});

export const parseGoosePath = (path: BoardSquare[]) => {
  const asArray = getPathArray(path.sort((a, b) => (a.order ?? 1) - (b.order ?? 0)));
  const asMap = getPathMap(asArray);
  return { asArray, asMap };
};

export const parsePathSections = (sections: PathSection[]): { [key: string]: PathSection } => {
  return sections.reduce((sectionMap: { [key: string]: PathSection }, section: PathSection, idx) => {
    sectionMap[section.id] = section;
    return sectionMap;
  }, {});
};

export const canUserJoinTable = (authUser: UserInfo, availableAt: string, opponents: [PlayerRecord], moderator: UserInfo | null, status: string | null) => {
  if (GAME_STATUS.COMPLETED === status) {
    return false;
  }
  if (availableAt) {
    if (!isAfter(new Date(), new Date(availableAt))) {
      return false;
    }
  }
  if (authUser.id === moderator?.id) {
    return true;
  }
  if (!moderator && Object.values(opponents).findIndex((op) => op.user?.id === authUser.id) >= 0) {
    return true;
  }
};

export const createOpponentRecord = (gamePlayer: { player: { name: string; id: number; user: any } }) => {
  if (!gamePlayer || !gamePlayer.player) {
    return null;
  }
  const { player } = gamePlayer;
  let record = { name: player.name, user: player.user, player: { ...player, user: undefined } };
  return record;
};

export const hasSquareBeenVisited = (allSqVisits: { squareNum: number; side: string }[], sqNum: number) => {
  const visit = allSqVisits.find((visit) => visit.squareNum === sqNum);
  return visit ? visit.side : null;
};

export const getSquareVisitors = (allSqVisits: { squareNum: number; side: string }[], sqNum: number): string[] => {
  let visitors = allSqVisits.filter((visit) => visit.squareNum === sqNum).map((visit) => visit.side);
  return visitors;
};

export const ageGroupToRange = (ageGroup: string) => {
  let pMin = 6;
  let pMax = 100;
  switch (ageGroup) {
    case AGE_GROUP.PRIMARY_SCHOOL: {
      pMin = 6;
      pMax = 11;
      break;
    }
    case AGE_GROUP.LOWER_HIGH_SCHOOL: {
      pMin = 12;
      pMax = 14;
      break;
    }
    case AGE_GROUP.UPPER_HIGH_SCHOOL: {
      pMin = 15;
      pMax = 17;
      break;
    }
    default: {
      pMin = 18;
      pMax = 100;
      break;
    }
  }

  return { minAge: pMin, maxAge: pMax };
};

export const ageRangeTogroups = (ageRange: { minAge: number; maxAge: number }): string[] => {
  const groups: string[] = [];
  if (ageRange.minAge <= 6 && ageRange.maxAge >= 11) {
    groups.push(AGE_GROUP.PRIMARY_SCHOOL);
  }
  if (ageRange.minAge <= 12 && ageRange.maxAge >= 14) {
    groups.push(AGE_GROUP.LOWER_HIGH_SCHOOL);
  }
  if (ageRange.minAge <= 15 && ageRange.maxAge >= 17) {
    groups.push(AGE_GROUP.UPPER_HIGH_SCHOOL);
  }
  if (ageRange.minAge <= 18 && ageRange.maxAge >= 100) {
    groups.push(AGE_GROUP.ADULT);
  }
  return groups;
};

export const ageRangeTogroupLabels = (ageRange: { minAge: number; maxAge: number }): string[] => {
  const groups = ageRangeTogroups(ageRange);
  const labels = groups.map((group) => t(group));
  return labels;
};

export const ageRangeLabels = (ageRange: { minAge: number; maxAge: number }): string[] => {
  const labels = ageRangeTogroupLabels(ageRange);
  if (labels.length > 1) {
    return [labels[0], labels[labels.length - 1]];
  }
  return labels;
};

export const isAgeGroupInGameAgeRange = (ageRange: { minAge: number; maxAge: number }, ageGroup: any) => {
  if (isValueEmpty(ageRange) || isValueEmpty(ageGroup)) {
    return false;
  }
  const groupRange = ageGroupToRange(ageGroup);
  return groupRange.minAge >= ageRange.minAge && groupRange.maxAge <= ageRange.maxAge;
};

export const calculageAgeRange = (ageRange: { minAge: number; maxAge: number }, ageGroup: string, checked: boolean) => {
  const groupRange = ageGroupToRange(ageGroup);
  if (isValueEmpty(ageRange)) {
    return groupRange;
  }
  const newRagne = { ...ageRange };
  if (checked) {
    if (groupRange.minAge < newRagne.minAge) {
      newRagne.minAge = groupRange.minAge;
    }
    if (groupRange.maxAge > newRagne.maxAge) {
      newRagne.maxAge = groupRange.maxAge;
    }
  } else {
    if (groupRange.minAge <= newRagne.minAge) {
      newRagne.minAge = groupRange.maxAge + 1;
    }
    if (groupRange.maxAge >= newRagne.maxAge) {
      newRagne.maxAge = groupRange.minAge - 1;
    }
  }
  if (newRagne.minAge >= newRagne.maxAge) {
    return null;
  }
  return newRagne;
};

export const playerNameAcronym = (playerName = '') => {
  const tokens = playerName.split(' ');
  let result = '';
  tokens.forEach((token) => {
    if (token && token.length > 0) {
      result += token[0].toUpperCase();
    }
  });
  return result;
};

export const getInitialContentsMap = (): { [key: string]: any[] } => {
  const map: { [key: string]: any[] } = {};
  SquareTypes.forEach((type) => {
    if (!FinalSquareTypes.includes(type)) {
      map[type] = [];
    }
  });
  return map;
};
