import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { AuthDao } from '../../model/dao/AuthDao';

export default function useForgotPwdHook() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(null);
  const [changed, setChanged] = useState(null);

  const sendResetLink = async ({ email }) => {
    try {
      setSent(false);
      setLoading(true);
      setError(null);
      await AuthDao.sendPwdResetLink(email);
      setSent(true);
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Could not generate password reset link right now.', { variant: 'error', autoHideDuration: 4000 });
      setError(error);
      setLoading(false);
    } finally {
    }
  };

  const changePassword = async ({ email, password, token }, redirectTo) => {
    try {
      setChanged(false);
      setLoading(true);
      setError(null);
      await AuthDao.changePwd(email, password, token);
      enqueueSnackbar('Password changed', { variant: 'success', autoHideDuration: 1000 });
      setChanged(true);
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Password change failed. Please try again...', { variant: 'error', autoHideDuration: 4000 });
      setError(error);
      setLoading(false);
    } finally {
    }
  };

  return { loading, error, sent, sendResetLink, changePassword, changed };
}
