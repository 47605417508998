import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import AnswerForm from './AnswerForm';
import InfoContent from './InfoContent';

export default function QuizContent({ content, onQuizAnswered }) {
  const [contentShown, setContentShown] = useState(false);

  if (isEmpty(content)) {
    return <></>;
  }
  return (
    <div>
      <InfoContent content={content.body} onContentShown={(value) => setContentShown(value)} />
      {contentShown && (
        <AnswerForm
          question={content.body}
          onAnswerChange={onQuizAnswered}
          /* onAnswerChange={(value) => {
            setAnswer(value);
          }} */
        />
      )}
    </div>
  );
}
