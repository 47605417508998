import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import React, { useImperativeHandle, useState } from 'react';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import TableConfigurator from '../play/new/TableConfigurator';
import { useDispatch } from 'react-redux';
import { GAME_STATUS } from '@grethics/commons';
import { ArchiveOutlined, Delete } from '@mui/icons-material';
import { GuiService } from '../../../services/GuiService';
import { useUpdateTableHook } from '../../../hooks/play/useUpdateTableHook';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const TableEditorModal = React.forwardRef(({ adminMode }, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [table, setTable] = useState(null);
  const { updating, deleteTable } = useUpdateTableHook();
  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setTable(null);
    setOpen(false);
  };

  const handleArchive = () => {};
  const handleDelete = () => {
    GuiService.showAlert({
      title: t('confirmAction'),
      message: (
        <p className='text-center'>
          {t('confirmDeleteTable')}
          <br /> ({t('anduableAction')})
        </p>
      ),
      actions: [
        {
          title: t('cancel'),
          color: 'primary',
        },
        {
          title: t('delete'),
          color: 'error',
          callback: () => {
            console.log('Table is gone...!');
            deleteTable(table.slug, table.gameId).then(() => {
              handleClose();
            });
          },
        },
      ],
    });
  };

  useImperativeHandle(ref, () => ({
    show(data) {
      setTable(data);
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <Dialog disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
      <DisposableDialogTitle onClose={handleClose} id='customized-dialog-title'>
        {t('editingTable', { slug: table?.slug })}
      </DisposableDialogTitle>
      <DialogContent dividers sx={{}}>
        {adminMode && (
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <LoadingButton loading={updating} loadingposition='start' variant='contained' color='warning' onClick={handleArchive} startIcon={<ArchiveOutlined />} disabled={!!table?.archived}>
              {t('archiveTable')}
            </LoadingButton>

            <LoadingButton loading={updating} loadingposition='start' variant='contained' color='error' onClick={handleDelete} startIcon={<Delete />}>
              {t('deleteTable')}
            </LoadingButton>
          </DialogActions>
        )}
        {!table?.archived && GAME_STATUS.NOT_STARTED === table?.state.gameStatus && (
          <TableConfigurator
            gameId={table?.gameId}
            ageRange={table?.game?.ageRange}
            table={table}
            onComplete={(value) => {
              handleClose();
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
});

export default TableEditorModal;
