import { ApiClient } from './utils/ApiClient';
import { CustomResponseError } from '../../utils/Utils';

export const TableDao = {
  async createNewTable(payload) {
    try {
      const { data } = await ApiClient.post(`/play/tables`, { ...payload });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async joinTable(slug, playerCode) {
    try {
      const { data } = await ApiClient.post(`/play/tables/${slug}/join`, { playerCode });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },

  async updateTable(slug, payload) {
    try {
      const { data } = await ApiClient.patch(`/play/tables/${slug}`, { ...payload });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },

  async resetTable(slug, payload) {
    try {
      const { data } = await ApiClient.get(`/play/tables/${slug}/reset`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },

  async deleteTable(slug) {
    try {
      const { data } = await ApiClient.delete(`/play/tables/${slug}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },

  async archiveTable(slug) {
    try {
      const { data } = await ApiClient.get(`/play/tables/${slug}/archive`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },

  async fetchFullTable(slug) {
    try {
      //console.log('fetching game spec...');
      const { data } = await ApiClient.get(`/play/tables/${slug}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },

  async fetchUserCreatedTables() {
    try {
      const { data } = await ApiClient.get(`/play/tables`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },

  async setPlayersDisconnected(slug) {
    try {
      const { data } = await ApiClient.post(`/play/${slug}/disconnect`, {});
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
};
