import { CustomResponseError } from '../../utils/Utils';
import { ApiClient } from './utils/ApiClient';

export const AuthDao = {
  async fetchCurrentUser() {
    try {
      const { data } = await ApiClient.get(`/auth/me`);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async loginUser(email, password) {
    try {
      const { data } = await ApiClient.post(`/auth/login`, { email, password });
      return data;
    } catch (error) {
      console.log({ error });
      throw error?.response?.data;
    }
  },

  async loginSocialUser(provider, socialToken) {
    try {
      const { data } = await ApiClient.post(`/auth/login/${provider}`, socialToken);
      return data;
    } catch (error) {
      console.log({ error });
      throw error?.response?.data;
    }
  },

  async registerUser(userData) {
    try {
      const { data } = await ApiClient.post(`/auth/register`, userData);
      return data;
    } catch (error) {
      console.log({ error });
      throw error?.response?.data ?? new Error('Could not Siginup');
    }
  },

  async updateMyUserProfile(payload) {
    try {
      const { data } = await ApiClient.patch(`auth/me`, { payload });
      return data;
    } catch (error) {
      console.log({ error });
      throw CustomResponseError(error);
    }
  },
  async upsertMyPlayerProfile(player) {
    try {
      const { data } = await ApiClient.patch(`/auth/me/player`, { player });
      return data;
    } catch (error) {
      console.log(JSON.stringify(error));
      throw CustomResponseError(error);
    }
  },
  async sendPwdResetLink(email) {
    try {
      const { data } = await ApiClient.post(`/auth/password-reset`, { email });
      return data;
    } catch (error) {
      console.log(JSON.stringify(error));
      throw CustomResponseError(error);
    }
  },
  async changePwd(email, password, token) {
    try {
      const { data } = await ApiClient.post(`/auth/password-change`, { email, password, token });
      return data;
    } catch (error) {
      console.log(JSON.stringify(error));
      throw CustomResponseError(error);
    }
  },
};
