import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isValueEmpty } from '@grethics/commons';
import { Button, CircularProgress, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ProtectedRoute({ children, allowedRoles = [] }) {
  const { t } = useTranslation();
  const { user, checked } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  if (user) {
    if (allowedRoles.includes(user?.role) || isValueEmpty(allowedRoles)) {
      return children;
    } else {
      return (
        <Container maxWidth={'md'}>
          <h1 className='text-center p-2 text-xl'>{t('accessDeniedToPage')}</h1>
          <div className='flex flex-1 items-center flex-col w-full'>{t('sorry')}</div>
          <div className='flex flex-1 items-center flex-col w-full pt-2 text-4xl'>💔</div>
          <div className='flex flex-1 items-center flex-col w-full pt-2 text-4xl'>
            <Button
              variant='text'
              onClick={() => {
                navigate('/');
              }}>
              Home
            </Button>
          </div>
        </Container>
      );
    }
  } else if (checked) {
    return <Navigate to={'/auth/signin'} replace state={{ from: location }} />;
  } else {
    return (
      <Container maxWidth={'md'}>
        <div className='flex flex-1 items-center flex-col w-full pt-2 text-4xl'>
          <CircularProgress />
        </div>
      </Container>
    );
  }
}
