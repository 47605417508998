import { AGE_GROUP, isValueEmpty } from '@grethics/commons';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Tooltip } from '@mui/material';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import slugify from 'slugify';
import '../../../../editor.css';
import useGameHook from '../../../../hooks/make/useGameHook';
import { calculageAgeRange, isAgeGroupInGameAgeRange } from '../../../../utils/GameUtils';
import { RTEditorToolbarOptions, capitalizePhrase } from '../../../../utils/Utils';
import GameAvatar from '../../../components/common/GameAvatar';
import IOSSwitch from '../../../components/common/IOSSwitch';
import PageHeader from '../../../components/common/PageHeader';
import { NavigateAwayPrompt } from '../../../components/make/NavigateAwayPrompt';
import MediaLibraryModal from '../../../components/make/MediaLibraryModal';
import GreenGoose from '../../../../assets/img/left-goose.svg';
import RedGoose from '../../../../assets/img/right-goose.svg';
import { useTranslation } from 'react-i18next';
import { HYBRID_ENABLED, LANGS } from '../../../../config/config';

export default function BasicsConfigPage() {
  const { t } = useTranslation();
  const mediaLibRef = useRef();
  const leftMediaLibRef = useRef();
  const rightMediaLibRef = useRef();
  const { checkGameSlug } = useGameHook();
  const { game, updateGame, loading } = useGameHook();
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      title: '',
      lang: 'EN',
      slug: '',
      description: '',
      allowHybrid: false,
      revisitRandomSquares: false,
      leftMediaId: null,
      rightMediaId: null,
      logoMediaId: null,
      ageRange: { minAge: 6, maxAge: 100 },
      rewarding: { title: '', initial: 0, onTrue: 'Plus {{points}}!!!', onFalse: 'Minus {{points}}' },
    },
    values: { ...game },
  });

  const onSubmit = async (data) => {
    await updateGame(game.slug, { ...game, ...data });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col flex-1' autoComplete='off'>
      <PageHeader title={t('gameBasicsCfg')} />
      <NavigateAwayPrompt prevent={isDirty} />
      <Paper className='flex flex-col min-h-[500px] w-full items-start p-2'>
        <div className='flex flex-row w-full gap-4 my-4'>
          <div className='grow'>
            <Controller
              name='title'
              control={control}
              rules={{
                required: t('gameTitleRequired'),
                validate: async (title, id) => {
                  if (id) {
                    return true;
                  }
                  const slug = slugify(title, { trim: true, lower: true });
                  const free = await checkGameSlug(slug);
                  return free || `${title} is reserved...`;
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl fullWidth error={!!error}>
                    <TextField label={t('gameTitle')} {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
          </div>
          <div className='grow'>
            <Controller
              name='lang'
              control={control}
              rules={{ required: t('gameLanguageRequired') }}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <InputLabel>{t('gameLanguage')}</InputLabel>
                  <Select label={t('gameLanguage')} {...field}>
                    {Object.keys(LANGS).map((code) => (
                      <MenuItem key={code} value={code}>
                        {t(`lang${code.toUpperCase()}`)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </div>
        </div>
        <div className='flex flex-row w-full gap-4 my-4'>
          <div className='flex-grow'>
            <Controller
              name='description'
              control={control}
              rules={{}}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('gameDescription')}</InputLabel>
                  <ReactQuill
                    theme='snow'
                    className='border rounded p-3'
                    modules={{ toolbar: RTEditorToolbarOptions }}
                    value={field.value}
                    onChange={(v) => {
                      setValue(field.name, v, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                    }}
                  />
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </div>
          <div className='flex flex-col flex-none'>
            <Controller
              name='logoMediaId'
              control={control}
              rules={{ required: t('gameLogoRequired') }}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[120px] min-w-[120px] items-center'>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('gameLogo')}</InputLabel>
                    <div className='mt-3 top-0 z-0'>
                      <GameAvatar imageId={field.value} height={100} width={100} />
                    </div>
                    <div className='flex flex-row justify-between pt-1 w-[90px] absolute top-1'>
                      <IconButton
                        onClick={() => {
                          mediaLibRef.current?.show(game.id);
                        }}
                        sx={{ borderRadius: 1, py: 1, px: 0 }}
                        color='primary'
                        className='z-10'>
                        <Edit />
                      </IconButton>
                      {field.value && (
                        <IconButton
                          onClick={() => {
                            setValue(field.name, undefined, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                          sx={{ borderRadius: 1, py: 1, px: 0 }}
                          color='error'
                          className='z-10'>
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <FormHelperText>{error?.message}</FormHelperText>
                  <div>
                    <MediaLibraryModal
                      ref={mediaLibRef}
                      mediaId={field.value}
                      mediaTypes={['image']}
                      onSelect={(mediaId) => {
                        setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                        // trigger(field.name);
                      }}
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
        </div>
        <div className='flex flex-col w-full gap-4 my-4'>
          <div className='grow'>
            <Controller
              name='ageRange'
              control={control}
              rules={{ required: t('targetAgeRangesRequired') }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl error={!!error} fullWidth>
                    <div className='p-2 flex flex-row border rounded-md border-gray-300 min-w-[120px] items-center'>
                      <InputLabel shrink={false} sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>
                        {t('targetAgeRanges')}
                      </InputLabel>
                      {Object.values(AGE_GROUP).map((group) => {
                        const selected = isAgeGroupInGameAgeRange(field.value, group);
                        return (
                          <div key={group} className='flex flex-row items-center'>
                            <Checkbox
                              value={group}
                              checked={selected}
                              onChange={(e, checked) => {
                                const newValue = calculageAgeRange(field.value, group, checked);
                                setValue(field.name, newValue, { shouldDirty: true, shouldValidate: true });
                              }}
                            />
                            <span>{t(group)}</span>
                          </div>
                        );
                      })}
                    </div>
                    <FormHelperText error={error}>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-grow'>
              <FormControl>
                <div className='px-2 flex flex-row border rounded-md border-gray-300 py-6 gap-4'>
                  <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('playerAvatars')}</InputLabel>
                  <Controller
                    name='leftMediaId'
                    control={control}
                    rules={{}}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error}>
                        <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[120px] min-w-[150px] items-center'>
                          <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('leftPlayer')}</InputLabel>
                          <div className='mt-3 top-0 z-0'>
                            <GameAvatar imageId={field.value} picture={!field.value && GreenGoose} height={100} width={100} />
                          </div>
                          <div className='flex flex-row justify-between pt-1 w-[90px] absolute top-1'>
                            <IconButton
                              onClick={() => {
                                leftMediaLibRef.current?.show(game.id);
                              }}
                              sx={{ borderRadius: 1, py: 1, px: 0 }}
                              color='primary'
                              className='z-10'>
                              <Edit />
                            </IconButton>
                            {field.value && (
                              <IconButton
                                onClick={() => {
                                  setValue(field.name, undefined, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                                  trigger(field.name);
                                }}
                                sx={{ borderRadius: 1, py: 1, px: 0 }}
                                color='error'
                                className='z-10'>
                                <Delete />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        <FormHelperText>{error?.message}</FormHelperText>
                        <div>
                          <MediaLibraryModal
                            ref={leftMediaLibRef}
                            mediaId={field.value}
                            mediaTypes={['image']}
                            onSelect={(mediaId) => {
                              setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                              // trigger(field.name);
                            }}
                          />
                        </div>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name='rightMediaId'
                    control={control}
                    rules={{}}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error}>
                        <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[120px] min-w-[150px] items-center'>
                          <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>{t('rightPlayer')}</InputLabel>
                          <div className='mt-3 top-0 z-0'>
                            <GameAvatar imageId={field.value} picture={!field.value && RedGoose} height={100} width={100} />
                          </div>
                          <div className='flex flex-row justify-between pt-1 w-[90px] absolute top-1'>
                            <IconButton
                              onClick={() => {
                                rightMediaLibRef.current?.show(game.id);
                              }}
                              sx={{ borderRadius: 1, py: 1, px: 0 }}
                              color='primary'
                              className='z-10'>
                              <Edit />
                            </IconButton>
                            {field.value && (
                              <IconButton
                                onClick={() => {
                                  setValue(field.name, undefined, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                                  trigger(field.name);
                                }}
                                sx={{ borderRadius: 1, py: 1, px: 0 }}
                                color='error'
                                className='z-10'>
                                <Delete />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        <FormHelperText>{error?.message}</FormHelperText>
                        <div>
                          <MediaLibraryModal
                            ref={rightMediaLibRef}
                            mediaId={field.value}
                            mediaTypes={['image']}
                            onSelect={(mediaId) => {
                              setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                              // trigger(field.name);
                            }}
                          />
                        </div>
                      </FormControl>
                    )}
                  />
                </div>
              </FormControl>
            </div>
            <Controller
              name='rewarding'
              control={control}
              rules={{
                validate: async (value) => {
                  const { title, initial, onTrue, onFalse } = value;
                  let error = '';
                  if (isValueEmpty(title)) {
                    error = t('rewardTitleRequired');
                  } else if (isValueEmpty(initial)) {
                    error = t('initialRewardRequired');
                  } else if (isValueEmpty(onTrue)) {
                    error = t('passFeedbackTmplRequired');
                  } else if (isValueEmpty(onFalse)) {
                    error = t('failFeedbackTmplRequired');
                  } else if (onTrue.includes('{{points}}') === false) {
                    error = t('feedbackTmplInvalid');
                  } else if (onFalse.includes('{{points}}') === false) {
                    error = t('feedbackTmplInvalid');
                  }
                  if (error) {
                    return t('rewardingCfgInvalid') + error;
                  }
                  return true;
                },
              }}
              render={({ field, fieldState: { error } }) => {
                const rewarding = field.value;
                return (
                  <FormControl error={!!error} className='w-full'>
                    <div className='px-2 flex flex-col border rounded-md border-gray-300 items-center w-full pt-2'>
                      <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('rewarding')}</InputLabel>
                      <div className='flex flex-row gap-4 py-4 w-full'>
                        <TextField
                          label={t('rewardLabel')}
                          name='title'
                          value={rewarding?.title}
                          type='text'
                          fullWidth
                          onChange={(e) => {
                            setValue(field.name, { ...rewarding, title: e.target.value }, { shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                        />

                        <TextField
                          name='initial'
                          label={t('initialReward')}
                          value={rewarding?.initial}
                          type='number'
                          fullWidth
                          onChange={(e) => {
                            if (e.target.value >= 0) {
                              setValue(field.name, { ...rewarding, initial: e.target.value }, { shouldDirty: true, shouldValidate: true });
                            }
                          }}
                        />
                      </div>
                      <div className='flex flex-row gap-4 py-2 w-full'>
                        <TextField
                          label={t('passFeedbackTmpl')}
                          name='onTrue'
                          value={rewarding?.onTrue}
                          type='text'
                          fullWidth
                          onChange={(e) => {
                            setValue(field.name, { ...rewarding, onTrue: e.target.value }, { shouldDirty: true, shouldValidate: true });
                            trigger(field.name);
                          }}
                        />

                        <TextField
                          name='onFalse'
                          label={t('failFeedbackTmpl')}
                          value={rewarding?.onFalse}
                          type='text'
                          fullWidth
                          onChange={(e) => {
                            setValue(field.name, { ...rewarding, onFalse: e.target.value }, { shouldDirty: true, shouldValidate: true });
                          }}
                        />
                      </div>
                      <FormHelperText>{error?.message}</FormHelperText>
                    </div>
                  </FormControl>
                );
              }}
            />
          </div>
          <div className='flex flex-grow'>
            <Controller
              name='revisitRandomSquares'
              control={control}
              render={({ field, fieldState: { error } }) => {
                const obligatory = field.value;
                return (
                  <FormControl fullWidth error={!!error} sx={{ ml: 2 }}>
                    <div className='px-2 flex flex-col border rounded-md border-gray-300 items-center h-full'>
                      <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('allowRevisitingOfSquares')}</InputLabel>
                      <Tooltip title={t('allowRevisitingOfSquaresTooltip')}>
                        <div className='flex flex-row w-full'>
                          <FormControl fullWidth>
                            <div className='flex flex-col items-center m-2 mb-1'>
                              <FormControlLabel
                                style={{ padding: 3, width: '100%' }}
                                control={
                                  <IOSSwitch
                                    className={'w-full'}
                                    checked={!!obligatory}
                                    onChange={(e) => {
                                      setValue(field.name, e.target.checked, { shouldDirty: true, shouldValidate: true });
                                      trigger(field.name);
                                    }}
                                  />
                                }
                                label={<span className='ml-1 text-gray-500'>{obligatory ? t('yes') : t('no')}</span>}
                              />
                            </div>
                          </FormControl>
                        </div>
                      </Tooltip>
                    </div>
                  </FormControl>
                );
              }}
            />
            {HYBRID_ENABLED && (
              <Controller
                name='allowHybrid'
                control={control}
                render={({ field, fieldState: { error } }) => {
                  const obligatory = field.value;
                  return (
                    <FormControl fullWidth error={!!error} sx={{ ml: 2 }}>
                      <div className='px-2 flex flex-col border rounded-md border-gray-300 items-center h-full'>
                        <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('allowHybrid')}</InputLabel>
                        <Tooltip title={t('allowHybridTooltip')}>
                          <div className='flex flex-row w-full'>
                            <FormControl fullWidth>
                              <div className='flex flex-col items-center m-2 mb-1'>
                                <FormControlLabel
                                  style={{ padding: 3, width: '100%' }}
                                  control={
                                    <IOSSwitch
                                      className={'w-full'}
                                      checked={!!obligatory}
                                      onChange={(e) => {
                                        setValue(field.name, e.target.checked, { shouldDirty: true, shouldValidate: true });
                                        trigger(field.name);
                                      }}
                                    />
                                  }
                                  label={<span className='ml-1 text-gray-500'>{obligatory ? t('yes') : t('no')}</span>}
                                />
                              </div>
                            </FormControl>
                          </div>
                        </Tooltip>
                      </div>
                    </FormControl>
                  );
                }}
              />
            )}
          </div>
        </div>
      </Paper>
      <Paper className='flex flex-row flex-1 mt-1 p-2 items-center justify-between'>
        <div>
          {!isValueEmpty(dirtyFields) && (
            <Button
              startIcon={<Close />}
              variant='outlined'
              color='error'
              disabled={loading}
              onClick={() => {
                reset();
              }}>
              {t('cancel')}
            </Button>
          )}
        </div>
        <Button startIcon={<Check />} variant='outlined' color='primary' type='submit' disabled={isValueEmpty(dirtyFields) || loading}>
          {t('save')}
        </Button>
      </Paper>
    </form>
  );
}
