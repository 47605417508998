import { isValueEmpty } from '@grethics/commons';
import { CheckCircle, ChecklistOutlined, ContentCopy } from '@mui/icons-material';
import { Alert, IconButton, LinearProgress, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BASE_URL, HYBRID_ENABLED } from '../../../../config/config';
import { useCreateTableHook } from '../../../../hooks/play/useCreateTableHook';
import { useUpdateTableHook } from '../../../../hooks/play/useUpdateTableHook';
import { canUserJoinTable } from '../../../../utils/GameUtils';
import { isIntanceCfgValid } from '../../../../utils/Utils';
import useScreenSize from '../../../../utils/useScreenSize';
import PlayerConfigurator from './PlayerConfigurator';
import TableSettingsForm from './TableSettingsForm';
import { useTranslation } from 'react-i18next';
import { is } from 'date-fns/locale';

export default function TableConfigurator({ gameId, ageRange = { minAge: 6, maxAge: 100 }, table, onComplete }) {
  const { t } = useTranslation();
  const steps = [t('firstPlayer'), t('secondPlayer'), t('settings'), t('save')];
  const [activeStep, setActiveStep] = React.useState(0);
  const { range } = useScreenSize();
  const authUser = useSelector((state) => state.auth.user);
  const [opponents, setOpponents] = React.useState(table?.opponents ?? { left: null, right: null });
  const [moderator, setModerator] = React.useState(table?.moderator);
  const [numOfDices, setNumOfDices] = React.useState(table?.numOfDices ?? 1);
  const [availableAt, setAvailableAt] = React.useState(table?.availableAt ? new Date(table.availableAt) : null);
  const { instance, creating, createError, createNewTable } = useCreateTableHook();
  const { updated, updating, updateTable } = useUpdateTableHook();
  const [isHybrid, setIsHybrid] = React.useState(!!table?.isHybrid);
  const game = useSelector((state) => state.play.list.find((g) => g.id === gameId));
  const allowHybrid = HYBRID_ENABLED && game?.allowHybrid;

  React.useEffect(() => {
    if (updated) {
      onComplete(updated);
    }
  }, [updated, onComplete]);

  React.useEffect(() => {
    if (isValueEmpty(table)) {
      return;
      ///setDirty(true);
    }
    if (opponents !== table?.opponents) {
      console.log('opponents dirty');
      //setDirty(true);
    }
    const initDate = new Date(table?.availableAt);
    const curDate = new Date(availableAt);
    if (curDate.toISOString() !== initDate.toISOString()) {
      console.log('availability dirty');
      console.log(`${initDate.toISOString()} ${t('vs')} ${curDate.toISOString()}`);
      //setDirty(true);
    }
    if (moderator !== table.moderator) {
      console.log('moderator dirty');
      //setDirty(true);
    }
  }, [opponents, availableAt, moderator, table]);

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const isCfgValid = () => {
    if (activeStep === 1) {
      if (!opponents.left || !opponents.right) {
        return false;
      }
    } else if (activeStep === 2) {
      return isIntanceCfgValid({ opponents, moderator, availableAt });
    }
    return true;
  };

  const canCreatorPlayNow = () => {
    return canUserJoinTable(authUser, availableAt, opponents, moderator);
  };

  const handleNext = async () => {
    if (!isLastStep()) {
      const newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
    } else {
      console.log('Create new Game Instance based on this CFG:', { opponents, moderator, availableAt });
      if (table) {
        updateTable({ slug: table.slug, gameId, numOfDices, opponents, moderator, availableAt, isHybrid });
      } else {
        createNewTable({ gameId, numOfDices, opponents, moderator, availableAt, isHybrid });
      }
    }
  };

  const handleBack = () => {
    if (!instance) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStep = (step) => () => {
    if (!instance) {
      setActiveStep(step);
    }
  };

  return (
    <Paper sx={{ width: '100%', mt: 1, pb: 0 }}>
      {['xl', 'md', 'lg'].includes(range) && !instance && (
        <Stepper activeStep={activeStep} className=' rounded-t p-1 py-2' sx={{ minHeight: 50, backgroundColor: 'primary.light' }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color='inherit' onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      )}
      {['xs', 'sm'].includes(range) && !instance && (
        <Stepper activeStep={activeStep} className=' rounded-t p-1' orientation='vertical' sx={{ backgroundColor: 'primary.light' }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color='inherit' onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      )}

      <Box sx={{ width: '100%', pt: 2 }}>
        <Box sx={{ borderTop: 0, borderBottom: 0.5, mt: 2, borderColor: 'divider' }}>
          {activeStep === 0 && (
            <PlayerConfigurator
              side={'left'}
              selected={opponents}
              ageRange={ageRange}
              onComplete={(player) => {
                setOpponents({ ...opponents, left: player });
              }}
            />
          )}
          {activeStep === 1 && (
            <PlayerConfigurator
              side={'right'}
              selected={opponents}
              ageRange={ageRange}
              onComplete={(player) => {
                setOpponents({ ...opponents, right: player });
              }}
            />
          )}
          {activeStep === 2 && (
            <div>
              <TableSettingsForm
                opponents={opponents}
                moderator={moderator}
                numOfDices={numOfDices}
                isHybrid={isHybrid}
                allowHybrid={allowHybrid}
                onHybridChange={(h) => {
                  setIsHybrid(h);
                }}
                onDicesChange={(d) => {
                  setNumOfDices(d);
                }}
                availableAt={availableAt}
                onModeratorChange={(pm) => {
                  setModerator(pm);
                  //setDirty(true);
                }}
                onAvailabilityChange={(av) => {
                  setAvailableAt(av);
                  //setDirty(true);
                }}
              />
            </div>
          )}
          {activeStep === 3 && (
            <div sx={{ mb: 2, p: 2 }}>
              <div className='flex flex-col flex-1 items-center'>
                {creating || (updating && <LinearProgress className='w-full h-10' />)}
                {createError && (
                  <Alert variant='outlined' severity='error' className='rounded w-full'>
                    {createError ?? 'Something went wrong...'}
                  </Alert>
                )}
                {!creating && !updating && !instance && !createError && (
                  <>
                    <ChecklistOutlined sx={{ width: 250, height: 250, color: 'success.main', border: 3, borderRadius: 150, padding: 5 }} />
                    <div className='text-xl'>{t('youAreAllSet')}</div>
                    <div className='text-xl mt-3'>{table ? t('updateTablePrompt') : t('createTablePrompt')}</div>
                  </>
                )}
                {instance && (
                  <div className='flex flex-col items-center w-full'>
                    <CheckCircle sx={{ width: 250, height: 250, color: 'success.main', border: 3, borderRadius: 150, padding: 5 }} />
                    <div className='text-xl'>{t('tableCreated')}</div>
                    <div className='text-xl my-3 flex flex-col items-center'>
                      <div>{t('tableCode')}:</div>
                      <div className='flex flex-row items-center font-bold'>
                        {instance?.slug}
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(instance?.slug);
                            enqueueSnackbar({ variant: 'info', message: t('tableCodeCopied'), preventDuplicate: true });
                          }}>
                          <ContentCopy />
                        </IconButton>
                      </div>
                    </div>
                    <div className='text-xl my-3 flex flex-col items-center'>
                      <div>{t('tableLink')}</div>
                      <div className='flex flex-row items-center font-bold'>
                        {BASE_URL + '/play/' + instance?.slug}
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(`${BASE_URL}/play/${instance?.slug}`);
                            enqueueSnackbar({ variant: 'info', message: t('tableLinkCopied'), preventDuplicate: true });
                          }}>
                          <ContentCopy />
                        </IconButton>
                      </div>
                      {canCreatorPlayNow() && (
                        <Link className='text-blue-500 cursor-pointer my-3' to={`/play/${instance?.slug}`}>
                          {t('playNow')}
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Box>
      </Box>
      {!instance && (
        <Box sx={{ display: 'flex', flexDirection: 'row', py: 0, minHeight: 50, bgcolor: 'primary.extraLight' }} className='rounded-b'>
          <>
            <Button color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1, textTransform: 'none' }}>
              {t('back')}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button disabled={!isCfgValid()} onClick={handleNext} sx={{ m: 1, textTransform: 'none' }} variant={isLastStep() ? 'contained' : 'text'}>
              {isLastStep() ? (table ? t('update') : t('create')) : t('next')}
            </Button>
          </>
        </Box>
      )}
    </Paper>
  );
}
