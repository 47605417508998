import { GAME_STATUS, isValueEmpty } from '@grethics/commons';
import { ArchiveOutlined, Cached, ContentCopy, Delete, DeleteOutline, Edit, Login, RecyclingRounded, ResetTvOutlined, RestartAltOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { format, formatRelative } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../config/config';
import { useUpdateTableHook } from '../../../hooks/play/useUpdateTableHook';
import { GuiService } from '../../../services/GuiService';
import { canUserJoinTable, createOpponentRecord } from '../../../utils/GameUtils';
import { TableItemFallback } from '../common/ErrorFallbacks';
import TableEditorModal from './TableEditorModal';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import i18next from 'i18next';
import { getCurrentLocale } from '../../../utils/Utils';

const getStatusInfo = (table) => {
  const { gamePlayers, state } = table;
  const gameStatus = state?.gameStatus;
  let label;
  switch (gameStatus) {
    case GAME_STATUS.STARTED:
      label = t('started');
      break;
    case GAME_STATUS.COMPLETED:
      if (table.state?.winner) {
        label = t('completedWinner', { name: gamePlayers.find((gp) => gp.side === table?.state?.winner)?.player?.name });
      } else {
        label = t('completedDraw');
      }
      break;
    case GAME_STATUS.STARTING_UP:
      label = t('startingUp');
      break;
    default:
      label = t('notStarted');
      break;
  }
  return label;
};
const getModerationInfo = (moderator) => {
  if (!moderator) {
    return null;
  }
  return <span>({t('moderatedBy', { name: moderator.name })})</span>;
};

export default function TableItem({ authUser, table }) {
  const { t } = useTranslation();
  const tblEditorRef = useRef();
  const { game, gamePlayers, state, moderator } = table;

  const tableOpponents = { left: createOpponentRecord(table?.gamePlayers[0]), right: createOpponentRecord(table?.gamePlayers[1]) };

  const canUserEditTable = (isValueEmpty(state) || [GAME_STATUS.NOT_STARTED].includes(state.gameStatus)) && table.creatorId === authUser.id;
  const canUserArchiveTable = [GAME_STATUS.COMPLETED].includes(state?.gameStatus) && table.creatorId === authUser.id;
  const canUserDeleteTable = (isValueEmpty(state) || ![GAME_STATUS.STARTING_UP].includes(state.gameStatus)) && table.creatorId === authUser.id;
  const canUserResetTable = [GAME_STATUS.STARTING_UP, GAME_STATUS.STARTED].includes(state.gameStatus) && table.creatorId === authUser.id;

  const navigate = useNavigate();
  const { updating, deleteTable, archiveTable, resetTable } = useUpdateTableHook();

  const handleEdit = () => {
    const data = { slug: table.slug, moderator: table.moderator, gameId: table.gameId, opponents: tableOpponents, availableAt: table.availableAt, isHybrid: table.isHybrid, state: table.state };
    if (tblEditorRef.current) {
      tblEditorRef.current.show(data);
    }
  };
  const handleDelete = () => {
    GuiService.showAlert({
      title: t('confirmAction'),
      message: (
        <p className='text-center'>
          {t('confirmDeleteTable')}
          <br /> ({t('undoableAction')})
        </p>
      ),
      actions: [
        {
          title: t('cancel'),
          color: 'primary',
        },
        {
          title: t('delete'),
          color: 'error',
          callback: () => {
            console.log('Table is gone...!');
            deleteTable(table.slug, table.gameId);
          },
        },
      ],
    });
  };

  const handleReset = () => {
    GuiService.showAlert({
      title: t('confirmAction'),
      message: (
        <p className='text-center'>
          {t('confirmResetTable')}
          <br /> ({t('undoableAction')})
        </p>
      ),
      actions: [
        {
          title: t('cancel'),
          color: 'primary',
        },
        {
          title: t('reset'),
          color: 'error',
          callback: () => {
            resetTable(table.slug, table.gameId);
          },
        },
      ],
    });
  };

  const handleArchive = () => {
    archiveTable(table.slug, table.gameId);
  };

  return (
    <Box sx={{ padding: 1, mb: 2, border: 0.5, borderColor: 'lightgray', borderRadius: 1 }}>
      <ErrorBoundary fallback={<TableItemFallback />}>
        <TableEditorModal ref={tblEditorRef} />
        <div className='flex flex-1 flex-row items-center'>
          <span className='font-bold pr-1'>{gamePlayers[0]?.player?.name}</span>
          {t('vs')}
          <span className='font-bold px-1'>{gamePlayers[1]?.player?.name}</span>
          {getModerationInfo(moderator)}
        </div>
        <div>
          <Typography variant='body1'>
            {t('createdAt', { date: format(new Date(table.createdAt), 'yyyy/MM/dd') })}, {t('joinableFrom', { date: formatRelative(new Date(table.availableAt), new Date(), { locale: getCurrentLocale(i18next.language) }) })},<span className='font-bold'> {getStatusInfo(table)}</span>
          </Typography>
          {state?.gameStatus !== GAME_STATUS.COMPLETED && (
            <div className='flex flex-row items-center text-reg text-gray-500'>
              {t('copyCode')}{' '}
              <IconButton
                color='primary'
                onClick={() => {
                  navigator.clipboard.writeText(table?.slug);
                  enqueueSnackbar({ variant: 'info', message: t('tableCodeCopied'), preventDuplicate: true });
                }}>
                <ContentCopy sx={{ fontSize: 18 }} />
              </IconButton>
              {t('copyLink')}{' '}
              <IconButton
                color='primary'
                onClick={() => {
                  navigator.clipboard.writeText(`${BASE_URL}/play/${table?.slug}`);
                  enqueueSnackbar({ variant: 'info', message: t('tableLinkCopied'), preventDuplicate: true });
                }}>
                <ContentCopy sx={{ fontSize: 18 }} />
              </IconButton>
            </div>
          )}
        </div>
        <Stack direction={'row-reverse'} spacing={1} sx={{ minHeight: 50 }} className='border-t border-gray-200 px-2 pt-2'>
          {canUserJoinTable(authUser, table.availableAt, tableOpponents, table.moderator, state?.gameStatus) && (
            <IconButton
              color='success'
              title={t('join')}
              sx={{ border: 1, borderColor: 'success' }}
              onClick={() => {
                navigate('/play/' + table.slug, { state: { gameId: table.gameId, gameTitle: game.title } });
              }}>
              <Login />
            </IconButton>
          )}
          {!updating && canUserEditTable && (
            <IconButton title={t('edit')} color='primary' sx={{ border: 1, borderColor: 'primary' }} onClick={handleEdit}>
              <Edit />
            </IconButton>
          )}
          {!updating && canUserResetTable && (
            <IconButton title={t('reset')} color='warning' sx={{ border: 1 }} onClick={handleReset}>
              <Cached />
            </IconButton>
          )}
          {!updating && canUserArchiveTable && (
            <IconButton title={t('archive')} color='warning' sx={{ border: 1 }} onClick={handleArchive}>
              <ArchiveOutlined />
            </IconButton>
          )}
          {!updating && canUserDeleteTable && (
            <IconButton title={t('delete')} color='error' sx={{ border: 1 }} onClick={handleDelete}>
              <DeleteOutline />
            </IconButton>
          )}
          {updating && <LoadingButton loading={!!updating} color='error' onClick={handleDelete}></LoadingButton>}
        </Stack>
      </ErrorBoundary>
    </Box>
  );
}
