import React from 'react';
import PlayerAvatar from '../play/PlayerAvatar';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export default function RankingItem({ item }) {
  const { t } = useTranslation();
  const { imageId, gameTitle, when, name, country, city } = item;
  return (
    <div className='flex flex-row items-center w-full gap-4 m-2'>
      <PlayerAvatar imageId={imageId} width={40} height={40} />
      <div className='flex w-full flex-row justify-between'>
        <div className='flex flex-col items-start'>
          <Typography variant='h6' sx={{ color: 'primary.main', fontSize: 16 }}>
            {name} ({city}, {country})
          </Typography>
          <Typography variant='caption' sx={{ color: 'GrayText', lineHeight: 1.5 }}>
            {t('rankingItemInfo', { date: format(new Date(when), 'dd/MM/yyyy'), gameTitle: gameTitle })}
          </Typography>
        </div>
      </div>
    </div>
  );
}
