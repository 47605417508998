import React, { useEffect } from 'react';
import { Box, Grid, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RegistrationForm from '../../components/auth/RegistrationForm';
import { useTranslation } from 'react-i18next';

export default function SignUpPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.user);
  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);
  return (
    <div className='h-full max-w-md mx-auto'>
      <RegistrationForm />
      <Grid container>
        <Grid item xs></Grid>
        <Grid item>
          <Link href='./signin'>{t('alreadyHaveAnAccount')}</Link>
        </Grid>
      </Grid>
    </div>
  );
}
