export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
export const JWT_SECRET = process.env.REACT_APP_JWT_SECRET;
export const FONT_FAMILY = process.env.REACT_APP_FONT_FAMILY;
export const HYBRID_ENABLED = !!process.env.REACT_APP_HYBRID_ENABLED;
export const DEFAULT_PLAYER_STATE = {
  curSquare: 0,
};
export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const LANGS = {
  en: { code: 'en', unicodeFlag: '🇺🇸' },
  el: { code: 'el', unicodeFlag: '🇬🇷' },
  it: { code: 'it', unicodeFlag: '🇮🇹' },
  es: { code: 'es', unicodeFlag: '🇪🇸' },
  pt: { code: 'pt', unicodeFlag: '🇵🇹' },
  pl: { code: 'pl', unicodeFlag: '🇵🇱' },
  sr: { code: 'sr', unicodeFlag: '🇷🇸' },
  hu: { code: 'hu', unicodeFlag: '🇭🇺' },
  sw: { code: 'sw', unicodeFlag: '🇸🇪' },
  nl: { code: 'nl', unicodeFlag: '🇳🇱' },
  de: { code: 'de', unicodeFlag: '🇩🇪' },
  bg: { code: 'bg', unicodeFlag: '🇧🇬' },
  lt: { code: 'lt', unicodeFlag: '🇱🇹' },
};
