import { CloseOutlined } from '@mui/icons-material';
import { DialogTitle, IconButton } from '@mui/material';
import React from 'react';

export default function DisposableDialogTitle({ type, onClose, children, ...other }) {
  return (
    <DialogTitle variant={type} sx={{ m: 0, p: 2, bgcolor: `${type?? 'primary'}.main`, color: 'white' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
