import { Button, Grid, Paper, Skeleton, Typography } from '@mui/material';
import React, { useRef } from 'react';
import useAuthoredGames from '../../../hooks/make/useFetchAuthoredGames';
import GameItem from '../../components/browse/GameItem';
import { useNavigate } from 'react-router-dom';
import NewGameModal from '../../components/make/NewGameModal';
import { useTranslation } from 'react-i18next';

export default function GameBrowserPage() {
  const { t } = useTranslation();
  const newGameModalRef = useRef();
  const { loading, gameList } = useAuthoredGames();
  const navigate = useNavigate();

  const onNewGame = () => {
    newGameModalRef.current.show();
  };

  return (
    <div className='flex flex-col items-center max-w-[1600px] mx-auto'>
      <NewGameModal
        ref={newGameModalRef}
        onSave={({ slug }) => {
          navigate(`/make/games/${slug}/config/basics`);
        }}
      />
      <Typography variant='h4' className='w-full'>
        {t('makeGames')}
      </Typography>
      <Paper className='flex flex-col w-full items-center mt-2 p-2'>
        {loading && (
          <div className='flex flex-1 w-full flex-col'>
            <Skeleton variant='rectangular' sx={{ minHeight: 150, width: '100%', my: 3 }} />
            <Skeleton variant='rectangular' sx={{ minHeight: 150, width: '100%', my: 3 }} />
            <Skeleton variant='rectangular' sx={{ minHeight: 150, width: '100%', my: 3 }} />
          </div>
        )}
        {!loading && gameList && gameList.length === 0 && (
          <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item md={12} lg={12}>
              <div className='text-2xl text-center w-full'>{t('noGamesCreated')}</div>
            </Grid>
            <Grid item container marginTop={2} justifyContent={'center'}>
              <Button variant='contained' size='large'>
                {t('createFirstGame')}
              </Button>
            </Grid>
          </Grid>
        )}
        {!loading && gameList && gameList.length > 0 && (
          <Grid container spacing={1}>
            <Grid item container marginTop={2} justifyContent={'flex-end'}>
              <Button color='primary' variant='contained' size='large' onClick={onNewGame}>
                {t('newGame')}
              </Button>
            </Grid>
            {gameList.map((game) => {
              return <GameItem key={game.id} game={game}></GameItem>;
            })}
          </Grid>
        )}
      </Paper>
    </div>
  );
}
