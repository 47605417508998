import React from 'react';
import PlayerAvatar from '../PlayerAvatar';
import { useTranslation } from 'react-i18next';

const getPlayerInfo = (record, authUser, t) => {
  if (record.user && authUser && record.user.id === authUser.id) {
    return <span>Me, {record.name}</span>;
  }

  return (
    <span>
      {record.name}
      {record.user ? '- ' + t('registered') : '- ' + t('unregistered')}
    </span>
  );
};

export default function PlayerInfo({ record, withAvatar, direction = 'row', avatarW, avatarH, authUser }) {
  const { t } = useTranslation();
  return (
    <div className={`flex p-1 items-center flex-${direction}`}>
      {withAvatar && <PlayerAvatar imageId={record?.player?.imageId ?? record?.user?.imageId} picture={record?.player?.picture} width={avatarW} height={avatarH} />}
      <div className='flex flex-1 ml-1'>{getPlayerInfo(record, authUser, t)}</div>
    </div>
  );
}
