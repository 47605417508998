import { AGE_GROUP, isValueEmpty } from '@grethics/commons';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isAgeGroupInGameAgeRange } from '../../../utils/GameUtils';
import AgeFilter from '../../components/browse/AgeFilter';
import LangFilter from '../../components/browse/LangFilter';
import PlayListItem from '../../components/browse/PlayListItem';
import { useTranslation } from 'react-i18next';

export default function PlayListBrowser() {
  const [allLangs, setAllLangs] = useState([]);
  const [checkedLangs, setCheckedLangs] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const { t } = useTranslation();
  const playList = useSelector((state) => state.play?.list);
  const [displayList, setDisplayList] = useState([]);

  useEffect(() => {
    if (!isEmpty(playList)) {
      const gLangs = playList.map((game) => game.lang);
      setAllLangs([...new Set(gLangs)]);
    }
  }, [playList]);

  useEffect(() => {
    if (playList) {
      setDisplayList(
        playList.filter((game) => {
          const inLang = isEmpty(checkedLangs) || checkedLangs.includes(game.lang);
          const inRange = isValueEmpty(checkedGroups) || checkedGroups.some((group) => isAgeGroupInGameAgeRange(game.ageRange, group));
          return inLang && inRange;
        })
      );
    }
  }, [checkedLangs, checkedGroups, playList]);

  return (
    <Grid container spacing={1}>
      {playList && playList.length > 0 && (
        <Grid item container xs={12} md={3} justifyContent={'start'} alignSelf={'flex-start'} marginTop={2}>
          <Typography mb={1} variant='h6'>
            {t('filterGames')}
          </Typography>
          <LangFilter allLangs={allLangs} onLangChange={(value) => setCheckedLangs(value)} />
          <AgeFilter allAgeGroups={Object.keys(AGE_GROUP)} onRangeChange={(value) => setCheckedGroups(value)} />
        </Grid>
      )}
      <Grid item container xs={12} md={9} justifyContent={'flex-start'} marginTop={2}>
        {displayList.length === 0 && (
          <Grid container spacing={1}>
            <Grid item md={12} lg={12}>
              <div className='text-2xl text-center w-full'>{t('noGames')}</div>
              <div className='text-2xl text-center w-full'>💔</div>
            </Grid>
          </Grid>
        )}
        {displayList.length > 0 && (
          <Grid container spacing={1}>
            {displayList.map((game) => (
              <PlayListItem key={game.id} game={game}></PlayListItem>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
