import { QuestionType } from '@grethics/commons';
import { Checkbox, Divider, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { ActiveGameController } from '../../../control/play/ActiveGameController';

export default function AnswerForm({ question, onAnswerChange }) {
  const { t } = useTranslation();
  const answer = useSelector((state) => state.play.current.table.lastAnswer?.answer);
  const isActing = ActiveGameController.isPrimaryActor();
  const onChange = (value) => {
    onAnswerChange(value);
  };

  return (
    <div className='w-full mt-3'>
      <Divider />
      {question.qType === QuestionType.OPEN && (
        <div className='w-full flex flex-1 flex-row items-center  p-2'>
          <div className='mr-2'>{t('answer')}:</div>
          <TextField
            margin='normal'
            fullWidth
            required
            id='qAnswer'
            name='qAnswer'
            autoFocus
            disabled={!isActing}
            value={answer ?? ''}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>
      )}

      {question.qType !== QuestionType.OPEN && (
        <div className='w-full flex flex-1 flex-col items-start py-2'>
          {question?.options?.length &&
            question?.options.map((option) => {
              const isChecked = Array.isArray(answer) ? answer.includes(option.code) : false;
              return (
                <div key={option.code} className={`w-full border rounded-md my-1 flex flex-row flex-1 items-center justify-start ${isChecked ? ' bg-slate-50 font-medium shadow-sm' : ' shadow-md'}`}>
                  <Checkbox
                    size='medium'
                    key={option.code + '_chck'}
                    checked={isChecked}
                    disabled={!isActing}
                    onChange={() => {
                      let updatedAnswer;
                      if (question.qType === QuestionType.MCMS) {
                        if ((answer ?? []).includes(option.code)) {
                          updatedAnswer = answer.filter((selOpt) => selOpt !== option.code);
                        } else {
                          updatedAnswer = (answer ?? []).concat(option.code);
                        }
                      } else if (question.qType === QuestionType.MCSS) {
                        updatedAnswer = [option.code];
                      }
                      onChange(updatedAnswer);
                    }}
                  />
                  <div>{option.description}</div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
