import { Add, Check, Close, DeleteOutline } from '@mui/icons-material';
import { Button, FormControl, FormHelperText, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import '../../../../editor.css';
import { isValueEmpty } from '@grethics/commons';

import useGameHook from '../../../../hooks/make/useGameHook';
import AuthorFinder from '../../../components/make/AuthorFinder';
import PlayerAvatar from '../../../components/play/PlayerAvatar';
import { NavigateAwayPrompt } from '../../../components/make/NavigateAwayPrompt';
import PageHeader from '../../../components/common/PageHeader';

export default function AccessConfigPage() {
  const { t } = useTranslation();
  const { slug } = useParams();
  const authorFinderRef = useRef();
  const { updateGameAuthors } = useGameHook();
  const { game, loading } = useSelector((state) => state.make.current ?? {});

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm({ mode: 'all', defaultValues: { size: 10, bgMediaId: null }, values: { ...game } });

  const onSubmit = async (data) => {
    await updateGameAuthors(slug, data.coAuthors);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col flex-1' autoComplete='off'>
      <PageHeader title={t('gameAccessCfg')} />
      <NavigateAwayPrompt prevent={isDirty} />
      <AuthorFinder ref={authorFinderRef} />
      <Paper className='flex flex-col min-h-[500px] w-full items-start flex-wrap p-2'>
        <div className='flex lg:flex-row md:flex-col w-full gap-4 mt-4'>
          <div className='flex flex-col flex-1 items-center'>
            <Controller
              name='coAuthors'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <List className='border border-slate-300 rounded mx-auto' sx={{ px: 1 }}>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('gameCoAuthors')}</InputLabel>
                    {(field.value ?? []).map((author, idx) => {
                      if (author.toDelete) {
                        return null;
                      }
                      return (
                        <ListItem
                          className='shadow-sm'
                          key={author.userId}
                          primaryAction={
                            <>
                              <IconButton
                                edge='end'
                                aria-label='remove'
                                title={t('remove')}
                                onClick={() => {
                                  setValue(
                                    field.name,
                                    field.value.filter((ca) => ca.userId !== author.userId),
                                    { shouldDirty: true, shouldTouch: true, shouldValidate: true }
                                  );
                                }}>
                                <DeleteOutline color={'error'} />
                              </IconButton>
                            </>
                          }>
                          <ListItemAvatar>
                            <PlayerAvatar imageId={author.user.imageId} />
                          </ListItemAvatar>
                          <ListItemText primary={author.user.name} />
                        </ListItem>
                      );
                    })}
                    <div className='w-full flex flex-row items-center justify-end p-1 mt-2'>
                      {isValueEmpty(field.value) && <div className='w-full flex'>{t('noCoAuthors')}</div>}
                      <IconButton
                        color='success'
                        title={t('addCoAuthor')}
                        sx={{ border: 1 }}
                        onClick={() => {
                          authorFinderRef.current?.show((data) => {
                            setValue(field.name, [...field.value, { userId: data.id, gameId: game.id, user: data }], { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                          }, game.coAuthors);
                        }}>
                        <Add />
                      </IconButton>
                    </div>
                  </List>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </div>
        </div>
        <div className='flex flex-row w-full gap-4 my-4'></div>
      </Paper>
      <Paper className='flex flex-row flex-1 mt-1 p-2 items-center justify-between'>
        <div>
          {!isValueEmpty(dirtyFields) && (
            <Button
              startIcon={<Close />}
              variant='outlined'
              color='error'
              disabled={loading}
              onClick={() => {
                reset();
              }}>
              {t('cancel')}
            </Button>
          )}
        </div>
        <Button startIcon={<Check />} variant='outlined' color='primary' type='submit' disabled={isValueEmpty(dirtyFields) || loading}>
          {t('save')}
        </Button>
      </Paper>
    </form>
  );
}
