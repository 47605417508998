import React, { useEffect, useState } from 'react';
import { Validators } from '@grethics/commons';
import { Box, Button, TextField, Typography, Alert, Avatar, IconButton, useForkRef, FormControl, FormHelperText, Autocomplete, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import useSignUpHook from '../../../hooks/auth/useSignUpHook';
import { AccountCircleOutlined, EditOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import AvatarEditor from '../common/AvatarEditor';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import PlayerAvatar from '../play/PlayerAvatar';
import { useTranslation } from 'react-i18next';

export default function RegistrationForm({ redirectTo }) {
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: { email: '', password: '', password2: '', name: '', country: '', city: '', picture: null },
  });
  const { t } = useTranslation();
  const [onAvatarEdit, setAvatarEdit] = useState(false);
  const { signingUp, signUp } = useSignUpHook();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const country = watch('country');
  const password = watch('password');

  const signUpClbk = async (data) => {
    signUp(data, redirectTo);
  };

  useEffect(() => {
    fetch('https://countriesnow.space/api/v0.1/countries/flag/unicode')
      .then((response) => {
        response.json().then((rsp) => {
          setCountries(rsp?.data ?? []);
        });
      })
      .catch((error) => console.log('could not get Countries'));
  }, []);

  useEffect(() => {
    setCities([]);
    if (country) {
      axios
        .post('https://countriesnow.space/api/v0.1/countries/cities', { country })
        .then((response) => {
          setCities(response?.data?.data ?? []);
        })
        .catch((error) => console.log('could not get cities'));
    }
  }, [country]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <AccountCircleOutlined />
      </Avatar>
      <Typography component='h1' variant='h5'>
        {t('signUp')}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Controller
          name='email'
          control={control}
          rules={{
            required: t('emailRequired'),
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('emailRequired'),
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl sx={{ my: 2 }} fullWidth error={!!error}>
                <TextField label='Email' {...field} type='email' />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <Controller
          name='password'
          control={control}
          rules={{
            required: t('passwordRequired'),
            minLength: { value: 6, message: t('passwordTooShort') },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl sx={{ mb: 2 }} fullWidth variant='outlined'>
                <InputLabel htmlFor='password'>{t('password')}</InputLabel>
                <OutlinedInput
                  fullWidth
                  id='password'
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t('password')}
                />
                <FormHelperText error={error}>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <Controller
          name='password2'
          control={control}
          rules={{
            required: t('confirmPasswordRequired'),
            minLength: { value: 6, message: t('passwordTooShort') },
            validate: (value) => {
              return value === password || t('passwordsDontMatch');
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl sx={{ mb: 2 }} fullWidth variant='outlined'>
                <InputLabel htmlFor='password2'>{t('confirmPassword')}</InputLabel>
                <OutlinedInput
                  fullWidth
                  id='password2'
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t('confirmPassword')}
                />
                <FormHelperText error={error}>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <Controller
          name='name'
          control={control}
          rules={{
            required: t('nameRequired'),
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl fullWidth error={!!error}>
                <TextField label={t('name')} {...field} type='text' />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <Controller
          name='country'
          defaultValue={''}
          control={control}
          rules={{ required: t('countryRequired') }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl error={!!error} fullWidth sx={{ mb: 1 }}>
                <Autocomplete
                  id='country-selector'
                  fullWidth
                  value={{ name: field.value }}
                  sx={{ mt: 2 }}
                  options={countries}
                  autoHighlight
                  disableClearable
                  getOptionLabel={(country) => country?.name}
                  isOptionEqualToValue={(option, value) => {
                    return option.name === value.name;
                  }}
                  renderOption={(props, country) => (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {country.unicodeFlag} {country.name} ({country.iso2})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('country')}
                      required
                      value={field.value}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: '',
                      }}
                    />
                  )}
                  onChange={(event, newCountry) => {
                    setValue(field.name, newCountry?.name, { shouldDirty: true, shouldValidate: true });
                    setValue('city', '', { shouldDirty: true });
                  }}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <Controller
          name='city'
          control={control}
          rules={{ required: t('cityRequired') }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl error={!!error} fullWidth sx={{ mb: 1 }}>
                <Autocomplete
                  id='city-selector'
                  fullWidth
                  sx={{ mt: 2 }}
                  options={cities}
                  value={field.value}
                  autoHighlight
                  disableClearable
                  getOptionLabel={(city) => city}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderOption={(props, city) => (
                    <Box component='li' {...props}>
                      {city}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('city')}
                      required
                      value={field.value}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: '',
                      }}
                    />
                  )}
                  onChange={(event, city) => {
                    setValue(field.name, city, { shouldDirty: true });
                  }}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />

        <Controller
          name='picture'
          defaultValue={''}
          control={control}
          rules={{ required: t('photoRequired') }}
          render={({ field, fieldState: { error } }) => {
            const fieldValue = field.value ?? '/img/avatars/default-profile.svg';
            return (
              <FormControl error={!!error} fullWidth sx={{ mb: 1 }}>
                <div className='text-gray-500 mt-2 w-full text-center content-center border rounded-sm border-gray-300'>
                  <InputLabel sx={{ top: -15, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('photo')} *</InputLabel>
                  {onAvatarEdit && (
                    <div className='w-full flex flex-col flex-1 items-center p-3'>
                      <AvatarEditor
                        showPreview={false}
                        onChange={(value) => {
                          if (value) {
                            setValue(field.name, value, { shouldDirty: true });
                          }
                          setAvatarEdit(false);
                        }}
                        onCancel={() => {
                          setAvatarEdit(false);
                        }}
                      />
                    </div>
                  )}
                  {!onAvatarEdit && (
                    <div className='w-full flex flex-col flex-1 items-center p-3'>
                      {!(fieldValue.startsWith('data') || fieldValue.startsWith('/')) && <PlayerAvatar imageId={fieldValue} width={100} height={100} />}
                      {(fieldValue.startsWith('data') || fieldValue.startsWith('/img/avatars')) && <img src={fieldValue} alt='preview' width={100} height={100} className='mx-auto' />}
                      <IconButton
                        sx={{ top: -30, left: 45 }}
                        onClick={() => {
                          setAvatarEdit(true);
                        }}>
                        <EditOutlined />
                      </IconButton>
                    </div>
                  )}
                </div>
              </FormControl>
            );
          }}
        />
        <Button
          onClick={handleSubmit(signUpClbk)}
          fullWidth
          variant='outlined'
          className={signingUp ? 'animate-pulse' : ''}
          sx={{
            mt: 2,
            mb: 2,
            py: 2,
            width: '100%',
            textTransform: 'capitalize',
            fontSize: 20,
            fontWeight: 'light',
          }}>
          {t('signUp')}
        </Button>
      </Box>
    </Box>
  );
}
