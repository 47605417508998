import i18next from 'i18next';
import { DEFAULT_LANGUAGE } from '../../../config/config';

export async function checkLangCookie() {
  const langCookie = localStorage.getItem('i18nextLng') ?? DEFAULT_LANGUAGE;
  console.log('langCookie', langCookie);
  if (i18next.language !== langCookie) {
    console.log('changing language to', langCookie);
    await i18next.changeLanguage(langCookie);
  }
}

export function clearLangCookie() {
  localStorage.removeItem('i18nextLng');
}

export function setLangCookie(token) {
  localStorage.setItem('i18nextLng', token);
}
