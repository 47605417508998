import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from './assets/langs/en.json';
import common_el from './assets/langs/el.json';
import common_it from './assets/langs/it.json';
import common_es from './assets/langs/es.json';
import common_pt from './assets/langs/pt.json';
import common_pl from './assets/langs/pl.json';
import common_sr from './assets/langs/sr.json';
import common_hu from './assets/langs/hu.json';
import common_sw from './assets/langs/sw.json';
import common_nl from './assets/langs/nl.json';
import common_de from './assets/langs/de.json';
import common_bg from './assets/langs/bg.json';
import common_lt from './assets/langs/lt.json';

import { DEFAULT_LANGUAGE } from './config/config';

i18n.use(initReactI18next).init({
  debug: false,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: 'en',
  compatibilityJSON: 'v3',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      common: common_en,
    },
    el: {
      common: common_el,
    },
    it: {
      common: common_it,
    },
    es: {
      common: common_es,
    },
    pt: {
      common: common_pt,
    },
    pl: {
      common: common_pl,
    },
    sr: {
      common: common_sr,
    },
    hu: {
      common: common_hu,
    },
    sw: {
      common: common_sw,
    },
    nl: {
      common: common_nl,
    },
    de: {
      common: common_de,
    },
    bg: {
      common: common_bg,
    },
    lt: {
      common: common_lt,
    },
  },
  defaultNS: 'common',
});
export default i18n;
