import { isValueEmpty } from '@grethics/commons';
import { Box, Button, FormControl, FormHelperText, Grid, Link, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useForgotPwdHook from '../../../hooks/auth/useForgotPwdHook';

export default function ResetPage() {
  const { t } = useTranslation();
  const { loading, sent, error, sendResetLink } = useForgotPwdHook();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { email: null },
    mode: 'onChange',
  });

  const resetClbk = async (data) => {
    if (!isValueEmpty(data?.email)) {
      sendResetLink(data);
    }
  };

  return (
    <div className='h-full max-w-md mx-auto'>
      {sent && (
        <p>
          {t('resetLinkSent')} <br />
        </p>
      )}
      {!sent && (
        <>
          <p>{t('passwordResetPrompt')}</p>
          <Box component={'form'} onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Controller
              name='email'
              control={control}
              rules={{
                required: t('emailRequired'),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t('emailRequired'),
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl sx={{ my: 2 }} fullWidth error={!!error}>
                    <TextField label={t('email')} {...field} type='email' />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Button
              onClick={handleSubmit(resetClbk)}
              fullWidth
              disabled={loading || errors?.email}
              variant='outlined'
              className={loading ? 'animate-pulse' : ''}
              sx={{
                mt: 2,
                mb: 2,
                py: 2,
                width: '100%',
                textTransform: 'capitalize',
                fontSize: 20,
                fontWeight: 'light',
              }}>
              {t('sendResetLink')}
            </Button>
          </Box>
          <Grid container>
            <Grid item xs>
              <Link href='./signin'>{t('signIn')}</Link>
            </Grid>
            <Grid item>
              <Link href='./signup'>{t('signUp')}</Link>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
