import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//const vMinMargin = 60 + 122 + 100; //header + footer + playerPads on top
const vMinMargin = 0; //header + footer + playerPads on top

export default function useGameEditMetrics(outer, canvas, gridSize = { width: 16, height: 10 }) {
  const [canvasSize, setCanvaSize] = useState(500);
  const { drawerOpen } = useSelector((state) => state.ui);
  useEffect(() => {
    function setSquareSize() {
      if (outer && canvas) {
        const outerH = outer.offsetHeight;
        const outerW = outer.offsetWidth;
        let minDimension = outerW;

        setCanvaSize(minDimension);
      }
    }
    setSquareSize();
    window.addEventListener('resize', setSquareSize);
    return () => {
      window.removeEventListener('resize', setSquareSize);
    };
  }, [outer, canvas, drawerOpen]);

  const boardSize = canvasSize;
  const cellSize = Math.floor((boardSize - boardSize * 0.001) / gridSize.width);
  const cellPadding = Math.floor(cellSize * 0.015);
  const squareSize = cellSize - cellPadding * 2;
  const avatarSize = cellSize - 15;
  const hMargin = (outer?.offsetWidth ?? 500) - boardSize;

  /* console.log({
    BoardSize,
    CellSize,
    CellPadding,
    SquareSize,
    AvatarSize,
    hMargin,
  }); */
  return { canvasWidth: boardSize, cellSize, cellPadding, squareSize, avatarSize, hMargin };
}
