import { QuestionType } from '@grethics/commons';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import QuestionOptionsForm from './QuestionOptionsForm';
import { useTranslation } from 'react-i18next';
const QuestionTypes = { [QuestionType.OPEN]: 'Open', [QuestionType.MCMS]: 'Multiple Choice', [QuestionType.MCSS]: 'Single Choice' };
const AnswerTypes = { number: 'Number', string: 'Single Line Text', text: 'MultiLine Text' };
const AnswerForm = ({ qType }) => {
  const { control, watch } = useFormContext();
  const aType = watch('body.aType');
  const corAnsFieldType = aType === 'number' ? 'number' : 'text';
  const { t } = useTranslation();
  return (
    <div className='flex flex-col flex-1 gap-4'>
      <div className='flex w-full px-2'>
        <Controller
          name='body.qType'
          defaultValue={''}
          control={control}
          rules={{ required: t('qtypeRequired') }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl error={!!error} fullWidth>
                <InputLabel>{t('qtype')}</InputLabel>
                <Select label={t('qtype')} {...field} value={field.value}>
                  {Object.entries(QuestionTypes).map((qt) => (
                    <MenuItem key={qt[0]} value={qt[0]}>
                      {t(`qtype${qt[0]}`)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
      </div>
      {qType === QuestionType.OPEN && (
        <div className='flex flex-col w-full gap-4 px-2'>
          <Controller
            name='body.aType'
            defaultValue={''}
            control={control}
            rules={{ required: t('inputTypeRequired') }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl error={!!error} fullWidth>
                  <InputLabel>{t('inputType')}</InputLabel>
                  <Select label={t('inputType')} {...field}>
                    {Object.keys(AnswerTypes).map((type) => (
                      <MenuItem key={type} value={type}>
                        {t(`input${type}`)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            name='body.correctAnswer'
            defaultValue={''}
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl error={!!error} fullWidth sx={{ mb: 1 }}>
                  <TextField label={t('correctAnswer')} {...field} fullWidth type={corAnsFieldType} />
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </div>
      )}
      {[QuestionType.MCMS, QuestionType.MCSS].includes(qType) && <QuestionOptionsForm qType={qType} />}
    </div>
  );
};

export default AnswerForm;
