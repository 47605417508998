import { Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GameDao } from '../../../model/dao/GameDao';
import RankingItem from '../../components/rankings/RankingItem';
import { useTranslation } from 'react-i18next';

export default function RankingPage() {
  const { slug, id } = useParams();
  const [timeFrame, setTimeFrame] = useState('');
  const [rankings, setRankings] = useState([]);
  const gameLeaderboards = useSelector((state) => state.admin.rankings[slug] ?? []);
  const leaderboard = gameLeaderboards.find((gr) => gr.id === Number.parseInt(id));
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (leaderboard) {
      const allRankings = leaderboard?.rankings ?? [];
      if (['year', 'month', 'week', 'day'].includes(timeFrame)) {
        setLoading(true);
        GameDao.getLeaderboardRankings(slug, id, timeFrame)
          .then((data) => setRankings(data[0].rankings))
          .catch((error) => {
            enqueueSnackbar(error?.message ?? t('somethingWentWrong'), {
              variant: 'error',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setRankings(allRankings);
      }
    }
  }, [id, leaderboard, slug, timeFrame]);

  const handleTimeFilter = (event, newFilter) => {
    setTimeFrame(newFilter ?? '');
  };

  return (
    <div className='flex flex-col fel-1 items-center mt-4'>
      {leaderboard?.timeFrames && (
        <div>
          <ToggleButtonGroup exclusive value={timeFrame} onChange={handleTimeFilter} color='primary'>
            <ToggleButton value={''}>{t('allTime')}</ToggleButton>
            <ToggleButton value='year'>{t('lastYear')}</ToggleButton>
            <ToggleButton value='month'>{t('lastMonth')}</ToggleButton>
            <ToggleButton value='week'>{t('lastWeek')}</ToggleButton>
            <ToggleButton value='day'>{t('today')}</ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}
      <div className='w-full min-h-[40px]'>{loading && <LinearProgress sx={{ width: '100%' }} />}</div>

      <TableContainer component={Paper} sx={{ maxWidth: { lg: 600 } }}>
        {rankings.length === 0 && (
          <Grid container spacing={1}>
            <Grid item md={12} lg={12}>
              <div className='text-2xl text-center w-full'>{t('noOnePlayedYet')}</div>
              <div className='text-2xl text-center w-full'>💔</div>
            </Grid>
          </Grid>
        )}
        {rankings.length > 0 && (
          <Table stickyHeader sx={{ minWidth: { lg: 550, md: 350, sm: 250 } }} aria-label='player-rankings'>
            <TableHead>
              <TableRow>
                <TableCell align='left' width={10} sx={{ fontSize: 20, fontWeight: 'bold' }}>
                  {t('rank')}
                </TableCell>
                <TableCell sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('name')}</TableCell>
                <TableCell align='center' sx={{ fontSize: 20, fontWeight: 'bold' }} width={10}>
                  {t('score')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rankings.map((row, idx) => (
                <TableRow key={row.playerId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ fontSize: 20, fontWeight: 'bold' }} align='right'>
                    {idx + 1}.
                  </TableCell>
                  <TableCell align='left'>
                    <RankingItem item={row} />
                  </TableCell>
                  <TableCell sx={{ fontSize: 20, fontWeight: 'bold' }} align='right'>
                    {row.score}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
}
