import { GAME_STATUS, isValueEmpty } from '@grethics/commons';
import { Add, Edit } from '@mui/icons-material';
import { Badge, Button, IconButton, TablePagination, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, gridClasses } from '@mui/x-data-grid';
import { format } from 'date-fns';
import * as React from 'react';
import NoDataRows from '../NoDataRows';
import { useSelector } from 'react-redux';
import LeaderBrdEditModal from './LeaderBrdEditModal';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LeaderBrdDataGrid({ leaderboards }) {
  const { t } = useTranslation();
  const ldrbrdEditorRef = React.createRef();
  const { drawerOpen } = useSelector((state) => state.ui);
  const [showGrid, setShowGrid] = React.useState(true);

  const LeaderBrdGridToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <GridToolbarQuickFilter sx={{ display: 'flex', flexGrow: 1, p: 1 }} />
        <IconButton
          color='success'
          sx={{ border: 1 }}
          title={t('newTable')}
          onClick={() => {
            ldrbrdEditorRef.current.show();
          }}>
          <Add />
        </IconButton>
      </GridToolbarContainer>
    );
  }, [ldrbrdEditorRef]);

  React.useEffect(() => {
    if (drawerOpen) {
      setShowGrid(false);
      setTimeout(() => {
        setShowGrid(true);
      }, 50);
    }
  }, [drawerOpen]);

  const handleClick = (event, cellValues) => {
    const leaderBrd = cellValues.row;
    const { id, title, domain, maxEntries, timeFrames } = leaderBrd;

    const data = { id, title, domain, maxEntries, timeFrames };
    if (ldrbrdEditorRef.current) {
      ldrbrdEditorRef.current.show(data);
    }
  };

  const onFilterChange = (value) => {
    console.log(value);
  };

  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Link className='text-cyan-600' to={`../../rankings/any/${cellValues.row.id}`}>
            {cellValues.row.title}
          </Link>
        );
      },
    },
    {
      field: 'games',
      headerName: 'Games',
      minWidth: 80,
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        const { games } = params.row.domain;
        if (isValueEmpty(games)) {
          return 'Any';
        } else {
          return games.map((g) => g.title).join(',');
        }
      },
      renderCell: (params) => {
        const { games } = params.row.domain;
        if (isValueEmpty(games)) {
          return 'Any';
        } else {
          return games.length < 2
            ? games.map((g) => g.title).join(',')
            : games
                .slice(0, 2)
                .map((g) => g.title)
                .join(',') + ', ...';
        }
      },
    },
    {
      field: 'countries',
      headerName: 'Countries',
      minWidth: 80,
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        const values = params.row.domain.region.countries;
        if (isValueEmpty(values)) {
          return 'Any';
        } else {
          return values.join(',');
        }
      },
      renderCell: (params) => {
        const values = params.row.domain.region.countries;
        if (isValueEmpty(values)) {
          return 'Any';
        } else {
          return values.length < 2 ? values.join(',') : values.slice(0, 2).join(',') + ', ...';
        }
      },
    },

    {
      field: 'cities',
      headerName: 'Cities',
      minWidth: 80,
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        const values = params.row.domain.region.cities;
        if (isValueEmpty(values)) {
          return 'Any';
        } else {
          return values.join(',');
        }
      },
      renderCell: (params) => {
        const values = params.row.domain.region.cities;
        if (isValueEmpty(values)) {
          return 'Any';
        } else {
          return values.length < 2 ? values.join(',') : values.slice(0, 2).join(',') + ', ...';
        }
      },
    },
    {
      field: 'maxEntries',
      headerName: 'Top-k',
      minWidth: 30,
      editable: false,
      valueGetter: (params) => {
        return params.row.maxEntries ?? '(All)';
      },
    },
    {
      field: 'timeFrames',
      headerName: 'Time Filter',
      minWidth: 60,
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        return params.row.timeFrames;
      },
    },
    {
      field: 'Edit',
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              handleClick(event, cellValues);
            }}>
            <Edit />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box sx={{ minHeight: 600, minWidth: 0, flex: 1 }}>
      <LeaderBrdEditModal ref={ldrbrdEditorRef} />
      {showGrid && (
        <DataGrid
          sx={{
            [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
              outline: 'transparent',
            },
            [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
          }}
          rows={leaderboards}
          columns={columns}
          slots={{ toolbar: LeaderBrdGridToolbar, noRowsOverlay: () => <NoDataRows msg={'No tables yet'} /> }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          onFilterModelChange={onFilterChange}
          autoPageSize
          density='comfortable'
          disableRowSelectionOnClick
          disableColumnSelector
          onCellClick={handleCellClick}
        />
      )}
    </Box>
  );
}
