import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { TableDao } from '../../model/dao/TableDao';
import { useDispatch } from 'react-redux';
import { deleteGameTableAction, resetGameTableAction, updateGameTableAction } from '../../model/store/slices/TablesSlice';
import { useTranslation } from 'react-i18next';

export function useUpdateTableHook() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);
  const [updateError, setError] = useState(null);
  const [updated, setUpdated] = useState(null);

  const updateTable = async (data) => {
    try {
      setUpdating(true);
      setUpdated(false);
      setError(null);
      const { slug, gameId, numOfDices, opponents, availableAt, moderator, isHybrid } = data;
      const payload = { slug, gameId, opponents, numOfDices, availableAt, isHybrid };
      if (moderator) {
        payload.moderatorId = moderator.id;
      } else {
        payload.isHybrid = false;
      }
      const table = await TableDao.updateTable(slug, payload);
      setUpdated(table);
      dispatch(updateGameTableAction({ table, gameId }));
      enqueueSnackbar(t('updatedSuccess'), { variant: 'success' });
    } catch (error) {
      setUpdated(null);
      setError(error?.message);
      enqueueSnackbar(error?.message ?? t('somethingWentWrong'), { variant: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  const deleteTable = async (slug, gameId) => {
    try {
      setUpdating(true);
      setError(null);
      await TableDao.deleteTable(slug);
      dispatch(deleteGameTableAction({ slug, gameId }));
      enqueueSnackbar(t('deletedSuccess'), { variant: 'success' });
    } catch (error) {
      setError(error?.message);
      enqueueSnackbar(error?.message ?? t('somethingWentWrong'), { variant: 'error' });
    } finally {
      setUpdating(false);
    }
  };
  const archiveTable = async (slug, gameId) => {
    try {
      setUpdating(true);
      setError(null);
      await TableDao.archiveTable(slug);
      dispatch(deleteGameTableAction({ slug, gameId }));
      enqueueSnackbar(t('archivedSuccess'), { variant: 'success' });
    } catch (error) {
      setError(error?.message);
      enqueueSnackbar(error?.message ?? t('somethingWentWrong'), { variant: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  const resetTable = async (slug, gameId) => {
    try {
      setUpdating(true);
      setError(null);
      await TableDao.resetTable(slug);
      dispatch(resetGameTableAction({ slug, gameId }));
      enqueueSnackbar(t('resetSuccess'), { variant: 'success' });
    } catch (error) {
      setError(error?.message);
      enqueueSnackbar(error?.message ?? t('somethingWentWrong'), { variant: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  return { updated, updating, updateError, updateTable, archiveTable, deleteTable, resetTable };
}
