import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { TableDao } from '../../model/dao/TableDao';
import { useDispatch } from 'react-redux';
import { addGameTableAction } from '../../model/store/slices/TablesSlice';
import { useTranslation } from 'react-i18next';

export function useCreateTableHook() {
  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);
  const [createError, setError] = useState(null);
  const [instance, setInstance] = useState(null);
  const dispatch = useDispatch();

  const createNewTable = async (data) => {
    try {
      setCreating(true);
      setError(null);
      const {gameId, numOfDices, opponents, moderator, availableAt, isHybrid } = data;
      const payload = { gameId: parseInt(gameId), numOfDices, opponents, availableAt, isHybrid };
      if (moderator) {
        payload.moderatorId = parseInt(moderator.id);
      } else {
        payload.isHybrid = false;
      }
      const table = await TableDao.createNewTable(payload);
      setInstance(table);
      dispatch(addGameTableAction({ table, gameId }));
    } catch (error) {
      setError(error?.message);
      enqueueSnackbar(error?.message ?? t('somethingWentWrong'), { variant: 'error' });
    } finally {
      setCreating(false);
    }
  };

  return { instance, creating, createError, createNewTable };
}
