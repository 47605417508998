import { DoDisturbOnOutlined, SettingsOutlined } from '@mui/icons-material';
import { IconButton, Popover, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NewSquareBG from '../../../assets/img/new-square-green.png';
import { MEDIA_URL } from '../../../config/config';

export default function BoardSquare({ board, square, isDraggable, onDelete, onEdit, squareSize }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const content = useSelector((state) => (square ? state.make.current?.contents?.[square.contentId] : null));

  const squareStyle = {};
  if (!square) {
    return null;
  }
  let bgColor = board.sections.find((s) => s.id === square?.sectionId)?.color;
  if (square.type === 'START') {
    bgColor = 'lime';
  }
  if (square.type === 'END') {
    bgColor = 'red';
  }
  squareStyle.background = bgColor;
  //squareStyle.opacity = 0.85;
  squareStyle.borderWidth = square.obligatory ? 5 : 0;
  squareStyle.borderColor = square.obligatory ? 'coral' : 'white';
  squareStyle.cursor = isDraggable ? 'grab' : '';
  const { bgMedia } = square;
  let mediaUrl = undefined;
  if (bgMedia) {
    mediaUrl = `${MEDIA_URL}/${bgMedia.gameId}/${bgMedia.id}.${bgMedia.extension}`;
  } else if (!['START', 'END'].includes(square.type)) {
    mediaUrl = NewSquareBG;
  }

  return (
    <>
      <div id={`sq${square.order}`} style={{ position: 'relative', width: squareSize, height: squareSize, ...squareStyle }} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {!['START', 'END'].includes(square.type) && <div style={{ position: 'absolute', top: 0, right: 2, color: 'black' }}>{square.order}</div>}
        {mediaUrl && <div className={`flex flex-1 w-full h-full border`} style={{ background: `url('${mediaUrl}')`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}></div>}
        {!['START', 'END'].includes(square.type) && onDelete && (
          <IconButton style={{ position: 'absolute', top: 0, left: 0 }} onClick={() => onDelete(square)}>
            <DoDisturbOnOutlined color='error' />
          </IconButton>
        )}
        {onEdit && (
          <IconButton color='primary' sx={{ position: 'absolute', top: 0, left: 0 }} onClick={() => onEdit(square)}>
            <SettingsOutlined color='primary' />
          </IconButton>
        )}
      </div>
      <Popover
        aria-hidden={false}
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        {!['START', 'END'].includes(square.type) && (
          <Typography sx={{ p: 1 }}>
            {t(square.type)} <span>: {content?.title ?? t('dynamicContent')}</span>
          </Typography>
        )}
        {['START', 'END'].includes(square.type) && <Typography sx={{ p: 1 }}>{t(square.type)}</Typography>}
      </Popover>
    </>
  );
}
