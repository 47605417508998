import { ContentType, isValueEmpty, SquareType } from '@grethics/commons';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import GameAvatar from '../common/GameAvatar';
import IOSSwitch from '../common/IOSSwitch';
import MediaLibraryModal from './MediaLibraryModal';
import { PALLETE } from '../../../config/constants';
import { useTranslation } from 'react-i18next';

const SquareEditor = React.forwardRef(({ onSave }, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [square, setSquare] = useState(null);
  const [colorPicker, setShowPicker] = useState(null);
  const mediaLibRef = useRef();
  const { game, board, path, loading, contents } = useSelector((state) => state.make.current);
  const { control, setValue, handleSubmit, reset, watch, trigger } = useForm({
    mode: 'onBlur',
    values: { ...square },
  });
  const squareType = watch('type');
  const randomContent = watch('randomContent');
  const isBoundaySquare = [SquareType.START, SquareType.END].includes(squareType);
  const usedContents = (path?.asArray ?? []).map((sq) => sq.contentId).filter((c) => c !== null);
  const availableContents = Object.values(contents).filter((c) => c?.type === squareType && !usedContents.includes(c?.id));
  if (!square?.randomContent) {
    availableContents.push(contents[square?.contentId]);
  }
  const onFormSubmit = async (data) => {
    if (typeof onSave === 'function') {
      onSave({ ...data, contentId: randomContent ? null : data.contentId }, square)
        .then(() => {
          handleClose();
        })
        .catch((err) => {
          console.log('could not update square');
        });
    }
  };

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    reset();
    setSquare(null);
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show(square) {
      if (square) {
        if (square.randomContent) {
          setSquare({ ...square, contentId: 'random' });
        } else {
          setSquare(square);
        }
        setOpen(true);
      }
    },
    hide() {
      setSquare(null);
      handleClose();
    },
  }));

  if (!square) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off' className='flex flex-col flex-1'>
        <Dialog disableEscapeKeyDown maxWidth={'lg'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} fullWidth sx={{ mx: 'auto' }}>
          <DisposableDialogTitle id='customized-dialog-title' onClose={handleClose}>
            {isBoundaySquare ? `[${t(squareType)}]` : ''} {t('square')} {square.order}
          </DisposableDialogTitle>
          <DialogContent dividers sx={{ minWidth: '100%' }}>
            <div className='flex flex-col flex-1'>
              {!isBoundaySquare && (
                <div className='flex flex-col lg:flex-row flex-1 lg:items-start md:items-center gap-4'>
                  <div className='flex-grow w-full'>
                    <Controller
                      name='type'
                      control={control}
                      rules={{ required: t('squareTypeRequired') }}
                      render={({ field, fieldState: { error } }) => {
                        if (!isBoundaySquare) {
                          return (
                            <FormControl error={!!error} fullWidth sx={{ mb: 1 }}>
                              <InputLabel>{t('squareType')}</InputLabel>
                              <Select
                                label={t('squareType')}
                                {...field}
                                onChange={(e) => {
                                  setValue(field.name, e.target.value);
                                  setValue('contentId', '');
                                  //reset({ contentId: '', [field.name]: e.target.value });
                                  trigger('contentId');
                                }}>
                                {Object.keys(ContentType).map((st) => (
                                  <MenuItem key={st} value={st}>
                                    {t(st)}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{error?.message}</FormHelperText>
                            </FormControl>
                          );
                        } else {
                          return (
                            <FormControl error={!!error} fullWidth>
                              <TextField label={t('squareType')} {...field} disabled='true' />
                              <FormHelperText>{error?.message}</FormHelperText>
                            </FormControl>
                          );
                        }
                      }}
                    />
                    <Controller
                      name='sectionId'
                      control={control}
                      rules={{ required: t('squareTopicRequired') }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth sx={{ my: 1 }}>
                          <InputLabel>{t('squareTopic')}</InputLabel>
                          <Select label={t('squareTopic')} {...field}>
                            {board.sections.map((st) => (
                              <MenuItem key={st.id} value={st.id}>
                                {st.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      )}
                    />
                    <div className='flex flex-row gap-4'>
                      <Controller
                        name='obligatory'
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          const obligatory = field.value;
                          return (
                            <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
                              <div className='px-2 flex flex-col border rounded-md border-gray-300 items-center'>
                                <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('obligatorySquare')}</InputLabel>
                                <div className='flex flex-row w-full'>
                                  <FormControl fullWidth>
                                    <div className='flex flex-col items-center m-2 mb-1'>
                                      <FormControlLabel
                                        style={{ padding: 3, width: '100%' }}
                                        control={
                                          <IOSSwitch
                                            className={'w-full'}
                                            checked={!!obligatory}
                                            onChange={(e) => {
                                              setValue(field.name, e.target.checked);
                                              trigger(field.name);
                                            }}
                                          />
                                        }
                                        label={<span className='ml-1 text-gray-500'>{obligatory ? ` ${t('yes')}` : ` ${t('no')}`}</span>}
                                      />
                                    </div>
                                  </FormControl>
                                </div>
                              </div>
                            </FormControl>
                          );
                        }}
                      />
                      <Controller
                        name='randomContent'
                        control={control}
                        rules={{}}
                        render={({ field, fieldState: { error } }) => {
                          const randomContent = field.value;
                          return (
                            <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
                              <div className='px-2 flex flex-col border rounded-md border-gray-300 items-center'>
                                <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('dynamicContent')}</InputLabel>
                                <div className='flex flex-row w-full'>
                                  <FormControl fullWidth>
                                    <div className='flex flex-col items-center m-2 mb-1'>
                                      <FormControlLabel
                                        style={{ padding: 3, width: '100%' }}
                                        control={
                                          <IOSSwitch
                                            className={'w-full'}
                                            checked={!!randomContent}
                                            onChange={(e) => {
                                              setValue(field.name, e.target.checked ? true : null);
                                              setValue('contentId', e.target.checked ? t('randomContent') : null);
                                              trigger(field.name);
                                              trigger('contentId');
                                            }}
                                          />
                                        }
                                        label={<span className='ml-1 text-gray-500'>{randomContent ? ` ${t('yes')}` : ` ${t('no')}`}</span>}
                                      />
                                    </div>
                                  </FormControl>
                                </div>
                              </div>
                            </FormControl>
                          );
                        }}
                      />
                    </div>
                    <Controller
                      name='contentId'
                      control={control}
                      rules={{ required: 'Square should have an associated content' }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth sx={{ my: 1 }}>
                          <InputLabel>{t('content')}</InputLabel>
                          <Select disabled={randomContent} label={t('content')} {...field}>
                            {randomContent && (
                              <MenuItem key={'random'} value={'random'} selected={randomContent}>
                                {t('dynamicContent')}
                              </MenuItem>
                            )}
                            {!randomContent &&
                              availableContents.map((c) => {
                                if (!c) return null;
                                return (
                                  <MenuItem key={c.id} value={c.id} selected={c.id === field.value}>
                                    {c.sysTitle}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name='rewarding'
                      control={control}
                      rules={{
                        validate: async (value) => {
                          const { pointsOnFalse, pointsOnTrue } = value;
                          console.log({ pointsOnFalse, pointsOnTrue });
                          if (isValueEmpty(pointsOnTrue)) {
                            return t('invalidRewarding');
                          } else if (squareType === SquareType.QUIZ && isValueEmpty(pointsOnFalse)) {
                            return t('invalidRewarding');
                          }
                          return true;
                        },
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const rewarding = field.value;
                        console.log(rewarding);
                        return (
                          <FormControl error={!!error} fullWidth>
                            <div className='px-2 flex flex-col border rounded-md border-gray-300 items-center'>
                              <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('rewarding')}</InputLabel>
                              <div className='flex flex-row gap-4 py-4 w-full'>
                                {squareType === SquareType.QUIZ && (
                                  <TextField
                                    label={t('failReward')}
                                    name='pointsOnFailure'
                                    value={rewarding?.pointsOnFalse}
                                    type='number'
                                    fullWidth
                                    onChange={(e) => {
                                      setValue(field.name, { ...rewarding, pointsOnFalse: Number.parseInt(e.target.value) });
                                      trigger(field.name);
                                    }}
                                  />
                                )}
                                <TextField
                                  name='pointsOnSuccess'
                                  label={squareType === SquareType.QUIZ ? t('successReward') : ''}
                                  value={rewarding?.pointsOnTrue}
                                  type='number'
                                  fullWidth
                                  onChange={(e) => {
                                    setValue(field.name, { ...rewarding, pointsOnTrue: Number.parseInt(e.target.value) });
                                    trigger(field.name);
                                  }}
                                />
                              </div>
                              <FormHelperText>{error?.message}</FormHelperText>
                            </div>
                          </FormControl>
                        );
                      }}
                    />
                    <Controller
                      name='transition'
                      control={control}
                      rules={{
                        validate: async (value) => {
                          if (!value) {
                            return true;
                          }
                          const { to, type } = value;
                          if (type && to === null) {
                            return t('toSquareRequired');
                          }
                        },
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const transition = field.value;
                        console.log({ transition });
                        return (
                          <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
                            <div className='px-2 flex flex-col border rounded-md border-gray-300 items-center'>
                              <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('autoTransition')}</InputLabel>
                              <div className='flex flex-col w-full'>
                                <FormControl fullWidth>
                                  <div className='flex flex-col items-center m-2 mb-1'>
                                    <FormControlLabel
                                      style={{ padding: 3 }}
                                      control={
                                        <IOSSwitch
                                          checked={transition !== null}
                                          onChange={(e) => {
                                            setValue(field.name, e.target.checked ? {} : null);
                                            trigger(field.name);
                                          }}
                                        />
                                      }
                                      label={<span className='ml-1 text-gray-500'>{transition ? ` ${t('yes')}` : ` ${t('no')}`}</span>}
                                    />
                                  </div>
                                </FormControl>
                                {transition && (
                                  <div className='flex flex-row gap-4 pt-4 w-full'>
                                    <FormControl fullWidth sx={{ my: 1 }}>
                                      <InputLabel>{t('transitionType')}</InputLabel>
                                      <Select
                                        label={t('transitionType')}
                                        {...field}
                                        value={field.value?.type}
                                        onChange={(e) => {
                                          setValue(field.name, { ...transition, type: e.target.value, to: null });
                                          trigger(field.name);
                                        }}>
                                        {[
                                          { value: 'bwd', label: t('backwards') },
                                          { value: 'fwd', label: t('forwards') },
                                        ].map((t) => {
                                          return (
                                            <MenuItem key={t.value} value={t.value} selected={t.value === transition?.type}>
                                              {t.label}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                    <FormControl fullWidth sx={{ my: 1 }}>
                                      <InputLabel>{t('toSquare')}</InputLabel>
                                      <Select
                                        label={t('toSquare')}
                                        {...field}
                                        value={field.value?.to}
                                        onChange={(e) => {
                                          setValue(field.name, { ...transition, to: e.target.value });
                                          trigger(field.name);
                                        }}>
                                        {Object.values(path.asArray)
                                          .filter((sq) => {
                                            if (transition?.type === 'bwd') {
                                              return sq.order < square.order;
                                            } else {
                                              return sq.order > square.order;
                                            }
                                          })
                                          .map((sq) => {
                                            return (
                                              <MenuItem key={sq.id} value={sq.order} selected={sq.order === transition?.to}>
                                                {sq.order}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                )}
                                {transition?.type && transition?.to && (
                                  <>
                                    <Typography variant='body2' sx={{ borderBottom: 1, borderColor: PALLETE.CLOUDY.dark }}>
                                      {t('arrowConfig')}:
                                    </Typography>
                                    <div className='flex flex-row gap-4 pt-4 w-full'>
                                      <FormControl fullWidth>
                                        <InputLabel>{t('lineStyle')}</InputLabel>
                                        <Select
                                          label={t('lineStyle')}
                                          {...field}
                                          value={field.value?.line ?? 'solid'}
                                          onChange={(e) => {
                                            setValue(field.name, { ...transition, line: e.target.value });
                                            trigger(field.name);
                                          }}>
                                          {[
                                            { value: 'dash', label: t('dashedLine') },
                                            { value: 'solid', label: t('solidLine') },
                                          ].map((t) => {
                                            return (
                                              <MenuItem key={t.value} value={t.value} selected={t.value === transition?.type}>
                                                {t.label}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <div className='border border-slate-300 rounded p-3'>
                                          <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>Color</InputLabel>
                                          <Button
                                            fullWidth
                                            style={{ backgroundColor: transition?.color ?? 'lime', minHeight: 30 }}
                                            onClick={(e) => {
                                              setShowPicker(!colorPicker);
                                            }}></Button>
                                        </div>
                                      </FormControl>
                                      <FormControl fullWidth disabled={!(transition?.line === 'dash')}>
                                        <div className='border border-slate-300 rounded p-3'>
                                          <InputLabel shrink={false} sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>
                                            {t('animated')}
                                          </InputLabel>
                                          <div className='flex flex-col items-start'>
                                            <FormControlLabel
                                              style={{ padding: 3 }}
                                              control={
                                                <IOSSwitch
                                                  checked={transition?.animated}
                                                  onChange={(e) => {
                                                    setValue(field.name, { ...transition, animated: e.target.checked });
                                                    trigger(field.name);
                                                  }}
                                                />
                                              }
                                              label={<span className='ml-1 text-gray-500'>{transition?.animated ? ` ${t('yes')}` : ` ${t('no')}`}</span>}
                                            />
                                          </div>
                                        </div>
                                      </FormControl>
                                    </div>

                                    {colorPicker && (
                                      <div className='flex flex-row gap-4 pt-4 w-full'>
                                        <SketchPicker
                                          width='100%'
                                          color={{ hex: transition?.color ?? 'lime' }}
                                          onChangeComplete={(color) => {
                                            setValue(field.name, { ...transition, color: color?.hex });
                                            trigger(field.name);
                                          }}
                                        />
                                      </div>
                                    )}
                                    <div className='flex flex-row gap-4 pt-4 w-full'>
                                      <FormControl fullWidth sx={{ my: 1 }}>
                                        <InputLabel>{t('source')}</InputLabel>
                                        <Select
                                          label={t('source')}
                                          {...field}
                                          value={field.value?.takeoff ?? ''}
                                          onChange={(e) => {
                                            setValue(field.name, { ...transition, takeoff: e.target.value });
                                            trigger(field.name);
                                          }}>
                                          {[
                                            { value: 'auto', label: t('auto') },
                                            { value: 'top', label: t('top') },
                                            { value: 'bottom', label: t('bottom') },
                                            { value: 'left', label: t('left') },
                                            { value: 'right', label: t('right') },
                                          ].map((t) => {
                                            return (
                                              <MenuItem key={t.value} value={t.value} selected={t.value === transition?.takeoff}>
                                                {t.label}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      <FormControl error={!!error} fullWidth sx={{ my: 1 }}>
                                        <InputLabel>{t('target')}</InputLabel>
                                        <Select
                                          label={t('target')}
                                          {...field}
                                          value={field.value?.landing ?? ''}
                                          onChange={(e) => {
                                            setValue(field.name, { ...transition, landing: e.target.value });
                                            trigger(field.name);
                                          }}>
                                          {[
                                            { value: 'auto', label: t('auto') },
                                            { value: 'top', label: t('top') },
                                            { value: 'bottom', label: t('bottom') },
                                            { value: 'left', label: t('left') },
                                            { value: 'right', label: t('right') },
                                          ].map((t) => {
                                            return (
                                              <MenuItem key={t.value} value={t.value} selected={t.value === transition?.landing}>
                                                {t.label}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </>
                                )}
                              </div>
                              <FormHelperText>{error?.message}</FormHelperText>
                            </div>
                          </FormControl>
                        );
                      }}
                    />
                  </div>
                  <Controller
                    name='bgMediaId'
                    control={control}
                    rules={{ required: t('squareBgRequired') }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[320px] items-center'>
                          <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('squareBg')}</InputLabel>
                          <div className='mt-3 top-0 z-0'>
                            <GameAvatar imageId={field.value} height={300} width={300} />
                          </div>
                          <div className='flex flex-row justify-between pt-1 w-[290px] absolute top-1'>
                            <IconButton
                              onClick={() => {
                                mediaLibRef.current?.show(game?.id);
                              }}
                              sx={{ borderRadius: 1, py: 1, px: 0 }}
                              color='primary'
                              className='z-10'>
                              <Edit />
                            </IconButton>
                            {field.value && (
                              <IconButton
                                onClick={() => {
                                  setValue(field.name, undefined, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                                }}
                                sx={{ borderRadius: 1, py: 1, px: 0 }}
                                color='error'
                                className='z-10'>
                                <Delete />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        <FormHelperText>{error?.message}</FormHelperText>
                        <div>
                          <MediaLibraryModal
                            ref={mediaLibRef}
                            mediaId={field.value}
                            mediaTypes={['image']}
                            onSelect={(mediaId) => {
                              setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            }}
                          />
                        </div>
                      </FormControl>
                    )}
                  />
                </div>
              )}
              {isBoundaySquare && (
                <div className={'flex flex-col w-full items-center'}>
                  <Controller
                    name='bgMediaId'
                    control={control}
                    rules={{ required: t('squareBgRequired') }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error}>
                        <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[320px] items-center'>
                          <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>{t('squareBg')}</InputLabel>
                          <div className='mt-3 top-0 z-0'>
                            <GameAvatar imageId={field.value} height={300} width={300} />
                          </div>
                          <div className='flex flex-row justify-between pt-1 w-[290px] absolute top-1'>
                            <IconButton
                              onClick={() => {
                                mediaLibRef.current?.show(game?.id);
                              }}
                              sx={{ borderRadius: 1, py: 1, px: 0 }}
                              color='primary'
                              className='z-10'>
                              <Edit />
                            </IconButton>
                            {field.value && (
                              <IconButton
                                onClick={() => {
                                  setValue(field.name, undefined, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                                }}
                                sx={{ borderRadius: 1, py: 1, px: 0 }}
                                color='error'
                                className='z-10'>
                                <Delete />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        <FormHelperText>{error?.message}</FormHelperText>
                        <div>
                          <MediaLibraryModal
                            ref={mediaLibRef}
                            mediaId={field.value}
                            mediaTypes={['image']}
                            onSelect={(mediaId) => {
                              setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                            }}
                          />
                        </div>
                      </FormControl>
                    )}
                  />
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', bgcolor: 'lightsteelblue' }}>
            <Button variant='contained' color='error' autoFocus onClick={handleClose} startIcon={<Close />}>
              {t('cancel')}
            </Button>
            <LoadingButton loading={!!loading} loadingposition='start' variant='contained' color='primary' onClick={handleSubmit(onFormSubmit)} startIcon={<Check />}>
              {t('save')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
});

export default SquareEditor;
