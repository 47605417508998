import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DisposableDialogTitle from '../../common/DisposableDialogTitle';
import PlayerFormFields from '../../common/PlayerFormFields';
import { useTranslation } from 'react-i18next';

const NewPlayerModal = React.forwardRef(({ onSave }, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [playerInfo, setPlayerInfo] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show(entity) {
      console.log('Showing player form with initial data:', JSON.stringify(entity));
      if (isNil(entity) || isEmpty(entity)) {
        setUserInfo(null);
        setPlayerInfo(null);
      } else setUserInfo(entity.user);
      if (entity.player) {
        setPlayerInfo(entity.player);
      } else if (entity.user) {
        setPlayerInfo({ ...entity.user, id: undefined });
      }
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  const methods = useForm({
    mode: 'all',
    defaultValues: { id: '', name: '', country: '', city: '', userId: null, imageId: '', picture: null, ageGroup: '' },
    values: { ...playerInfo },
  });

  const country = methods.watch('country');

  const onFormSubmit = async (data) => {
    if (data.imageId && data.imageId.startsWith('data')) {
      data.picture = data.imageId;
      data.imageId = null;
    }
    setPlayerInfo(data);
    const record = { name: data?.name, player: data, user: userInfo ?? undefined };
    if (onSave) {
      onSave(record);
    }
    handleClose();
  };

  return (
    <>
      <div>
        <Dialog disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onFormSubmit)} autoComplete='off'>
              <DisposableDialogTitle id='customized-dialog-title' onClose={handleClose}>
                {userInfo ? t('playerProfileOfUser', { name: userInfo.name }) : t('newUnregisteredPlayer')}
              </DisposableDialogTitle>
              <DialogContent dividers sx={{ minWidth: '100%' }}>
                <PlayerFormFields country={country} control={methods.control} setValue={methods.setValue} errors={methods.formState.errors} />
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'end', bgcolor: 'primary.extraLight' }}>
                <Button variant='contained' autoFocus type='submit'>
                  OK
                </Button>
              </DialogActions>
            </form>
          </FormProvider>
        </Dialog>
      </div>
    </>
  );
});

export default NewPlayerModal;
