import { Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TableConfigurator from '../../components/play/new/TableConfigurator';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function NewTablePage() {
  const { t } = useTranslation();
  const { slug } = useParams();
  const navigate = useNavigate();
  console.log({ slug });
  const game = useSelector((state) => {
    const games = state.play.list;
    const result = games.find((g) => g.slug === slug);
    return result ?? 'NO_GAME';
  });

  console.log({ game });
  useEffect(() => {
    if (game === 'NO_GAME') {
      navigate('/play');
    }
  }, [navigate, game]);

  return (
    <Container width={'100%'} sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Container maxWidth='md'>
        <Typography variant='h5' sx={{ py: 2 }}>
          {t('newTableForGame', { game: game?.title })}
        </Typography>
        <TableConfigurator gameId={game?.id} ageRange={game?.ageRange} />
      </Container>
    </Container>
  );
}
