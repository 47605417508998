import { SOUND_FX_TYPE } from '@grethics/commons';
import { ControlCamera } from '@mui/icons-material';
import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { PALLETE } from '../../../config/constants';
import { diceRollerRef } from '../../../control/play/GameObserver';
import { getSoundFxUrl } from '../../../utils/Utils';
import DiceRoller from './DiceRoller';
import StatusBar from './StatusBar';
import { useRef } from 'react';

export default function DiceRollerWidget() {
  const { diceFx } = useSelector((state) => state.play.current.spec.game);
  const diceRollerRef = useRef();
  const [vw, setVw] = useState(window.innerWidth);
  const [vh, setVh] = useState(window.innerHeight);

  useEffect((effect) => {
    const handleResize = () => {
      setVw(window.innerWidth);
      setVh(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ position: 'absolute', bottom: 0, left: 0, zIndex: 1000 }}>
      <Draggable handle='.dragControl' defaultPosition={{ x: vw / 2 - 150, y: 0 }}>
        <Paper sx={{ borderRadius: 2, backgroundColor: PALLETE.GRAY.light, gap: 1 }} elevation={5} className='p-2 border shadow-md'>
          <div className='flex dragControl flex-row cursor-move'>
            <ControlCamera className='mr-1 mb-1' sx={{ color: 'white' }} />
            <StatusBar />
          </div>
          <DiceRoller ref={diceRollerRef} soundFxUrl={getSoundFxUrl(diceFx, SOUND_FX_TYPE.DICE)} />
        </Paper>
      </Draggable>
    </div>
  );
}
