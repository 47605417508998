import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { capitalizePhrase } from '../../../utils/Utils';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

export const MediaImportModal = React.forwardRef(({ onComplete }, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const loading = useSelector((state) => state.ui.loading);
  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;

    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show() {
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const canPlay = ReactPlayer.canPlay(formJson.url);
          if (!canPlay) {
            console.log('this video cannot be played....');
          }
          if (onComplete) {
            onComplete(formJson.title, formJson.url, formJson.mimeType);
          }
          setOpen(false);
          event.stopPropagation();
        },
      }}>
      <DialogTitle>{t('importMediaFromUrl')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('importMediaFromUrlPrompt')}</DialogContentText>
        <TextField autoFocus required margin='dense' id='url' name='url' label={t('mediaUrl')} type='url' fullWidth variant='outlined' />
        <FormControl required fullWidth sx={{ mt: 2 }}>
          <InputLabel id='test-select-label' sx={{ bgcolor: 'white', fontSize: 18 }}>
            {t('mediaType')}
          </InputLabel>
          <Select fullWidth id='mimeType' name='mimeType' defaultValue={'video'}>
            {['video', 'image', 'audio'].map((type) => (
              <MenuItem key={type} value={type}>
                {capitalizePhrase(type)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField sx={{ mt: 2 }} margin='dense' id='title' name='title' label={t('mediaTitle')} type='text' fullWidth variant='outlined' />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <LoadingButton loading={!!loading} loadingposition='start' type='submit'>
          {t('import')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
