import ChangePage from '../view/screens/auth/ChangePage';
import ResetPage from '../view/screens/auth/ResetPage';
import SignInPage from '../view/screens/auth/SignInPage';
import SignUpPage from '../view/screens/auth/SignUpPage';

export const AuthRoutes = [
  {
    path: 'auth/signin',
    element: <SignInPage />,
  },
  {
    path: 'auth/signup',
    element: <SignUpPage />,
  },
  {
    path: 'auth/reset',
    element: <ResetPage />,
  },
  {
    path: 'auth/change/:token',
    element: <ChangePage />,
  },
];
